/* eslint-disable */

import React, { useId } from "react";
import classNames from "classnames";
import "./Checkbox.scss";
import { Icon, Icomoon } from "../../Icon/Icon";
import {
  FieldValues,
  FormState,
  RegisterOptions,
  useFormContext,
  UseFormRegister,
  UseFormRegisterReturn,
} from "react-hook-form";

interface Props {
  className?: string;
  label: string;
  subtext?: string;
  inputProps: WithRequired<JSX.IntrinsicElements["input"], "name">;
  register?: UseFormRegisterReturn;
  formState?: FormState<FieldValues>;
  dataTrackingId?: string;
  rules?: RegisterOptions;
}

type RegisterType = UseFormRegister<FieldValues> | UseFormRegisterReturn | undefined;

function isUseFormRegister(register: RegisterType): register is UseFormRegister<FieldValues> {
  return typeof register === "function";
}

export const Checkbox: React.FC<Props> = ({
  className,
  label,
  subtext,
  inputProps,
  register: checkboxRegister,
  formState: checkBoxFormState,
  dataTrackingId,
  rules,
}) => {
  const formContext = useFormContext();
  const register = checkboxRegister ?? formContext?.register;
  const formState = checkBoxFormState ?? formContext?.formState;

  const error = formState?.errors[inputProps.name];

  const innerId = inputProps?.id ?? useId();

  return (
    <>
      <label htmlFor={innerId} className={classNames(className, "form-checkbox")}>
        <div
          className={classNames("checkbox-container", {
            "checkbox-container--errored": error,
          })}>
          <input
            {...inputProps}
            data-tracking-id={dataTrackingId}
            type="checkbox"
            id={innerId}
            {...(isUseFormRegister(register) ? register(inputProps.name, rules) : register)}
          />
          <Icon icomoon={Icomoon.checkmark} className="checkmark" />
          <div className="checkbox-text-container">
            <p className="checkbox-label">{label}</p>
            {subtext && <p className="checkbox-subtext">{subtext}</p>}
          </div>
        </div>
        {!!error && <div className="help is-danger">{error.message?.toString()}</div>}
      </label>
    </>
  );
};
