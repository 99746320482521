import React, { useState } from "react";
import { Dialog } from "../Dialog";
import "./VideoModal.scss";

interface VideoModalProps {
  /** Label for the button */
  renderTrigger: (onClick: () => void) => React.ReactNode;

  /** An iframe embed component */
  renderVideo: () => React.ReactNode;

  /** Aria label */
  dialogAriaLabel: string;
}

export const VideoModal = ({ renderTrigger, renderVideo, dialogAriaLabel }: VideoModalProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {renderTrigger(() => setIsOpen(true))}
      <Dialog
        className="video-modal"
        isOpen={isOpen}
        ariaLabel={dialogAriaLabel}
        autoFocus
        closeOnClickOutside
        onClose={() => setIsOpen(false)}
        contentClassName="p-0"
        fullscreenOnMobile={false}>
        {isOpen ? renderVideo() : null}
      </Dialog>
    </>
  );
};
