import React from "react";
import Sticky from "react-stickynode";
import * as zenscroll from "zenscroll";
import classNames from "classnames";
import Image from "next/legacy/image";
import { NavMenuWithPageScroll, NavMenuItem } from "../..";
import { useWindowDimensions, ResponsiveBreakpoints } from "../../utils";
import { Chapter, ChapterProps } from "./Chapter";
import imageLoader from "../../utils/imageLoader";
import { useTranslation } from "react-i18next";

import "./Chapters.scss";

export interface ChaptersProps {
  headerImageUrl: string;
  headerImageAlt?: string;
  chapters: (Omit<ChapterProps, "pageUrl"> & { menuTitle?: string })[];
  navLabel: string;
  className?: string;

  /**
   * Fully qualified current page URL without query string or hash component. Used to build sharing links.
   */
  pageUrl: string;
  children?: React.ReactNode;
}

const getDomItemYPositionWithStickyNavOffset = (id: string) => {
  const domItem = document.querySelector(`[data-scroll-id="${id}"]`);

  if (!domItem) {
    return;
  }

  const elementYOffset =
    domItem.getBoundingClientRect().top - document.body.getBoundingClientRect().top;

  // return y offset including the height of the secondary nav, to force the scroll to sit just under the nav
  return elementYOffset - 165;
};

const getNavItems = (content: ChaptersProps["chapters"]): NavMenuItem[] => {
  const navItems: NavMenuItem[] = [];

  for (const c of content) {
    navItems.push({
      label: c.menuTitle || c.title,
      id: `content-${c.slug}`,
      slug: c.slug,
    });
  }

  return navItems;
};

/**
 * Wrapper to display `<Chapter />` and a sticky `<NavMenuWithPageScroll />`
 * generated from `api.Chapter[]`
 *
 * @status stable
 */
export const Chapters: React.FC<ChaptersProps> = ({
  headerImageUrl,
  headerImageAlt,
  chapters,
  navLabel,
  pageUrl,
  className,
  children,
}) => {
  const { t } = useTranslation("ui");
  const { width } = useWindowDimensions();

  if (!chapters) {
    return null;
  }

  const isMobile = width && width < ResponsiveBreakpoints.tablet;
  const navItems: NavMenuItem[] = getNavItems(chapters);

  return (
    <div className={classNames("chapters", className)}>
      <div className="chapters__hero is-relative">
        <Image
          src={headerImageUrl ?? "https://travellocal.com/images/missing-image.jpg"}
          alt={headerImageAlt ?? ""}
          layout="fill"
          objectFit="cover"
          loader={imageLoader}
        />
      </div>

      <div className="container">
        <div className="columns chapters__columns is-marginless">
          <div className="chapters__secondary-nav-container column is-3-tablet is-paddingless is-marginless">
            <Sticky
              enabled={true}
              innerZ={5}
              top={".marketing-header"}
              bottomBoundary=".chapters__location-content-container">
              <NavMenuWithPageScroll
                label={isMobile ? t("Chapters_jumpToSection", "Jump to section...") : navLabel}
                items={navItems}
                onItemClick={(id) => {
                  const scrollTo = document.querySelector(
                    `[data-scroll-id="${id}"]`
                  ) as HTMLElement;

                  if (scrollTo != null) {
                    zenscroll.to(scrollTo);
                  }
                }}
                onSelectChange={(id) => {
                  const scrollTo = getDomItemYPositionWithStickyNavOffset(id);

                  if (scrollTo) {
                    zenscroll.toY(scrollTo);
                  }
                }}
              />
              {children}
            </Sticky>
          </div>
          <div className="chapters__location-content-container column is-9-tablet is-paddingless">
            {chapters.map((c, i) => {
              return (
                <Chapter
                  key={c.slug ?? i}
                  pageUrl={pageUrl}
                  html={c.html}
                  slug={c.slug ?? i}
                  title={c.title}
                />
              );
            })}
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
