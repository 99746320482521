import classNames from "classnames";
import React from "react";

interface Props {
  /**
   * The icon to display. E.g. `<Icon icomoon={Icomoon.plus2} />`.
   * N.B. You can also use an Icomoon classname `<Icon icomoon={"icon-earth" as Icomoon} />`,
   * but it may not be part of our bundle (this is a WIP!)
   */
  icomoon: Icomoon;

  /**
   * Additional class for the icon element
   */
  className?: string;

  title?: string;

  style?: React.CSSProperties;
}

/**
 * Simple component to display an icon. Currently, only selected Icomoon icons are supported.
 *
 * To implement you need to use the Icomoon enum eg, `<Icon icomoon={Icomoon.checkmark} />`
 *
 * @status stable
 */
export const Icon = ({ icomoon, className, ...props }: Props) => {
  return (
    <i
      className={classNames("icon", "icomoon", className, icomoon)}
      {...props}
    />
  );
};

/**
 * Icons that can be used with our Icomoon bundle. See Icon.stories.mdx on what to do if you need a new icon.
 */
export enum Icomoon {
  airplane = "icon-airplane",
  alarm = "icon-alarm",
  arrowDown3 = "icon-arrow-down3",
  arrowLeft3 = "icon-arrow-left3",
  arrowRight3 = "icon-arrow-right3",
  arrowUp3 = "icon-arrow-up3",
  bin5 = "icon-bin5",
  brush = "icon-brush",
  calendar2 = "icon-calendar2",
  checkmark = "icon-checkmark",
  clock = "icon-clock",
  cog = "icon-cog",
  coins = "icon-coins",
  cross = "icon-cross",
  drawer3 = "icon-drawer3",
  embed2 = "icon-embed2",
  eye = "icon-eye",
  eyeBlocked = "icon-eye-blocked",
  facebook = "icon-facebook",
  facebookCirlce = "icon-facebook-circle",
  filePdf = "icon-file-pdf",
  hammerWrench = "icon-hammer-wrench",
  history = "icon-history",
  info = "icon-info",
  instagram = "icon-instagram",
  lamp7 = "icon-lamp7",
  lamp8 = "icon-lamp8",
  link = "icon-link",
  list = "icon-list",
  listNumbered = "icon-list-numbered",
  location5 = "icon-location5",
  map3 = "icon-map3",
  menu = "icon-menu",
  menu7 = "icon-menu7",
  minus2 = "icon-minus2",
  more2 = "icon-more2",
  newTab = "icon-new-tab",
  notification = "icon-notification",
  playOutline = "icon-play-outline",
  plus2 = "icon-plus2",
  popout = "icon-popout",
  power2 = "icon-power2",
  power3 = "icon-power3",
  priceTags = "icon-price-tags",
  priceTags2 = "icon-price-tags2",
  question3 = "icon-question3",
  redo2 = "icon-redo2",
  search2 = "icon-search2",
  sphere = "icon-sphere",
  starEmpty3 = "icon-star-empty3",
  starFull2 = "icon-star-full2",
  starHalf = "icon-star-half",
  twitter = "icon-twitter",
  twitterCircle = "icon-twitter-circle",
  undo2 = "icon-undo2",
  unlink = "icon-unlink",
  user = "icon-user",
  userSolidCircle = "icon-user-solid-circle",
  users = "icon-users",
  users2 = "icon-users2",
  users4 = "icon-users4",
  warning2 = "icon-warning2",
  whatsapp = "icon-whatsapp",
}
