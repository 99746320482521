/* eslint-disable */

import classNames from "classnames";
import React from "react";
import { FaChevronLeft, FaRegPaperPlane } from "react-icons/fa";
import "./FormNavigation.scss";
import { Button } from "../../../atoms/Button/Button";
/**
 * A form navigation component.
 * This component has a back and a continue button for form navigation.
 * The continue button text may be quite long so styles need to take this into account.
 */
type FormNavigationProps = {
  className?: string;
  backButton: {
    type: string;
    text: string;
    onClick?: () => void;
    dataTrackingId?: string;
    props?: any;
  };
  continueButton: {
    type: string;
    text: string;
    onClick?: () => void;
    dataTrackingId?: string;
    props?: any;
  };
  noWrapperStyles?: boolean;
};

export const FormNavigation: React.FC<FormNavigationProps> = ({
  className = "",
  backButton,
  continueButton,
  noWrapperStyles = false,
}) => {
  return (
    <div className={classNames("form-navigation", className)}>
      <div
        className={classNames("buttons-container", {
          "no-style": noWrapperStyles,
        })}>
        <div
          className={classNames("buttons-wrapper", {
            "no-style": noWrapperStyles,
          })}>
          <Button
            className="btn-back"
            onClick={backButton.onClick}
            colour="teal"
            element="button"
            isGhost={true}
            icon={<FaChevronLeft />}
            iconSide="left"
            type={backButton.type}
            data-tracking-id={backButton.dataTrackingId}
            {...backButton?.props}>
            {backButton.text}
          </Button>
          <Button
            className="btn-continue"
            onClick={continueButton.onClick}
            colour="teal"
            element="button"
            icon={<FaRegPaperPlane />}
            iconSide="right"
            type={continueButton.type}
            data-tracking-id={continueButton.dataTrackingId}
            {...continueButton?.props}>
            {continueButton.text}
          </Button>
        </div>
      </div>
    </div>
  );
};

FormNavigation.displayName = "FormNavigation";
