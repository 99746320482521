/* eslint-disable */

import { isBrowser } from "@travellocal/utils";
import classNames from "classnames";
import React from "react";
import { useEffect, useState } from "react";
import * as ReactDOM from "react-dom";
import { supportsObjectFit } from "../../utils";

interface Props {
  id?: string;
  children?: React.ReactNode;
}

/**
 * Wrapper around ReactDOM.createPortal(). Use this to create elements that should exist outside of the main DOM tree - e.g. `Dialog`.
 *
 * There should be an element on the same page with the id `#modal`.
 *
 * @status stable
 */
export const Portal: React.FC<Props> = ({ children, id }) => {
  if (!isBrowser()) {
    console.warn("Portals are not supported in server render.");
    return null;
  }

  const modalRoot = document.getElementById("modal");
  if (!modalRoot) {
    throw new Error("Couldn't find #modal to render into.");
  }

  const [modalElement, setModalElement] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const instanceWrapper = window.document.createElement("div");
    instanceWrapper.setAttribute("data-attr-type", "portal-instance");
    if (id) {
      instanceWrapper.setAttribute("data-attr-id", id);
    }

    // #modal root element is separate to site #host, so we need to add this here as well
    // to make LazyLoadImage work as expected
    const objectFitClassName = supportsObjectFit() ? "has-object-fit" : "no-object-fit";
    instanceWrapper.className = classNames(objectFitClassName);

    setModalElement(instanceWrapper);
    modalRoot.appendChild(instanceWrapper);

    return () => {
      if (modalRoot) {
        modalRoot.removeChild(instanceWrapper);
      }
    };
  }, []);

  if (!modalElement) {
    return null;
  }

  return ReactDOM.createPortal(children, modalElement);
};
