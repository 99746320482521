import React from "react";
import classNames from "classnames";
import { BlogCard, BlogCardProps } from "../../molecules/BlogCard/BlogCard";
import "./BlogArticles.scss";

export interface BlogArticlesProps {
  sectionHeading: string;
  sectionIntro: string;
  articles: BlogCardProps[];
  backgroundColour: "white" | "sage";
}

export const BlogArticles: React.FC<BlogArticlesProps> = ({
  sectionHeading,
  sectionIntro,
  articles = [],
  backgroundColour,
}) => {
  return (
    <section
      className={classNames("blog-articles py-7 is-fluid", {
        "blog-articles__coloured-background": backgroundColour === "sage",
      })}
    >
      <div className="container">
        <div className="blog-articles__heading mb-6">
          <h2 className="title is-2 mb-2">{sectionHeading}</h2>
          <p>{sectionIntro}</p>
        </div>
        <div className="blog-articles__articles columns is-flex-wrap-wrap">
          {articles.slice(0, 4).map((article, index) => (
            <div className="column is-flex" key={index}>
              <BlogCard {...article} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

BlogArticles.displayName = "BlogArticles";
