import React from "react";
import { MetricHighlight } from "../../atoms/MetricHighlight/MetricHighlight";

import "./MetricHighlightGroup.scss";

type MetricHighlightGroupProps = {
  items: React.ComponentProps<typeof MetricHighlight>[];
};

export const MetricHighlightGroup = ({
  items = [],
}: MetricHighlightGroupProps) => {
  if (!items || items.length === 0) return null;

  return (
    <div className="metric-highlight-group">
      {items.map((item, index) => (
        <div key={index} className="metric-highlight-group__item">
          <MetricHighlight title={item.title} text={item.text} />
        </div>
      ))}
    </div>
  );
};
