/* eslint-disable */

import React from "react";
import { useAppState } from "../../utils";
import imageLoader from "../../utils/imageLoader";
import Image from "next/legacy/image";

export const BCorpLogo = (): JSX.Element => {
  const { appState } = useAppState();

  const locale = appState?.locale || `en`;

  let bcorpLogo: JSX.Element | null = null;

  switch (locale) {
    case `en`:
    case `en-gb`:
      bcorpLogo = (
        <div style={{ maxWidth: `268px` }}>
          <Image
            src="https://resources.travellocal.com/wp/uploads/2024/10/15081806/BCorpEn.png"
            alt="BCorp Certified"
            width={536}
            height={208}
            loader={imageLoader}
          />
        </div>
      );
      break;
    case `de`:
      bcorpLogo = (
        <div style={{ maxWidth: `301px` }}>
          <Image
            src="https://resources.travellocal.com/wp/uploads/2024/10/15081804/BCorpDe.png"
            alt="BCorp-zertifiziert"
            width={602}
            height={208}
            loader={imageLoader}
          />
        </div>
      );
      break;
    default:
      const _exhaustiveCheck: never = locale;
      return _exhaustiveCheck;
  }

  return bcorpLogo;
};
