import React from "react";
import { Locale } from "@travellocal/utils";
import { AbtaLogo, AstaLogo, AtolLogo, DrvLogo, TravelifeLogo } from "../../atoms";

interface TrustLogoProps {
  dataTrackingIds?: {
    travelife?: string;
  };
  locale: Locale;
}

export const TrustLogo = ({ dataTrackingIds, locale }: TrustLogoProps): JSX.Element | null => {
  switch (locale) {
    case "en":
      return (
        <>
          <AstaLogo type="dark" className="trust__asta-logo" />
          <a
            href="https://reimagine.travellocal.com/en/travelife"
            data-tracking-id={dataTrackingIds?.travelife}>
            <TravelifeLogo type="dark" className="trust__travelife-logo" />
          </a>
        </>
      );
    case "en-gb":
      return (
        <>
          <div className="trust__ukLogos is-flex is-align-items-center">
            <AbtaLogo type="dark" className="trust__abta-logo" />
            <AtolLogo type="dark" className="trust__atol-logo" width="65" />
            <a
              href="https://reimagine.travellocal.com/en/travelife"
              data-tracking-id={dataTrackingIds?.travelife}>
              <TravelifeLogo type="dark" className="trust__travelife-logo" />
            </a>
          </div>
        </>
      );
    case "de":
      return (
        <>
          <DrvLogo type="dark" className="trust__drv-logo" />
          <TravelifeLogo
            type="dark"
            className="trust__travelife-logo"
            dataTrackingId={dataTrackingIds?.travelife}
          />
        </>
      );
    default:
      return null;
  }
};
