import classNames from "classnames";
import React from "react";
import { useState, useRef, useEffect, useContext } from "react";
// import FocusLock from "react-focus-lock";
import { Icon, Portal, useClickOutsideHook } from "../..";
import { DialogContext } from "./DialogContext";

import "./Dialog.scss";
import { Icomoon } from "../../atoms";

export interface DialogProps {
  /**
   * Whether the dialog is open.
   */
  isOpen: boolean;

  /**
   * Function to run when the dialog is closed.
   */
  onClose?: () => void;

  /**
   * Label to be shown to screen readers for this dialog. Use a short description relating to the purpose of the dialog, e.g. "Welcome message dialog" or "Newsletter signup dialog".
   */
  ariaLabel: string;

  /**
   * A title for the dialog. If provided, it will display in a header bar.
   */
  title?: string;

  /**
   * Whether to show an X button in the top right of the dialog.
   */
  hasCloseButton?: boolean;

  /**
   * Additional class for the grey overlay `tl-dialog__overlay`
   */
  overlayClassName?: string;

  /**
   * Additional class for the element `tl-dialog__content`
   */
  contentClassName?: string;

  /**
   * Additional class for the dialog `tl-dialog`
   */
  className?: string;

  /**
   * Whether input focus should be automatically moved to the dialog.
   */
  autoFocus?: boolean;

  /**
   * An aria role for the dialog. Use `alertdialog` if the dialog relates to a warning or error. More info: https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Techniques/Using_the_alertdialog_role
   */
  role?: "dialog" | "alertdialog";

  /**
   * Whether the dialog should be fullscreen on mobile devices.
   */
  fullscreenOnMobile?: boolean;

  /**
   * Whether the dialog should close if there's a click outside the dialog area, or if the escape key is pressed while focussed.
   */
  closeOnClickOutside?: boolean;

  /**
   * Whether to grey-out the rest of the page when this dialog is open.
   */
  hasOverlay?: boolean;

  /**
   * Whether to prevent the page from scrolling when this dialog is open.
   */
  lockPageScroll?: boolean;

  children?: React.ReactNode;
}

const defaultProps = {
  autoFocus: true,
  role: "dialog",
  fullscreenOnMobile: true,
  closeOnClickOutside: false,
  hasOverlay: true,
  lockPageScroll: true,
} as const;

/**
 * A component to display content over other content. Designed to be accessible.
 *
 * @status stable
 */
export const Dialog: React.FC<DialogProps> = (props) => {
  const dialogContext = useContext(DialogContext);
  if (!dialogContext) {
    throw new Error("Dialog must be used within a DialogContext");
  }

  const [ariaHidden, setAriaHidden] = useState<boolean>(true);
  const { isOpen, ariaLabel, fullscreenOnMobile, closeOnClickOutside, hasOverlay, lockPageScroll } =
    { ...defaultProps, ...props };

  const innerModalRef = useRef<HTMLDivElement>(null);
  const [dialogId, setDialogId] = useState<number | null>(null);

  useClickOutsideHook(innerModalRef, props.onClose, closeOnClickOutside);

  useEffect(() => {
    let currentDialogId = dialogId;
    if (isOpen) {
      currentDialogId = dialogContext.register(lockPageScroll);
      setDialogId(currentDialogId);
    } else {
      dialogContext.unregister(dialogId);
    }

    setAriaHidden(!isOpen || !dialogContext.isOnTop(currentDialogId));

    return () => {
      dialogContext.unregister(currentDialogId);
    };
  }, [isOpen, lockPageScroll]);

  useEffect(() => {
    setAriaHidden(!isOpen || !dialogContext.isOnTop(dialogId));
  }, [dialogContext.count]);

  // Overlay wrapper, or not
  const Overlay = React.useCallback(
    ({ children }: React.PropsWithChildren) => {
      if (hasOverlay) {
        const overlayDialogClass = classNames("tl-dialog__overlay", props.overlayClassName, {
          "tl-dialog__overlay--fs-mobile": fullscreenOnMobile,
        });
        return <div className={overlayDialogClass}>{children}</div>;
      } else {
        return <>{children}</>;
      }
    },
    [hasOverlay]
  );

  const innerDialogClass = classNames("tl-dialog", props.className, {
    "tl-dialog--fs-mobile": fullscreenOnMobile,
  });

  if (!isOpen) {
    return null;
  }

  return (
    <Portal id={`dialog-${dialogId}`}>
      {/* <FocusLock shards={[innerModalRef]} autoFocus={autoFocus}> */}
      <Overlay>
        <div
          ref={innerModalRef}
          className={innerDialogClass}
          role={props.role}
          aria-label={ariaLabel}
          aria-hidden={ariaHidden}>
          {(props.title || props.hasCloseButton) && (
            <header
              className={classNames("tl-dialog__header", {
                "tl-dialog__header--fs-mobile": fullscreenOnMobile,
                "tl-dialog__header--white": !props.title && props.hasCloseButton,
              })}>
              {props.title && <span>{props.title}</span>}

              {props.hasCloseButton && (
                <button
                  aria-label="Close Dialog"
                  className="tl-dialog__close"
                  onClick={props.onClose}>
                  <Icon icomoon={Icomoon.cross} />
                </button>
              )}
            </header>
          )}

          <div className={classNames("tl-dialog__content", props.contentClassName)}>
            {props.children}
          </div>
        </div>
      </Overlay>
      {/* </FocusLock> */}
    </Portal>
  );
};

Dialog.defaultProps = defaultProps;

Dialog.displayName = "Dialog";
