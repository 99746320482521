import React from "react";
import classNames from "classnames";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { EmailInput } from "../../atoms/Forms/EmailInput/Email";
import { SuccessMessage } from "./SuccessMessage";
import { NativeText } from "../../atoms/Forms/NativeInputs/text";
import "./SubscribeWrapper.scss";

export interface SubscribeWrapperProps {
  subscribeClassName?: string;
  subscribeColumnClassName?: string;
  subscribeContentClassName?: string;
  subscribeFormClassName?: string;
  title?: string;
  bodyText?: string;
  locale?: string;
  signupSource?: string;
  country?: string;
  dataTrackingIds?: {
    email: string;
    firstName: string;
    submit?: string;
  };
}

export const SubscribeWrapper: React.FC<SubscribeWrapperProps> = ({
  subscribeClassName,
  subscribeColumnClassName,
  subscribeContentClassName,
  subscribeFormClassName,
  title,
  bodyText,
  locale,
  signupSource,
  country,
  dataTrackingIds,
}) => {
  const [showSuccessMessage, setShowSuccessMessage] = React.useState<boolean>(false);
  const { t } = useTranslation();
  const methods = useForm();

  return (
    <div className={classNames("subscribe-wrapper padding-6", subscribeClassName)}>
      <section className={classNames("subscribe", subscribeColumnClassName)}>
        <div className={classNames("subscribe--content", subscribeContentClassName)}>
          <h3 className="title is-size-3 has-text-white mb-3">
            {title ?? "Reimagine travel with us"}
          </h3>
          <p>
            {bodyText ??
              "Join our newsletter for more inspiration, local expertise, and updates on how we’re making travel a force for good."}
          </p>
        </div>
        <div className={classNames("subscribe--form", subscribeFormClassName)}>
          {showSuccessMessage && <SuccessMessage />}
          {!showSuccessMessage && (
            <FormProvider {...methods}>
              <form
                className="subscription-form"
                onSubmit={methods.handleSubmit(() => {
                  setShowSuccessMessage(true);
                })}>
                <div className="firstName">
                  <NativeText
                    required
                    name="first_name"
                    placeholder={t("common:firstName", "First name")}
                    id="first_name"
                    className="is-borderless"
                    data-tracking-id={dataTrackingIds?.firstName}
                  />
                </div>
                <EmailInput
                  name="email"
                  labelClassName="u-visually-hidden"
                  placeholder={t("common:email", "Email")}
                  dataTrackingId={dataTrackingIds?.email}
                />
                <input
                  type="hidden"
                  name="source_market"
                  value={locale === "en-gb" ? "en-GB" : locale}
                />
                <input type="hidden" name="signup_source" value={signupSource} />
                <input type="hidden" name="source_destination" value={country || ``} />
                <button className="button" type="submit" data-tracking-id={dataTrackingIds?.submit}>
                  {t("common:subscribe", "Subscribe")}
                </button>
              </form>
            </FormProvider>
          )}
        </div>
      </section>
    </div>
  );
};
