import React from "react";
import { Link } from "../..";
import "./PageNav.scss";

export interface PageNavItem {
  id?: string;
  href: string;
  target?: string;
  label: React.ReactNode;
  children?: Omit<PageNavItem, "children">[];
}

interface Props {
  items: PageNavItem[];
}

export const PageNav: React.FC<Props> = ({ items = [] }) => {
  return (
    <ul className="page-nav is-size-5">
      {items.map((item, i) => (
        <li key={item.id ?? i} className="page-nav__link page-nav__link--item">
          <Link href={item.href} target={item.target}>
            {item.label}
          </Link>

          {item.children && item.children.length > 0 && (
            <ul>
              {item.children.map((subItem, i) => (
                <li key={i} className="page-nav__link page-nav__link--subitem">
                  <Link href={subItem.href} target={subItem.target}>
                    {subItem.label}
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </li>
      ))}
    </ul>
  );
};
