/* eslint-disable */

import React, { forwardRef, LegacyRef } from "react";

export const NativeSelect = forwardRef(
  (props: JSX.IntrinsicElements["select"], ref: LegacyRef<HTMLSelectElement>) => {
    return (
      <div className="select">
        <select ref={ref} {...props} className="select p-4" />
      </div>
    );
  }
);
