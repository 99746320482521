import React, { ReactNode } from "react";
import { FieldValues, FormProvider, SubmitHandler, useForm } from "react-hook-form";

export const Form = ({
  children,
  onSubmit,
  useFormProps,
}: {
  useFormProps: Parameters<typeof useForm>[0];
  children: ReactNode | ReactNode[];
  onSubmit: SubmitHandler<FieldValues> | ((data: FieldValues) => void);
}) => {
  const methods = useForm(useFormProps);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>{children}</form>
    </FormProvider>
  );
};
