import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, Icon, Icomoon } from "../../atoms";

export interface FAQButtonProps {
  /**
   * Url for the ❓ icon. If not provided it's not shown.
   */
  faqUrl?: string;

  className?: string;

  dataTrackingId?: string;
}

export const FAQButton: React.FC<FAQButtonProps> = ({ className, faqUrl, dataTrackingId }) => {
  const { t } = useTranslation("ui");
  const faqAriaLabel = t("Header_faqAriaLabel", "Frequently asked questions");
  return (
    <Link
      className={classNames("button is-link is-flex p-3", className)}
      href={faqUrl}
      aria-label={faqAriaLabel}
      title={faqAriaLabel}
      target="_blank"
      data-tracking-id={dataTrackingId}
    >
      <Icon
        icomoon={Icomoon.question3}
        className="is-block m-0"
        style={{ fontSize: 23 }}
      />
    </Link>
  );
};
