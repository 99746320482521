import React from "react";
import classNames from "classnames";
import { CallToAction } from "../..";
import "./QuietLink.scss";

export interface QuietLinkProps {
  title: string;
  description: string;
  cta: CallToAction;
  className?: string;
}

export const QuietLink: React.FC<QuietLinkProps> = ({
  title,
  description,
  cta,
  className,
}) => {
  return (
    <div className={classNames("quiet-link", className)}>
      <a
        className="heading-link"
        target={cta?.target}
        href={cta?.url}
      >
        <h3 className="title is-4 mb-3">{title}</h3>
      </a>
      <p>{description}</p>
      <a className="link" target={cta?.target} href={cta?.url}>
        {cta?.title}
      </a>
    </div>
  );
};

QuietLink.displayName = "QuietLink";
