import React, { useEffect, useState } from "react";
import { AppProps, NextWebVitalsMetric } from "next/app";
import { supportsObjectFit, GoogleAnalyticsHelper } from "@travellocal/ui";
import classNames from "classnames";
import { environment, isBrowser } from "@travellocal/utils";
import Head from "next/head";
import { appWithTranslation } from "next-i18next";
import { Analytics } from "@vercel/analytics/react";
import nextI18NextConfig from "../next-i18next.config.js";
import "@travellocal/ui/dist/public/icomoon.css";
import "@travellocal/ui/dist/styles.css";
import "../styles/globals.scss";

if (typeof window === "undefined") {
  // Ignore an annoying warning
  React.useLayoutEffect = () => {};
}

environment.set("NEXT_PUBLIC_TL_API", process.env.NEXT_PUBLIC_TL_API);
environment.set("NEXT_PUBLIC_TM_API", process.env.NEXT_PUBLIC_TM_API);
environment.set("NEXT_PUBLIC_PREPROD_API", process.env.NEXT_PUBLIC_PREPROD_API);
environment.set("NEXT_PUBLIC_LOCIZE_PROJECT_ID", process.env.NEXT_PUBLIC_LOCIZE_PROJECT_ID);
environment.set("NEXT_PUBLIC_LOCIZE_API_KEY", process.env.NEXT_PUBLIC_LOCIZE_API_KEY);

/**
 * The base component of the site - every page is wrapped by this component.
 * See https://nextjs.org/docs/advanced-features/custom-app
 */
const CustomApp = (props: AppProps) => {
  const { Component, pageProps } = props;
  const [isMounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(isBrowser());
  }, []);

  const browserClassNames = isMounted
    ? classNames({
        "has-object-fit": supportsObjectFit(),
        "no-object-fit": !supportsObjectFit(),
      })
    : undefined;

  return (
    <div className={classNames("site", "marketing", browserClassNames)}>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>

      <Component {...pageProps} />

      <Analytics />
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default appWithTranslation(CustomApp, nextI18NextConfig as any);

export function reportWebVitals(metric: NextWebVitalsMetric) {
  if (metric.label === "web-vital") {
    GoogleAnalyticsHelper.logWebVital(metric);
  }
}
