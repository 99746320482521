import React, { useCallback } from "react";
import { Button, Dropdown, DropdownBody, DropdownBodyProps, Link } from "../../..";
import { useTranslation, Trans } from "react-i18next";
import "./ReimagineTravelDropdown.scss";
import Image from "next/legacy/image";
import imageLoader from "../../../utils/imageLoader";

const ReimaginingTravelHoverLink = (props: { children: React.ReactNode, dataTrackingId?: string }) => {
  const { t } = useTranslation("ui");
  return (
    <div>
      <Button element="button" className="is-header-link is-family-primary" data-tracking-id={props.dataTrackingId}>
        {t("ReimagineTravelDropdown_linkLabel", "Reimagining travel")}
      </Button>
      {props.children}
    </div>
  );
};

type Props = React.PropsWithChildren<DropdownBodyProps & ReimagineTravelMenuButtonProps>;

interface ReimagineTravelMenuButtonProps {
  dataTrackingId?: string;
}

const ReimagineTravelDropdownBody: React.FC<Props> = (props) => {
  const tabIndex = props.isVisible ? 0 : -1; // If visible, allow tabbing through links
  const { t } = useTranslation("ui");

  return (
    <DropdownBody {...props} visualStyle="arrow">
      <div className="reimagine-dropdown__body has-background-white">
        <div className="reimagine-dropdown__leftCol">
          <ul>
            <li>
              <a
                href={t(
                  "routes:reimagineOverview",
                  "https://reimagine.travellocal.com/en"
                )}
                tabIndex={tabIndex}
                data-tracking-id={props?.dataTrackingId && `${props.dataTrackingId}.overview`}
              >
                {t("common:pageName_reimagineOverview", "Overview")}
              </a>
            </li>
            <li>
              <a
                href={t(
                  "routes:reimagineOurCommitment",
                  "https://reimagine.travellocal.com/en/our-climate-commitment"
                )}
                tabIndex={tabIndex}
                data-tracking-id={props?.dataTrackingId && `${props.dataTrackingId}.commitment`}
              >
                {t("common:pageName_reimagineOurCommitment", "Our commitment")}
              </a>
            </li>
            <li>
              <a
                href={t(
                  "routes:reimagineEnvironment",
                  "https://reimagine.travellocal.com/en/environmental-protection"
                )}
                tabIndex={tabIndex}
                data-tracking-id={props?.dataTrackingId && `${props.dataTrackingId}.environment`}
              >
                {t("common:pageName_reimagineEnvironment", "Environment")}
              </a>
            </li>
            <li>
              <a
                href={t(
                  "routes:reimaginePeopleSociety",
                  "https://reimagine.travellocal.com/en/people-and-society"
                )}
                tabIndex={tabIndex}
                data-tracking-id={props?.dataTrackingId && `${props.dataTrackingId}.peopleAndSociety`}
              >
                {t(
                  "common:pageName_reimaginePeopleAndSociety",
                  "People & Society"
                )}
              </a>
            </li>
            <li>
              <Link href={t("routes:about", "/about-us")} tabIndex={tabIndex} data-tracking-id={props?.dataTrackingId && `${props.dataTrackingId}.aboutUs`}>
                {t("Header_about", "About us")}
              </Link>
            </li>
          </ul>
        </div>
        <div className="reimagine-dropdown__rightCol">
          <Image
            src="https://resources.travellocal.com/images/dropdown/reimagine.jpg"
            width={300}
            objectFit="cover"
            layout="fill"
            height={169}
            loader={imageLoader}
            alt=""
          />
          <div>
            <Trans i18nKey="ui:ReimagineTravelDropdown_flyoutLabel">
              Reimagining
              <br />
              travel
            </Trans>
          </div>
        </div>
      </div>
    </DropdownBody>
  );
};

/**
 * Inspiration dropdown on the marketing site.
 *
 * @status development
 */
export const ReimagineTravelDropdown: React.FC<ReimagineTravelMenuButtonProps> = ({ dataTrackingId }) => {
  const body = useCallback(
    (bodyProps: Props) => <ReimagineTravelDropdownBody dataTrackingId={dataTrackingId} {...bodyProps} />,
    []
  );

  const renderTrigger = (triggerProps: { children?: React.ReactNode }) => (
    <ReimaginingTravelHoverLink dataTrackingId={dataTrackingId}>
      {triggerProps.children}
    </ReimaginingTravelHoverLink>
  );

  return (
    <Dropdown
      className="reimagine-travel-dropdown"
      Trigger={renderTrigger}
      openOn="click"
      Body={body}
      hasShade={false}
    />
  );
};
