import React from "react";

const PulsatingSvg = (): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
      <path d="M22.0569 0H0V22.4579C4.4889 12.5349 12.311 4.57052 22.0569 0Z" fill="#007C80" />
    </svg>
  );
};

export const PulsatingTl = (): JSX.Element => {
  return (
    <div>
      <div className="tl-pulse-wrapper">
        <PulsatingSvg />
        <PulsatingSvg />
        <PulsatingSvg />
        <PulsatingSvg />
      </div>
    </div>
  );
};
