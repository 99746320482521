import { isBrowser } from "@travellocal/utils";

/**
 * Whether the browser supports the object-fit CSS property
 * https://caniuse.com/#feat=object-fit
 */
export const supportsObjectFit = () => {
  return (
    isBrowser() &&
    "objectFit" in document.documentElement.style &&
    window.location.search.indexOf("no-of") < 0
  );
};

export const supportsIntersectionObserver = () => {
  return isBrowser() && "IntersectionObserver" in window;
};
