import React from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import Image from "next/legacy/image";

import "./ImageSelect.scss";
import { Icomoon, Icon } from "../../../atoms";
import imageLoader from "../../../utils/imageLoader";

type ImageSelectProps = {
  type: "radio" | "checkbox";
  register: UseFormRegisterReturn;
  imgURL: string;
  label: string;
  helper?: string;
  badge?: string;
} & JSX.IntrinsicElements["input"];
export const ImageSelect = ({
  type,
  register,
  imgURL,
  label,
  helper,
  badge,
  id,
  ...props
}: ImageSelectProps) => {
  const internalId = id ?? register.name;

  return (
    <div className="image-select">
      <input
        {...props}
        {...register}
        id={internalId}
        type={type}
        className="image-select__input u-visually-hidden"
      />
      {helper && (
        <span className="image-select__helper is-size-7" tabIndex={-1}>
          {helper}
        </span>
      )}
      <label
        className="image-select__container"
        htmlFor={internalId}
        tabIndex={-1}
      >
        <div className="image-select__checked">
          <Icon icomoon={Icomoon.checkmark} />
        </div>
        <div className="image-select__image-container">
          <Image src={imgURL} layout="fill" objectFit="cover" alt="" loader={imageLoader} />
          {badge && (
            <div className="badge image-select__badge is-size-7 px-4 py-2">
              {badge}
            </div>
          )}
        </div>
        <span className="title is-5 is-clickable">{label}</span>
      </label>
    </div>
  );
};
