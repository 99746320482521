import React from "react";
import classNames from "classnames";
import { Link } from "../../atoms/Link/Link";

interface CrumbItem {
  label: string;
  href?: string;
}

interface Props {
  className?: string;
  items: CrumbItem[];
}

/**
 * Display a list of links in the form of breadcrumbs.
 *
 * @status review
 */
export const Breadcrumbs: React.FC<Props> = ({ className, items }) => {
  if (!items?.length) {
    return null;
  }

  const parentItemIndex = items.length - 2;
  return (
    <div className={classNames("breadcrumbs", className)}>
      <span className="is-hidden-tablet pr-2">{"<"}</span>

      {items.map((item, index) => {
        const includeSeparator = index > 0;
        const itemClassname = classNames({
          "is-hidden-mobile": index !== parentItemIndex,
        });
        return (
          <React.Fragment key={item.href + item.label}>
            {includeSeparator && (
              <span className="is-hidden-mobile pr-2">/</span>
            )}

            {item.href && (
              <Link
                className={classNames("link pr-2", itemClassname)}
                href={item.href}
              >
                {item.label}
              </Link>
            )}

            {!item.href && (
              <span className={classNames("pr-2", itemClassname)}>
                {item.label}
              </span>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};
