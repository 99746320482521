import React, { useMemo } from "react";
import classnames from "classnames";
import Image from "next/legacy/image";
import { useTranslation } from "react-i18next";
import imageLoader from "../../utils/imageLoader";

interface Props
  extends Pick<
    React.ImgHTMLAttributes<HTMLImageElement>,
    "alt" | "className" | "height" | "width"
  > {
  type: "light" | "dark" | "colour";
  dataTrackingId?: string;
}

const defaultAlt = "TravelLocal is a Travelife Partner";

/**
 * Travelife's logo
 *
 * @status stable
 */
export const TravelifeLogo: React.FC<Props> = ({ type, alt, className, ...rest }) => {
  const { t, i18n } = useTranslation("ui");

  const imgClassName = classnames(
    "lazyload",
    "travelife-logo",
    `travelife-logo--${type}`,
    className
  );

  const altText = useMemo(() => alt ?? t("TravelifeLogo_alt", defaultAlt), [alt, i18n?.language]);
  let path = ``;

  switch (type) {
    case "light":
      path =
        "https://resources.travellocal.com/wp/uploads/2023/06/21135036/Travelife_logo_light.svg";
      break;
    case "dark":
      path =
        "https://resources.travellocal.com/wp/uploads/2023/06/21135047/Travelife_logo_dark.svg";
      break;
    case "colour":
      path =
        "https://resources.travellocal.com/wp/uploads/2023/06/21135038/Travelife_logo_colour-1.svg";
      break;
    default:
      path = "";
      break;
  }

  const width = rest.width ? Number(rest.width) : 139;
  const height = rest.height ? Number(rest.height) : 50;

  return (
    <Image
      alt={altText}
      className={imgClassName}
      loader={imageLoader}
      src={path}
      title={altText}
      {...rest}
      width={width}
      height={height}
    />
  );
};

TravelifeLogo.defaultProps = {
  alt: defaultAlt,
};
