import classnames from "classnames";
import Image from "next/legacy/image";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import imageLoader from "../../utils/imageLoader";

interface Props
  extends Pick<
    React.ImgHTMLAttributes<HTMLImageElement>,
    "alt" | "className" | "height" | "width"
  > {
  type: "light" | "dark" | "colour";
}

const defaultAlt = "TravelLocal is a member of ASTA - book with confidence.";

/**
 * The ASTA logo.
 *
 * @status stable
 */
export const AstaLogo: React.FC<Props> = ({
  type,
  alt,
  className,
  ...rest
}) => {
  const { t, i18n } = useTranslation("ui");

  const imgClassName = classnames(
    "lazyload",
    "asta-logo",
    `asta-logo--${type}`,
    { "": type === "light" },
    className
  );

  const altText = useMemo(
    () => alt ?? t("AstaLogo_alt", defaultAlt),
    [alt, i18n?.language]
  );

  const path = `https://resources.travellocal.com/images/asta_logo_${type}.svg`;

  const width = rest.width ? Number(rest.width) : 148
  const height = rest.height ? Number(rest.height) : 65

  return (
    <Image
      className={imgClassName}
      alt={altText}
      title={altText}
      src={path}
      loader={imageLoader}
      {...rest}
      width={width}
      height={height}
    />
  );
};

AstaLogo.defaultProps = {
  alt: defaultAlt,
};
