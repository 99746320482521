import React from "react";
import classNames from "classnames";
import "./Radio.scss";
import { useFormContext } from "react-hook-form";
import { slugify } from "../../../utils/slugify";

interface Props {
  className?: string;
  id: string;
  label: string;
  subtext?: string;
  inputProps: WithRequired<JSX.IntrinsicElements["input"], "name">;
}

export const Radio: React.FC<Props> = ({
  className,
  label,
  subtext,
  inputProps,
}) => {
  const { register } = useFormContext();
  const innerId = inputProps?.id ?? inputProps.name + slugify(label);
  return (
    <label htmlFor={innerId} className={classNames(className, "form-radio")}>
      <input
        {...inputProps}
        type="radio"
        id={innerId}
        {...register(inputProps.name)}
      />
      <div className="form-radio-text-container">
        <p className="radio-label">{label}</p>
        {subtext && <p className="radio-subtext">{subtext}</p>}
      </div>
    </label>
  );
};
