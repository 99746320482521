import classNames from "classnames";
import React from "react";
import { Icomoon, Icon } from "../../atoms";

import "./BulletList.scss";

export interface IBulletList {
  icon: Icomoon;
  iconClassname?: string;
  size?: "regular" | "large";
  items: string[];
  itemClassname?: string;
}

export const BulletList = ({
  icon = Icomoon.checkmark,
  iconClassname = "has-text-teal",
  items = [],
  itemClassname,
  size = "regular",
}: IBulletList) => {
  return (
    <ul
      className={classNames("bullet-list", {
        "is-size-5": size === "large",
      })}
    >
      {items.map((item, index) => (
        <li
          className={classNames("bullet-list__item", itemClassname)}
          key={index}
        >
          <Icon icomoon={icon} className={classNames(iconClassname)} />
          {item}
        </li>
      ))}
    </ul>
  );
};
