import React from "react";
import classNames from "classnames";
import "./HtmlRenderBlock.scss";

interface Props {
  /**
   * The HTML to be rendered.
   */
  htmlString: string;

  /**
   * Optional classname to apply to the container element.
   */
  className?: string;

  /**
   * HTML Tag to use for the container element.
   */
  tag?: keyof JSX.IntrinsicElements;

  children?: never[];
}

/**
 * A component to render a string of HTML, probably sourced from one of our content APIs.
 *
 * @status stable
 */
export const HtmlRenderBlock: React.FC<Props> = (props) => {
  const Element = props.tag ?? "div";

  const html = props.htmlString.replaceAll("<img ", `<img loading="lazy" `);

  return (
    <Element
      className={classNames("html-render-block", props.className)}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
};

HtmlRenderBlock.defaultProps = {
  tag: "div",
};

HtmlRenderBlock.displayName = "HtmlRenderBlock";
