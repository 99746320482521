import classNames from "classnames";
import React, { useId } from "react";
import {
  FieldValues,
  RegisterOptions,
  useFormContext,
  UseFormRegisterReturn,
} from "react-hook-form";

import "./BoxSelect.scss";
import { Icomoon, Icon } from "../../../atoms";

type BoxSelectProps = {
  type: "radio" | "checkbox";
  label: string;
  sublabel?: string;
  helper?: string;
  register?: UseFormRegisterReturn;
  rules?: RegisterOptions<FieldValues, string>;
  className?: string;
  value: string;
  inputProps?: JSX.IntrinsicElements["input"];
  dataTrackingId?: string;
} & JSX.IntrinsicElements["div"];

export const BoxSelect = ({
  className,
  type,
  label,
  sublabel,
  helper,
  register,
  rules,
  value,
  inputProps = {},
  dataTrackingId,
  ...props
}: BoxSelectProps) => {
  const id = useId();
  const formContext = useFormContext();
  const internalRegister =
    register ||
    (formContext &&
      formContext.register(inputProps.name || "boxselect", {
        ...rules,
      }));

  return (
    <div className={classNames("box-select", className)} {...props}>
      <input
        {...inputProps}
        {...internalRegister}
        id={id}
        type={type}
        className="box-select__input u-visually-hidden"
        value={value}
        data-tracking-id={dataTrackingId}
      />
      {helper && <span className="box-select__helper is-size-7">{helper}</span>}
      <label className="box-select__container" htmlFor={id}>
        <div className="box-select__checked">
          <Icon icomoon={Icomoon.checkmark} />
        </div>
        <div className="box-select__title">{label}</div>
        {sublabel && <div className="box-select__subtitle">{sublabel}</div>}
      </label>
    </div>
  );
};
