import classnames from "classnames";
import React from "react";

import "./Loader.scss";

interface LoaderProps {
  className?: string;
  children?: JSX.Element;
}

/**
 * A loader
 *
 * @status stable
 */
export const Loader = ({ className }: LoaderProps) => {
  return (
    <div className={classnames("spinner", className)}>
      <div className="bounce" style={{ animationDelay: "-0.32s" }} />
      <div className="bounce" style={{ animationDelay: "-0.16s" }} />
      <div className="bounce" />
    </div>
  );
};
