import classNames from "classnames";
import React from "react";
import { Icomoon, Icon } from "../../atoms";
import { FaRegBell } from "react-icons/fa";

import "./Toast.scss";

type ToastProps = {
  type: "success" | "action" | "issue";
  title?: string;
  action?: React.ReactNode;
  icon?: React.ReactNode;
  children?: React.ReactNode;
};

export const Toast = ({ type, title, icon, children, action }: ToastProps) => {
  const typeIcon = (() => {
    if (icon) {
      return icon;
    } else {
      switch (type) {
        case "action":
          return <FaRegBell className="Toast_icon" />;
        case "issue":
          return <Icon icomoon={Icomoon.notification} className="Toast_icon" />;
        case "success":
        default:
          return <Icon icomoon={Icomoon.checkmark} className="Toast_icon" />;
      }
    }
  })();

  return (
    <div
      className={classNames("Toast", {
        "Toast--success": type === "success",
        "Toast--action": type === "action",
        "Toast--issue": type === "issue",
      })}
    >
      <div className="Toast_container">
        {(title || action) && (
          <div className="Toast_header">
            <div className="Toast_title">
              {title && (
                <>
                  {typeIcon}
                  <div>{title}</div>
                </>
              )}
            </div>
            <div className="Toast_action">{action}</div>
          </div>
        )}
        {children && <div className="Toast_children">{children}</div>}
      </div>
    </div>
  );
};
