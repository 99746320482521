import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "../../../atoms";

export interface HowItWorksMenuButtonProps {
  className?: string;
  dataTrackingId?: string;
}

export const HowItWorksMenuButton: React.FC<HowItWorksMenuButtonProps> = ({
  className,
  dataTrackingId
}) => {
  const { t } = useTranslation("ui");

  return (
    <div className={className}>
      <Link href={t("routes:howItWorks", "/how-it-works")} className="button button-comp is-header-link is-family-primary" data-tracking-id={dataTrackingId}>
        {t("HowItWorks_linkLabel", "How it works")}
      </Link>
    </div>
  );
};