import React from "react";
import classNames from "classnames";
import { Icomoon, Icon, Link } from "../../atoms";

import "./FAQsList.scss";

interface Props {
  hasBg?: boolean;
  questions: {
    question: string;
    url: string;
    dataTrackingId?: string;
    helpScoutArticleId?: string;
  }[];
  listContainerClassname?: string;
}

/**
 * Component to render a list of links to FAQs.
 */
export const FAQsList: React.FC<Props> = ({ hasBg, questions, listContainerClassname }: Props) => {
  return (
    <div className="faqs-list">
      <ul className={classNames("column is-8-desktop is-9-tablet", listContainerClassname)}>
        {questions.map((item, index) => (
          <li
            className={classNames("faqs-list__question is-size-4", {
              "faqs-list__question--has_bg": hasBg,
            })}
            key={index}
          >
            <Link href={item.url} data-beacon-article={item.helpScoutArticleId} data-tracking-id={item.dataTrackingId}>
              {item.question}
              <Icon icomoon={Icomoon.arrowRight3} />
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};
