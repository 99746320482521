/* eslint-disable */

import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { FeaturedSelect, FeaturedSelectProps } from "../../atoms";
import { useWindowDimensions } from "../../utils";

import "./DestinationSelect.scss";

export interface DestinationOption {
  /** A user-friendly label for this destination */
  label: string;
  /** A code-friendly value, e.g. a slug or a URL. */
  value: string;
  /** Whether this destination is enabled or not.*/
  enabled: boolean;
}

interface DestinationSelectCustomProps {
  destinationsList: DestinationOption[];
  isLoading?: boolean;
  name: string;
}

export type DestinationSelectProps = DestinationSelectCustomProps &
  FeaturedSelectProps<DestinationOption>;

export const DestinationSelect = ({
  destinationsList,
  isLoading = false,
  className,
  name,
  ...rest
}: DestinationSelectProps) => {
  const { t } = useTranslation("ui");

  const { isMobile } = useWindowDimensions();

  return (
    <FeaturedSelect
      {...rest}
      name={name}
      className={classNames("destination-select has-text-left", className)}
      placeholder={
        isMobile
          ? t("DestinationSelect_placeholderShort", "Search...")
          : t("DestinationSelect_placeholder", "Where do you want to visit?")
      }
      noOptionsMessage={() =>
        t("DestinationSelect_noResults", "No results found. Are you sure that's a country?")
      }
      isLoading={isLoading}
      options={destinationsList}
      components={{
        SingleValue: ({ children, getValue }) => {
          const [currentValue] = getValue() ?? [];
          return (
            <>
              {currentValue ? (
                <span className="has-text-elephant">{currentValue.label}</span>
              ) : (
                children
              )}
            </>
          );
        },
        // TODO for some reason overriding SelectContainer at all causes the search box to unfocus on Android. PE-2336
        // I stripped it back to this code but even with this I still got the bug...
        // This is the bare minimum according to the documentation https://react-select.com/components
        //
        // SelectContainer: ({ children, ...props }) => {
        //   const Container = components.SelectContainer;
        //   return (
        //     <Container {...props}>
        //       {children}
        //     </Container>
        //   );
        // },
        //
        // This is the original code, which has the "not enabled country" UX, which we can't use anyway until PE-2210 is done.
        //
        // SelectContainer: ({ children, ...props }) => {
        //   const [currentValue] = props.getValue() ?? [];
        //   const Container = components.SelectContainer;
        //   const selectionNotEnabled =
        //     currentValue && !currentValue.enabled && props.isFocused;
        //   return (
        //     <Container {...props}>
        //       <div
        //         className={classNames({
        //           "destination-select--country-disabled": selectionNotEnabled,
        //         })}
        //       >
        //         {children}
        //         {selectionNotEnabled && (
        //           <ClickOutsideHook
        //             onClose={() => props.setValue(null, "deselect-option")}
        //           >
        //             <div className="destination-select__no-country">
        //               {t(
        //                 "DestinationSelect_resultNotEnabled",
        //                 "Sorry we’re not currently offering trips here. We’re always adding new countries though so check back soon!"
        //               )}
        //             </div>
        //           </ClickOutsideHook>
        //         )}
        //       </div>
        //     </Container>
        //   );
        // },
      }}
    />
  );
};
