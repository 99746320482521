import React from "react";
import classNames from "classnames";
import "./Container.scss";

interface Props {
  className?: string;

  /**
   * Tag to use for the main column element.
   */
  Element?: keyof React.ReactHTML;

  children?: React.ReactNode;
}

/**
 * Component for [Bulma's Container](https://bulma.io/documentation/layout/container/). Deprecated - just use the css class instead.
 *
 * @status deprecated
 * @deprecated
 */
export const Container: React.FC<Props> = ({
  children,
  className,
  Element = "div",
}) => {
  return (
    <Element className={classNames("container", "tl-container", className)}>
      {children}
    </Element>
  );
};
