/* eslint-disable */

import { isBrowser } from "@travellocal/utils";
import { memo, useEffect, useState } from "react";
import { useWindowDimensions, ResponsiveBreakpoints } from "../../utils";

type RenderFunc = () => JSX.Element;
type BreakpointRenderFuncs = {
  [TKey in keyof typeof ResponsiveBreakpoints]: RenderFunc;
};

interface OwnProps extends Partial<BreakpointRenderFuncs> {
  /**
   * The component to render below tablet breakpoint, and if no other render funcs are defined.
   */
  mobile: RenderFunc;
}

/**
 * Server-side-rendering-safe way to render different components at different breakpoints.
 *
 * @status stable
 */
export const ResponsiveContainer = memo(
  ({ mobile, tablet, desktop, widescreen, fullhd }: OwnProps) => {
    const viewport = useWindowDimensions();
    const [isMounted, setMounted] = useState(false);
    useEffect(() => {
      setMounted(isBrowser());
      if (viewport.width == null && viewport.height == null) {
        console.warn(
          "useWindowDimensions is reporting no dimensions. Is WindowDimensionsProvider set up in the app?"
        );
      }
    }, []);

    // First render (whether server or browser) should always be the same -
    // we're using mobile layout as mobiles are generally less capable than desktops
    if (!isMounted || !viewport || viewport?.isMobile) {
      const Component = mobile();
      return Component;
    } else if (viewport.width < ResponsiveBreakpoints.desktop) {
      const Component = (tablet ?? mobile)();
      return Component;
    } else if (viewport.width < ResponsiveBreakpoints.widescreen) {
      const Component = (desktop ?? tablet ?? mobile)();
      return Component;
    } else if (viewport.width < ResponsiveBreakpoints.fullhd) {
      const Component = (widescreen ?? desktop ?? tablet ?? mobile)();
      return Component;
    } else {
      const Component = (fullhd ?? widescreen ?? desktop ?? tablet ?? mobile)();
      return Component;
    }
  }
);
