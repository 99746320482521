import classNames from "classnames";
import React from "react";

import "./Tooltip.scss";

interface Props {
  /**
   *  The text of the tooltip
   */
  text: string;

  /**
   *  Set to "small" when there is a small amount of text
   *  that will not need to wrap over more than one line
   */
  textLength?: "small";
  
  children?: React.ReactNode;
}

/**
 * The Tooltip component wraps around any child element it is given.
 * This could be an icon, anchor, button, etc., or any other component you wish.
 *
 * @status stable
 */
export class Tooltip extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  public render() {
    const { text, textLength: textSize } = this.props;

    return (
      <div
        className={classNames("tl-tooltip", {
          "small-text": textSize === "small",
        })}
        aria-label={text}
        data-testid="tooltip"
      >
        {this.props.children}
      </div>
    );
  }
}
