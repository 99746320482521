import React from "react";
import { CallToAction, HtmlRenderBlock, Link, WPImage } from "../..";
import Image from "next/legacy/image";
import imageLoader from "../../utils/imageLoader";

interface Props {
  chapters: {
    content: string;
    isThereACallToAction: boolean;
    callToAction?: CallToAction;
    heading?: string;
    image?: WPImage;
  }[];
}

export const PageArticleContent: React.FC<Props> = ({ chapters = [] }) => {
  return (
    <div>
      {chapters.map((chapter, index) => (
        <div key={index} className="has-fade-in" style={{ animationDelay: `${200 * index}ms` }}>
          {chapter.heading && (
            <>
              <h2 className="title is-3 my-5">{chapter.heading}</h2>
              <hr />
            </>
          )}
          {chapter.image && (
            <Image
              alt={chapter.image.altText}
              src={chapter.image.sourceUrl}
              layout="responsive"
              className="my-5"
              width={chapter.image.mediaDetails.width}
              height={chapter.image.mediaDetails.height}
              loader={imageLoader}
            />
          )}
          <HtmlRenderBlock htmlString={chapter.content} className="my-5" />

          {chapter.isThereACallToAction && chapter.callToAction && (
            <Link
              href={chapter.callToAction.url}
              target={chapter.callToAction.target}
              className="button my-5 is-large">
              {chapter.callToAction.title}
            </Link>
          )}
        </div>
      ))}
    </div>
  );
};
