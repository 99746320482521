/* eslint-disable */

import React, { useCallback } from "react";
import classNames from "classnames";
import { Icomoon, Icon } from "../../Icon/Icon";
import { RegisterOptions, useFormContext } from "react-hook-form";
import "./DateInput.scss";

interface Props {
  className?: string;
  hasError?: boolean;
  inputProps: WithRequired<JSX.IntrinsicElements["input"], "name">;
  rules?: RegisterOptions;
}

export const DateInput: React.FC<Props> = ({
  className,
  hasError,
  inputProps,
  rules,
  ...props
}) => {
  const { watch, register } = useFormContext();
  const hasValue = !!watch(inputProps.name);
  const handleClick = useCallback((e: any) => {
    const el = e.target;
    if (el) {
      try {
        el.showPicker();
      } catch (err) {
        console.error("Couldn't open picker", err);
      }
    }
  }, []);

  return (
    <div
      className={classNames(
        className,
        "date-input",
        { "date-input-errored": hasError },
        { "date-input-greyed": !hasValue }
      )}
      {...props}>
      <input
        onClick={handleClick}
        {...inputProps}
        type="date"
        {...register(inputProps.name, rules)}
      />
      <Icon icomoon={Icomoon.calendar2} />
    </div>
  );
};
