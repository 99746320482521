/* eslint-disable */

import React from "react";
import * as accounting from "accounting";
import classNames from "classnames";

import "./AccountingJsString.scss";

interface IAccountingJsStringProps extends React.HTMLProps<HTMLSpanElement> {
  price: number;
  symbol: string;
  precision: number;
  roundUpToNearest?: number;
  symbolBehind: boolean;
  /**
   * Controls order of price and symbol in resulting string
   * True: £XXX.XX
   * False: XXX.XX GBP
   */
  formatForCustomer?: boolean;
  extraSpaceBetweenSymbolAndPrice?: boolean;
  thousandsSeparator?: string;
  decimalSeparator?: string;
}

export const AccountingJsString: React.FC<IAccountingJsStringProps> = (props) => {
  let {
    symbol,
    roundUpToNearest,
    price,
    precision,
    formatForCustomer,
    className,
    symbolBehind,
    extraSpaceBetweenSymbolAndPrice,
    thousandsSeparator,
    decimalSeparator,
    ...htmlProps
  } = props;

  // Default to true to maintain compat
  formatForCustomer = formatForCustomer != null ? formatForCustomer : true;

  if (roundUpToNearest) {
    price = Math.ceil(price / roundUpToNearest) * roundUpToNearest;

    if (roundUpToNearest >= 1) {
      precision = 0;
    }
  }

  let classes = classNames(className, "money-string");
  let isNegative = price < 0;
  let priceString = accounting.formatNumber(
    Math.abs(price),
    precision,
    thousandsSeparator,
    decimalSeparator
  );

  const extraSpace = extraSpaceBetweenSymbolAndPrice ? ` ` : ``;

  return formatForCustomer ? (
    <span className={classes} {...htmlProps}>
      {isNegative && "\u2212"}
      {symbolBehind ? (
        <span className="symbol">
          {symbol}
          {extraSpace}
        </span>
      ) : null}
      {priceString}
      {symbolBehind ? null : (
        <span className="symbol">
          {extraSpace}
          {symbol}
        </span>
      )}
    </span>
  ) : (
    <span className={classes} {...htmlProps}>
      {isNegative && "\u2212"}
      {priceString}
      <span className="symbol"> {symbol}</span>
    </span>
  );
};
