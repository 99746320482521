import React from "react";
import Image from "next/legacy/image";
import classNames from "classnames";
import { CallToAction, WPImage } from "../..";
import imageLoader from "../../utils/imageLoader";
import "./ExtremeImagePullout.scss";

interface Props {
  backgroundImage: WPImage;
  subHeading: string;
  heading: string;
  callToAction: CallToAction;
  whichSideShouldTheContentBeOn: "left" | "right";
}

export const ExtremeImagePullout: React.FC<Props> = ({
  backgroundImage,
  subHeading,
  heading,
  callToAction,
  whichSideShouldTheContentBeOn,
}) => {
  return (
    <div className="extreme-image-pullout is-marginless">
      <div className="extreme-image-pullout__image is-relative">
        <Image
          src={backgroundImage?.sourceUrl}
          alt={backgroundImage?.altText}
          width={backgroundImage.mediaDetails.width}
          height={backgroundImage.mediaDetails.height}
          layout="fill"
          objectFit="cover"
          loader={imageLoader}
        />
        <div className="extreme-image-pullout__text is-relative columns is-marginless">
          <div
            className={classNames(
              "column has-text-centered-touch is-offset-2-desktop is-4-desktop is-3-widescreen",
              {
                "is-offset-6-desktop is-offset-7-widescreen":
                  whichSideShouldTheContentBeOn === "right",
              }
            )}
          >
            <span className="subtitle is-uppercase is-family-primary is-letter-spacing-1 is-6 is-spaced has-text-light">
              {subHeading}
            </span>
            <h2 className="title is-size-3 has-text-light extreme-image-pullout__heading">
              {heading}
            </h2>
            {callToAction && (
              <a
                className="button is-white is-ghost mt-5"
                target={callToAction.target}
                href={callToAction.url}
              >
                {callToAction.title}
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

ExtremeImagePullout.defaultProps = {};

ExtremeImagePullout.displayName = "ExtremeImagePullout";
