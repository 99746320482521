/* eslint-disable */

import classNames from "classnames";
import React, { useState, useEffect, useCallback } from "react";
import useEmblaCarousel, { EmblaOptionsType } from "embla-carousel-react";
import { CircleArrow } from "../../atoms";
import { useInView } from "react-intersection-observer";
import "./embla.scss";

type PropType = {
  className?: string;
  options?: EmblaOptionsType;
  children: any;
  arrows: boolean;
  onLoad?: ({
    scrollPrev,
    scrollNext,
  }: {
    scrollPrev: any;
    scrollNext: any;
    scrollTo?: any;
  }) => void;
  onPrevBtnStatus?: (enabled: any) => void;
  onNextBtnStatus?: (enabled: any) => void;
};

const EmblaCarousel: React.FC<PropType> = (props) => {
  const { className, children, options, arrows, onLoad, onNextBtnStatus, onPrevBtnStatus } = props;
  const [emblaRef, emblaApi] = useEmblaCarousel(options);
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

  const loops = options?.loop;
  const [lastCardRef, lastCardInView] = useInView({
    threshold: 1,
  });

  const scrollPrev = useCallback(() => emblaApi && emblaApi.scrollPrev(), [emblaApi]);
  const scrollNext = useCallback(() => emblaApi && emblaApi.scrollNext(), [emblaApi]);
  const scrollTo = useCallback((index: number) => emblaApi && emblaApi.scrollTo(index), [emblaApi]);
  useEffect(() => {
    if (emblaApi && onLoad) {
      onLoad({ scrollPrev, scrollNext, scrollTo });
    }
  }, [emblaApi]);

  useEffect(() => {
    if (onPrevBtnStatus) onPrevBtnStatus(prevBtnEnabled);
  }, [prevBtnEnabled]);
  useEffect(() => {
    if (onNextBtnStatus) onNextBtnStatus(nextBtnEnabled);
  }, [nextBtnEnabled]);
  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    setSelectedIndex(emblaApi.selectedScrollSnap());

    if (loops) {
      if (children.length >= 2) {
        setPrevBtnEnabled(true);
        setNextBtnEnabled(true);
      }
    } else {
      setPrevBtnEnabled(emblaApi.canScrollPrev());
      let canScrollNext = emblaApi.canScrollNext();
      if (lastCardInView && children.length >= 2) {
        canScrollNext = false;
      }
      setNextBtnEnabled(canScrollNext);
    }
  }, [emblaApi, setSelectedIndex, lastCardInView, loops, children]);

  useEffect(() => {
    if (!emblaApi) return;
    onSelect();
    setScrollSnaps(emblaApi.scrollSnapList());
    emblaApi.on("select", onSelect);
    emblaApi.on("reInit", onSelect);
  }, [emblaApi, setScrollSnaps, onSelect]);

  return (
    <>
      <div className={classNames("embla", className)}>
        <div className="embla__viewport" ref={emblaRef}>
          <div className={"embla__container"}>
            {children.map((child: React.ReactNode, index: number) => {
              return (
                <div
                  key={index}
                  className="embla__slide"
                  {...(index === children.length - 1
                    ? {
                        ref: lastCardRef,
                      }
                    : {})}>
                  {child}
                </div>
              );
            })}
          </div>
        </div>

        {arrows && (
          <div className="nav-buttons-container">
            <CircleArrow
              onClick={scrollPrev}
              disabled={!prevBtnEnabled}
              className={classNames("m-1", {
                "is-transparent": !prevBtnEnabled,
              })}
              dir="left"
              theme="dark"
            />
            <CircleArrow
              onClick={scrollNext}
              disabled={!nextBtnEnabled}
              className={classNames("m-1", {
                "is-transparent": !nextBtnEnabled,
              })}
              dir="right"
              theme="dark"
            />
          </div>
        )}
      </div>
    </>
  );
};

export default EmblaCarousel;
