/* eslint-disable */

import classNames from "classnames";
import React, { useRef, useState } from "react";
import { CallToAction } from "../..";
import { Button, CircleArrow } from "../../atoms";
import EmblaCarousel from "../../molecules/Slider/EmblaCarousel";
import "./CardCarousel.scss";

export type CardCarouselProps = {
  cta?: CallToAction;
  children: any;
  infinite?: boolean;
  swipeToSlide?: boolean;
  className?: string;
  beforeChange?: any;
};

/**
 * Wrapper around embla carousel for CardCarousel.
 */
export const CardCarousel: React.FC<CardCarouselProps> = ({
  children,
  infinite = false,
  swipeToSlide = true,
  className,
  cta,
  beforeChange,
  ...props
}) => {
  const scrollToCallbacks = useRef<{ scrollPrev: any; scrollNext: any }>({
    scrollPrev: () => null,
    scrollNext: () => null,
  });
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(true);
  const handleSliderPrev = () => {
    if (scrollToCallbacks.current) {
      scrollToCallbacks.current.scrollPrev();
    }
  };
  const handleSliderNext = () => {
    if (scrollToCallbacks.current) {
      scrollToCallbacks.current.scrollNext();
    }
  };
  return (
    <div className={classNames("card-carousel", className)}>
      <EmblaCarousel
        className="embla-card-carousel"
        options={{ align: "start" }}
        arrows={false}
        onLoad={({ scrollPrev, scrollNext }) => {
          scrollToCallbacks.current = {
            scrollPrev,
            scrollNext,
          };
        }}
        onPrevBtnStatus={(enabled) => setPrevBtnEnabled(enabled)}
        onNextBtnStatus={(enabled) => setNextBtnEnabled(enabled)}
        {...props}>
        {children}
      </EmblaCarousel>

      <div className="level is-mobile mt-5">
        <div className="level-left">
          {cta && (
            <div className="level-item">
              <Button isGhost target={cta.target} href={cta.url}>
                {cta.title}
              </Button>
            </div>
          )}
        </div>
        <div className="level-right mr-1">
          <div className="level-item is-narrow">
            <CircleArrow
              disabled={!prevBtnEnabled}
              className={classNames("m-1", {
                "is-transparent": !prevBtnEnabled,
              })}
              dir="left"
              onClick={() => handleSliderPrev()}
            />
          </div>
          <div className="level-item is-narrow">
            <CircleArrow
              disabled={!nextBtnEnabled}
              className={classNames("m-1", {
                "is-transparent": !nextBtnEnabled,
              })}
              dir="right"
              onClick={() => handleSliderNext()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
