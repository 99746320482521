/* eslint-disable */

import React from "react";
import { SocialButtons } from "../..";
import { HtmlRenderBlock } from "../..";

import "./Chapter.scss";

export interface ChapterProps {
  slug: string;
  title: string;
  html: string;
  pageUrl: string;
}

/**
 * Wrapper for displaying a single piece of content
 *
 * Prefer use within `<Chapters />`
 *
 * @status stable
 */
export const Chapter: React.FC<ChapterProps> = ({ slug, title, html, pageUrl }) => {
  const id = `content-${slug}`;

  const untaggedHtml = html?.replaceAll(/\<.*\>/gi, "").substring(0, 120);

  return (
    <div id={slug} data-scroll-id={id} className="chapter">
      <div className="social-container">
        <SocialButtons
          pageLink={pageUrl + `#${slug}`}
          shortMessage={title}
          longMessage={untaggedHtml}
          enableCopying={true}
        />
      </div>

      <h2 className="title is-family-secondary has-text-weight-light is-size-2 mb-4">{title}</h2>

      <HtmlRenderBlock htmlString={html} />
    </div>
  );
};
