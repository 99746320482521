import classNames from "classnames";
import React, { useRef, useState } from "react";
import { ReviewCard, ReviewCardProps } from "../../molecules";
import { useTranslation } from "react-i18next";
import { CircleArrow } from "../../atoms";
import EmblaCarousel from "../../molecules/Slider/EmblaCarousel";
import { EmblaOptionsType } from "embla-carousel-react";
import "./ReviewCarousel.scss";

interface ReviewCarouselProps {
  reviews: Pick<ReviewCardProps["review"], "quote" | "rating">[];
  loop?: boolean;
}

export const ReviewCarousel = ({ reviews, loop }: ReviewCarouselProps) => {
  const { t } = useTranslation("ui");

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const scrollToCallbacks = useRef<{ scrollPrev: any; scrollNext: any }>({
    scrollPrev: () => null,
    scrollNext: () => null,
  });
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(true);
  const handleSliderPrev = () => {
    if (scrollToCallbacks.current) {
      scrollToCallbacks.current.scrollPrev();
    }
  };
  const handleSliderNext = () => {
    if (scrollToCallbacks.current) {
      scrollToCallbacks.current.scrollNext();
    }
  };
  const options: EmblaOptionsType = {
    loop: !!loop,
  };
  return (
    <div className="review-carousel has-background-navy has-text-white">
      <div className="subtitle is-uppercase is-family-primary is-letter-spacing-1 is-5 has-text-centered px-1">
        {t("ReviewCarousel_title", "What our travellers are saying")}
      </div>

      <div className="review-carousel__slider-container">
        <EmblaCarousel
          options={options}
          className="embla-review-carousel is-clipped"
          arrows={false}
          onLoad={({ scrollPrev, scrollNext }) => {
            scrollToCallbacks.current = {
              scrollPrev,
              scrollNext,
            };
          }}
          onPrevBtnStatus={(enabled) => setPrevBtnEnabled(enabled)}
          onNextBtnStatus={(enabled) => setNextBtnEnabled(enabled)}>
          {reviews.map((review, i) => (
            <div key={i} className="is-size-2 is-size-3-touch is-family-secondary carousel-item">
              <ReviewCard review={review} />
            </div>
          ))}
        </EmblaCarousel>
        <div className="review-carousel__navigation review-carousel__navigation--left">
          <CircleArrow
            disabled={!prevBtnEnabled}
            className={classNames("m-1", {
              "is-transparent": !prevBtnEnabled,
            })}
            dir="left"
            theme="light"
            onClick={() => handleSliderPrev()}
          />
        </div>
        <div className="review-carousel__navigation review-carousel__navigation--right">
          <CircleArrow
            disabled={!nextBtnEnabled}
            className={classNames("m-1", {
              "is-transparent": !nextBtnEnabled,
            })}
            dir="right"
            theme="light"
            onClick={() => handleSliderNext()}
          />
        </div>
      </div>
    </div>
  );
};
