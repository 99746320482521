import React from "react";
import classNames from "classnames";

export interface DropdownBodyProps {
  /**
   * Whether the dropdown body is visible.
   */
  isVisible: boolean;

  /**
   * Visual style.
   */
  visualStyle?: "default" | "arrow";

  /**
   * Additional classnames for this element.
   */
  className?: string;

  onFocus?: () => void;
  onBlur?: () => void;

  children?: React.ReactNode;
}

export const DropdownBody: React.FC<DropdownBodyProps> = ({
  isVisible,
  className,
  onFocus,
  onBlur,
  visualStyle = "default",
  ...rest
}) => {
  return (
    <div
      className={classNames("dropdown__body", className, {
        "u-visually-hidden": !isVisible,
        "dropdown__body-arrow": visualStyle === "arrow",
      })}
      aria-hidden={!isVisible}
      {...(onFocus && { onMouseOver: onFocus, onFocus })}
      {...(onBlur && { onMouseOut: onBlur, onBlur })}
      {...rest}
    />
  );
};

DropdownBody.displayName = "DropdownBody";
