import _ from "lodash";
import classNames from "classnames";
import React, { memo, useEffect, useState } from "react";
import { getMenuItems } from "./mobileMenuHelpers";
import { AppState, Button, Link } from "../../..";
import { Icomoon, Icon } from "../../..";
import { Dialog } from "../../..";
import { useTranslation } from "react-i18next";
import { MobileSubmenu } from "./MobileSubmenu";
import { LocaleSelector, LocaleSelectorProps } from "../../../molecules";
import { FAQButton, FAQButtonProps } from "../FaqButton";
import { useAppState } from "../../../utils";

import "./MobileMenu.scss";

export interface MobileMenuProps extends Pick<FAQButtonProps, "faqUrl"> {
  /** The current relative URL of the page e.g. /articles */
  currentPath: string;

  /** The currently logged in user */
  currentUser: AppState["user"];

  /** Whether the menu is open or not */
  isOpen: boolean;

  /** Callback for when the close button or any menu items are clicked. */
  onClose: () => void;

  onLocaleSelect: LocaleSelectorProps["onClick"];

  shouldShowYourTrip?: boolean;

  dataTrackingIds?: DataTrackingIds;
}

export type DataTrackingIds = {
  logo?: string;
  destinations?: string;
  destinationsCountries?: string;
  inspiration?: string;
  inspirationLinks?: string;
  reimagine?: string;
  howItWorks?: string;
  localeModal?: string;
  faq?: string;
  userLinks?: string;
}

/**
 * Full screen menu for use on mobile. This also functions as a navigation list for screen readers when on desktop.
 */
export const MobileMenu = memo(
  ({
    currentUser,
    currentPath,
    isOpen,
    onClose,
    onLocaleSelect,
    faqUrl,
    shouldShowYourTrip = true,
    dataTrackingIds
  }: MobileMenuProps) => {
    const { t, i18n } = useTranslation("ui");
    const { appState } = useAppState();
    const [activeMenuHref, setActiveHref] = useState<string | undefined>();
    const [menuItems, setMenuItems] = useState(
      getMenuItems(t, appState?.locale, shouldShowYourTrip, dataTrackingIds)
    );

    const isLoggedIn = currentUser != null;

    // Set the active menu item based on the route.
    useEffect(() => {
      if (currentPath) {
        const menuItemHrefs = _.flatMap(menuItems, (item) => {
          switch (item?.type) {
            case "submenu":
              return item.submenu.map((item) => item.href);
            case "link":
              return item.href;
            default:
              return [];
          }
        });
        const matchingPaths = menuItemHrefs.filter((href) =>
          currentPath.includes(href)
        );
        const closestLengthPath = matchingPaths.reduce<string | undefined>(
          (a, b) =>
            !a ||
              Math.abs(a.length - currentPath.length) >=
              Math.abs(b.length - currentPath.length)
              ? b
              : a,
          undefined
        );
        setActiveHref(closestLengthPath);
      }
    }, [isLoggedIn, currentPath, menuItems]);

    useEffect(() => {
      setMenuItems(getMenuItems(t, appState?.locale, shouldShowYourTrip, dataTrackingIds));
    }, [currentUser, currentPath, shouldShowYourTrip, i18n.language, dataTrackingIds]);

    return (
      <Dialog
        ariaLabel="Primary navigation"
        isOpen={isOpen}
        autoFocus={false}
        onClose={onClose}
        className="mobile-menu"
        fullscreenOnMobile
        contentClassName="p-4 pb-6"
      >
        <div className="mobile-menu__header level is-mobile mb-4">
          <div className="level-left">
            <LocaleSelector
              className="is-hidden-tablet"
              onClick={onLocaleSelect}
              dataTrackingId={dataTrackingIds?.localeModal}
            />

            {faqUrl && (
              <FAQButton className="is-hidden-tablet" faqUrl={faqUrl} dataTrackingId={dataTrackingIds?.faq} />
            )}

            <Link
              className="link is-block is-heading text--dark is-hidden-tablet px-3 is-marginless"
              tabIndex={isOpen ? 0 : -1}
              href={
                currentUser
                  ? t("routes:customerIndex", "/account/")
                  : t("routes:customerIndex", "/account/")
              }
              data-tracking-id={dataTrackingIds?.userLinks && (currentUser ? `${dataTrackingIds.userLinks}.sign-out` : `${dataTrackingIds.userLinks}.sign-in`)}
            >
              {currentUser
                ? t("common:logout", "Sign out")
                : t("common:login", "Sign in")}
            </Link>
          </div>

          <div className="level-right">
            <div className="level-item">
              <Button
                element="button"
                className="is-link"
                aria-label={t("common:closeMenuAriaLabel", "Close menu")}
                onClick={onClose}
              >
                <Icon icomoon={Icomoon.cross} />
              </Button>
            </div>
          </div>
        </div>

        <nav
          className="mobile-menu__nav"
          role="navigation"
          aria-label="Primary Navigation"
        >
          <ul className="mobile-menu__list is-family-secondary">
            {menuItems.map((item, i) => {
              if (item.visible === false) {
                return null;
              }
              switch (item?.type) {
                case "link":
                  return (
                    <div key={i}>
                      <li
                        className={classNames("mobile-menu__item", {
                          "mobile-menu__item--active":
                            activeMenuHref === item.href,
                        })}
                      >
                        <Link
                          href={item.href}
                          tabIndex={isOpen ? 0 : -1}
                          className="is-family-secondary is-size-3 py-1 pl-3"
                          onClick={onClose}
                          data-tracking-id={item?.dataTrackingId}
                        >
                          {item.label}
                        </Link>
                      </li>
                    </div>
                  );
                case "submenu":
                  return (
                    <MobileSubmenu
                      key={i}
                      item={item}
                      activeMenuHref={activeMenuHref}
                      dataTrackingId={item?.dataTrackingId}
                    />
                  );
                case "separator":
                  return <hr key={i} className="my-3" />;
              }
            })}
          </ul>
        </nav>
      </Dialog>
    );
  }
);

MobileMenu.displayName = "MobileMenu";
