import React from "react";

import "./MetricHighlight.scss";

type MetricHighlightProps = {
  title: string;
  text: string;
};

export const MetricHighlight = ({ title, text }: MetricHighlightProps) => {
  return (
    <div className="metric-highlight has-text-centered">
      <div className="metric-highlight__title has-text-teal is-size-3">
        {title}
      </div>
      <div className="pt-2 is-size-5 has-text-elephant">{text}</div>
    </div>
  );
};
