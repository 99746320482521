import classNames from "classnames";
import React from "react";
import { StarRating } from "../..";

import "./ReviewCard.scss";

export interface ReviewCardProps {
  review: {
    /** Title of the review */
    title?: string;
    /** Review comment */
    quote: string;
    /** Rating out of 5 */
    rating: number;
    /** Author and date of the review */
    author?: string;
  };
  className?: string;
}

/**
 * A card to display a review.
 *
 * @status stable
 */
export const ReviewCard = ({ review, className }: ReviewCardProps) => {
  return (
    <div className={classNames("review-card p-5 has-text-centered", className)}>
      {review.rating && (
        <StarRating
          currentValue={review.rating}
          numberOfStars={review.rating}
          maximumValue={review.rating}
        />
      )}

      {review.title && <h5 className="title is-size-3 my-3">{review.title}</h5>}
      <p className="my-3">&quot;{review.quote}&quot;</p>
      {review.author && (
        <p className="my-3 subtitle is-uppercase is-family-primary is-letter-spacing-1 is-size-7">{review.author}</p>
      )}
    </div>
  );
};
