import React, { useMemo } from "react";
import { AccountingJsString } from "./AccountingJsString";
import { isZero, site1Api } from "@travellocal/utils";
import { useAppState } from "../..";

export interface PriceInCurrencyProps extends React.HTMLProps<HTMLSpanElement> {
  /**
   * If an ICustomerSampleItineraryPrices array is provided,
   * then select the price in the currency preference defined in AppState.
   * Otherwise, use the currency of the provided IPriceInCurrency.
   */
  price?: site1Api.PriceInCurrency | Record<string, site1Api.PriceInCurrency>;
  displayForCustomer?: boolean;
  roundUpToNearest?: number;
}

function isPriceInCurrency(
  price: PriceInCurrencyProps["price"]
): price is site1Api.PriceInCurrency {
  const priceInCurrency = price as site1Api.PriceInCurrency;
  return priceInCurrency.price !== undefined && priceInCurrency.currency !== undefined;
}

/**
 * Displays a price in customer and non-customer friendly formats
 *
 * @status stable
 */
export const PriceInCurrency: React.FC<PriceInCurrencyProps> = (props) => {
  const { price, displayForCustomer, roundUpToNearest, ...htmlProps } = props;
  const { appState } = useAppState();
  const locale = appState?.locale;

  const activePrice = useMemo(() => {
    if (!price) {
      return null;
    }
    if (isPriceInCurrency(price)) {
      return price;
    }

    if (!locale) {
      return null;
    }

    return price[site1Api.localeToCurrency(locale)];
  }, [locale, price]);

  if (!activePrice) {
    return null;
  }

  let symbol: string;
  if (displayForCustomer) {
    symbol = activePrice.currency.symbol ?? activePrice.currency.code;
  } else {
    symbol = activePrice.currency.code;
  }

  return (
    <AccountingJsString
      {...htmlProps}
      price={isZero(activePrice) ? 0 : activePrice.price}
      symbol={symbol}
      // if locale is de, put symbol afterward, otherwise behind
      symbolBehind={appState?.locale !== "de"}
      formatForCustomer={displayForCustomer}
      precision={activePrice.currency.decimalPlaces}
      roundUpToNearest={roundUpToNearest}
      extraSpaceBetweenSymbolAndPrice={appState?.locale === `de`}
      thousandsSeparator={appState?.locale === `de` ? `.` : undefined}
      decimalSeparator={appState?.locale === `de` ? `,` : undefined}
    />
  );
};

PriceInCurrency.defaultProps = {
  displayForCustomer: true,
};

PriceInCurrency.displayName = "PriceInCurrency";
