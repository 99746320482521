import classNames from "classnames";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { AppState, Dropdown, DropdownBody, DropdownBodyProps } from "../../..";
import { Link, Icon, Icomoon, Button } from "../../../atoms";
import { useAppState } from "../../../utils";
import "./AccountDropdown.scss";

export interface AccountDropdownProps {
  className?: string;

  /**
   * The currently signed in user
   */
  user: AppState["user"];

  dataTrackingId?: string;
}

const AccountClickLink = (props: { children?: React.ReactNode, dataTrackingId?: string }) => {
  const { t } = useTranslation("ui");
  const label = t("AccountDropdown_ariaLabel", "Your account");
  return (
    <div className="account-dropdown__click">
      <Button
        element="button"
        className="is-link p-3 is-flex"
        aria-label={label}
        title={label}
        data-tracking-id={props.dataTrackingId}
      >
        <Icon
          icomoon={Icomoon.userSolidCircle}
          className="is-block m-0 mr-2"
          style={{ fontSize: 23, marginRight: 4 }}
        />
        <Icon
          icomoon={Icomoon.arrowDown3}
          className="is-block m-0"
          style={{ fontSize: 23 }}
        />
      </Button>
      {props.children}
    </div>
  );
};

type Props = React.PropsWithChildren<DropdownBodyProps & AccountDropdownProps>;

const AccountDropdownBody: React.FC<Props> = ({ dataTrackingId, ...props }) => {
  const tabIndex = props.isVisible ? 0 : -1; // If visible, allow tabbing through links
  const { t } = useTranslation("ui");
  const { setUser } = useAppState();

  return (
    <DropdownBody
      {...props}
      visualStyle="arrow"
      className="account-dropdown__body"
    >
      <ul className="account-dropdown__links p-2">
        {props.user && (
          <>
            {"email" in props.user && (
              <li>
                <Link
                  className="link py-2 px-3"
                  tabIndex={tabIndex}
                  href={t("routes:customerSettings", "/my-account/settings")}
                  data-tracking-id={dataTrackingId && `${dataTrackingId}.settings`}
                >
                  {t("common:settings", "Settings")}
                </Link>
              </li>
            )}
          </>
        )}

        <li>
          <Link
            className="link py-2 px-3"
            tabIndex={tabIndex}
            href={
              props?.user
                ? t("routes:logout", "/en/logout")
                : t("routes:login", "/login")
            }
            onClick={() => setUser(null)}
            data-tracking-id={dataTrackingId && (props?.user ? `${dataTrackingId}.sign-out` : `${dataTrackingId}.sign-in`)}
          >
            {props?.user
              ? t("common:logout", "Sign out")
              : t("common:login", "Sign in")}
          </Link>
        </li>
      </ul>
    </DropdownBody>
  );
};

/**
 * Destinations dropdown on the marketing site.
 *
 * @status development
 */
export const AccountDropdown: React.FC<AccountDropdownProps> = ({
  className,
  ...props
}) => {
  const renderBody = useCallback(
    (bodyProps: DropdownBodyProps) => <AccountDropdownBody {...bodyProps} {...props} />,
    [props.user]
  );

  const renderTrigger = (triggerProps: { children?: React.ReactNode }) => (
    <AccountClickLink dataTrackingId={props.dataTrackingId}>
      {triggerProps.children}
    </AccountClickLink>
  );

  return (
    <Dropdown
      className={classNames("account-dropdown", className)}
      Trigger={renderTrigger}
      openOn="click"
      Body={renderBody}
      hasShade={false}
      bodyAlign="trigger"
      direction="down-left"
    />
  );
};
