import React from "react";
import classNames from "classnames";
import "./LogoCorner.scss";

interface Props {
  type: "accent" | "light" | "dark";

  corner?: "tl" | "tr" | "br" | "bl";

  /**
   * Width (and height) of the logo in pixels.
   */
  width?: number;

  /**
   * Additional classname to use as well as .logo.
   */
  className?: string;

  /**
   * Additional styling for the container element.
   */
  style?: React.CSSProperties;
}

/**
 * Just one corner of the logo, used primarily for aesthetics as a visual asset.
 *
 * @status stable
 */
export const LogoCorner: React.FC<Props> = ({
  type,
  width,
  corner,
  className,
  style,
}) => {
  return (
    <svg
      viewBox="0 0 14.9 15.2"
      preserveAspectRatio="xMinYMin"
      className={classNames(
        className,
        "logo-corner",
        `logo-corner--${type}`,
        `logo-corner--${corner}`
      )}
      style={style}
      width={width}
    >
      <path className="st0" d="M14.9,0H0v15.2C3,8.5,8.3,3.1,14.9,0z" />
    </svg>
  );
};

LogoCorner.defaultProps = {
  type: "accent",
  width: 30,
  corner: "tl",
};
