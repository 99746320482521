import React from "react";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaPinterest } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";

export interface SocialIconProps {
  icon: string;
  fill?: string;
}

/**
 * Social Icon
 */
export const SocialIcon: React.FC<SocialIconProps> = ({ icon, fill }) => {
  const iconProps = { fill, style: { height: `100%`, width: `100%` } };

  let iconToDisplay = null;
  switch (icon) {
    case "facebook":
      iconToDisplay = <FaFacebook {...iconProps} />;
      break;
    case "instagram":
      iconToDisplay = <FaInstagram {...iconProps} />;
      break;
    case "linkedin":
      iconToDisplay = <FaLinkedin {...iconProps} />;
      break;
    case "pinterest":
      iconToDisplay = <FaPinterest {...iconProps} />;
      break;
    case "twitter":
      iconToDisplay = <FaTwitter {...iconProps} />;
      break;
    case "youtube":
      iconToDisplay = <FaYoutube {...iconProps} />;
      break;
  }

  return iconToDisplay;
};
