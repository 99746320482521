import React, { PropsWithChildren, useCallback } from "react";
import classNames from "classnames";
import { ResponsiveContainer } from "../../atoms";
import { CardCarousel, CardCarouselProps } from "./CardCarousel";
import { CardGrid, CardGridProps } from "./CardGrid";
import { CallToAction } from "../..";

import "./CardLayout.scss";

interface Props {
  /**
   * How the cards should be displayed.
   * - "auto" - carousel on mobile, grid on tablet up
   * - "grid" - always grid
   * - "carousel" - always carousel
   */
  layout: "auto" | "grid" | "carousel";

  carouselProps?: CardCarouselProps;

  gridProps?: CardGridProps;

  className?: string;

  /** Optional title */
  title?: string;

  /** Optional CTA displayed underneath the grid/carousel */
  cta?: CallToAction;
}

export type CardLayoutProps = PropsWithChildren<Props>;

/**
 * Component to render cards in either a grid or carousel.
 */
export const CardLayout: React.FC<CardLayoutProps> = ({
  className,
  layout,
  carouselProps,
  gridProps,
  children,
  title,
  cta,
}) => {
  const renderCarousel = useCallback(() => {
    return (
      <CardCarousel cta={cta} {...carouselProps}>
        {children}
      </CardCarousel>
    );
  }, [children, carouselProps, cta]);

  const renderGrid = useCallback(() => {
    return <CardGrid cta={cta}>{children}</CardGrid>;
  }, [children, gridProps, cta]);

  const renderMobile = useCallback(
    () => (layout === "grid" ? renderGrid() : renderCarousel()),
    [layout, renderGrid, renderCarousel]
  );

  const renderTablet = useCallback(
    () => (layout === "carousel" ? renderCarousel() : renderGrid()),
    [layout, renderGrid, renderCarousel]
  );

  return (
    <div className={classNames("card-layout", className)}>
      {title && <h2 className="title is-2 mb-6">{title}</h2>}
      <ResponsiveContainer mobile={renderMobile} tablet={renderTablet} />
    </div>
  );
};
