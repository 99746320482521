import React, { useMemo } from "react";
import classnames from "classnames";
import Image from "next/legacy/image";
import { useTranslation } from "react-i18next";
import imageLoader from "../../utils/imageLoader";

interface Props
  extends Pick<
    React.ImgHTMLAttributes<HTMLImageElement>,
    "alt" | "className" | "height" | "width"
  > {
  type: "colour" | "dark" | "light";
}

const defaultAlt = "TravelLocal is a member of DRV - book with confidence.";

/**
 * The DRV logo.
 *
 * @status stable
 */
export const DrvLogo: React.FC<Props> = ({ type, alt, className, ...rest }) => {
  const { t, i18n } = useTranslation("ui");

  const imgClassName = classnames(
    "lazyload",
    "drv-logo",
    `drv-logo--${type}`,
    className
  );

  const altText = useMemo(
    () => alt ?? t("DrvLogo_alt", defaultAlt),
    [alt, i18n?.language]
  );

  const path = `https://resources.travellocal.com/images/drv_logo_${type}.svg`;

  const width = rest.width ? Number(rest.width) : 90;
  const height = rest.height ? Number(rest.height) : 50;

  return (
    <Image
      className={imgClassName}
      alt={altText}
      title={altText}
      src={path}
      loader={imageLoader}
      {...rest}
      width={width}
      height={height}
    />
  );
};

DrvLogo.defaultProps = {
  alt: defaultAlt,
};
