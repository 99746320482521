import React from "react";
import Image from "next/legacy/image";
import { Link } from "../../atoms";
import imageLoader from "../../utils/imageLoader";
import "./ExpertTip.scss";

interface ImageType {
  src: string;
  meta: string;
}

interface Props {
  images: ImageType[];
  title: string;
  text: string;
  linkUrl: string;
  linkLabel: string;
}

/**
 * Component to display tip from a local expert
 *
 * @status development
 */
export const ExpertTip: React.FC<Props> = ({
  images = [],
  title,
  text,
  linkLabel,
  linkUrl,
}) => {
  return (
    <div className="expert-tip">
      <div className="columns is-multiline">
        <div className="column is-offset-2-tablet is-8-tablet is-offset-0-desktop is-4-desktop is-offset-1-widescreen">
          <div className="expert-tip__images-wrap">
            <div className="expert-tip__images">
              {images?.[1] && (
                <div className="expert-tip__image expert-tip__image--secondary">
                  <Image
                    src={
                      images[1].src ??
                      "https://resources.travellocal.com/images/missing-image.jpg"
                    }
                    alt={images[1].meta || ""}
                    layout="fill"
                    objectFit="cover"
                    loader={imageLoader}
                  />
                </div>
              )}
              {images?.[0] && (
                <div className="expert-tip__image expert-tip__image--primary">
                  <Image
                    src={
                      images[0].src ??
                      "https://resources.travellocal.com/images/missing-image.jpg"
                    }
                    alt={images[0].meta || ""}
                    layout="fill"
                    objectFit="cover"
                    loader={imageLoader}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="column is-full-tablet is-8-desktop is-6-widescreen">
          <div className="expert-tip__content">
            <h3 className="subtitle is-uppercase is-family-primary is-letter-spacing-1 is-size-6">{title}</h3>
            <hr />
            <p className="is-family-secondary is-size-5 is-size-3-tablet">
              &quot;{text}&quot;
            </p>
            <Link href={linkUrl} className="link">
              {linkLabel}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
