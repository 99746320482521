import React, { createContext, useContext, useEffect, useReducer, useState } from "react";
import { AppState, AppStateContext } from "./AppState";
import { AppStateReducer, syncCookieProps } from "./AppStateReducer";
import { parseCookies } from "nookies";
import { useTranslation } from "react-i18next";
import { Session, site2Api } from "@travellocal/utils";

export interface AppStateProviderProps {
  initialAppState: Partial<AppState>;
  children: React.ReactNode;
}

const ReactContext = createContext<AppStateContext | null>(null);

export const useAppState = (): AppStateContext => {
  const state = useContext(ReactContext);
  if (state === null) {
    throw new Error("useAppState must be used within an AppStateProvider");
  }
  return state;
};

const initialise = (initialAppState: Partial<AppState>) => {
  return syncCookieProps({
    // Defaults
    user: null,
    locale: "en",
    // Actual
    ...initialAppState,
    cookies: parseCookies(),
  });
};

export const AppStateProvider: React.FC<AppStateProviderProps> = (props) => {
  const [appState, dispatch] = useReducer(AppStateReducer, props.initialAppState, initialise);
  const { i18n } = useTranslation("ui");
  const [loginCheckCount, setLoginCheckCount] = useState(0);

  const context: AppStateContext = {
    appState,
    setCookie: (name, value, options) => dispatch({ type: "set_cookie", name, value, options }),
    loadCookies: (cookies) => dispatch({ type: "load_cookies", cookies }),
    loadUserSession: async () => {
      const user = await site2Api
        .getLoggedInUser(appState.locale)
        .then((result) => result.user)
        .catch(() => null);

      setLoginCheckCount(loginCheckCount + 1);
      dispatch({ type: "set_state", values: { user } });

      return user;
    },
    loginCheckCount,
    setUser: (user) => {
      dispatch({ type: "set_state", values: { user } });

      // Site 1 - clear session
      // Site 2 handled via navigation to /logout page
      if (user == null) {
        Session.clearSession();
      }
    },
    setLocale: (locale) => dispatch({ type: "set_state", values: { locale } }),
  };

  // On client load, we need to re-parse cookies. This is because statically rendered pages are rendered
  // on the server and shared between users - they don't have access to each user's cookies!
  useEffect(() => {
    const cookies = parseCookies();
    context.loadCookies(cookies);
  }, []);

  // Update language in i18next whenever it changes in AppState.
  useEffect(() => {
    if (i18n.language != appState.locale) {
      i18n.changeLanguage(appState.locale);
    }
  }, [appState.locale]);

  return <ReactContext.Provider value={context}>{props.children}</ReactContext.Provider>;
};
