/* eslint-disable */

import classNames from "classnames";
import React, { forwardRef, LegacyRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Icomoon, Icon } from "../../../atoms/Icon/Icon";

type PasswordInputProps = {
  label: string;
  helpMessage?: string;
  hasError: boolean;
  errorMessage?: string;
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

import "./Password.scss";

export const PasswordInput = forwardRef(
  (
    {
      hasError = false,
      errorMessage,
      helpMessage,
      label,
      type, // extract to not override
      ...props
    }: PasswordInputProps,
    ref: LegacyRef<HTMLInputElement>
  ) => {
    const { t } = useTranslation();
    const [showPassword, setShowPassword] = useState(false);

    return (
      <div className="field has-addons">
        <div className="control is-expanded">
          <label className="label">{label}</label>
          <input
            ref={ref}
            className={classNames("input py-2 px-3 password-input", {
              "is-danger": hasError,
            })}
            type={showPassword ? "text" : "password"}
            {...props}
          />
          {helpMessage && <div className="is-size-7 mt-1">{helpMessage}</div>}
          {errorMessage && <p className="help is-danger">{errorMessage}</p>}
        </div>
        <div className="control pt-5">
          <button
            type="button"
            className="button-show-password button is-ghost mt-2"
            aria-label={
              showPassword
                ? t("AccountLogin_showPasswordLabel", "Show password")
                : t("AccountLogin_hidePasswordLabel", "Hide password")
            }
            onClick={() => setShowPassword(!showPassword)}>
            <span className="is-block" style={{ paddingTop: 1 }}>
              {showPassword ? (
                <Icon icomoon={Icomoon.eye} />
              ) : (
                <Icon icomoon={Icomoon.eyeBlocked} />
              )}
            </span>
          </button>
        </div>
      </div>
    );
  }
);
