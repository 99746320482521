import { throttle } from "lodash";
import { useState, useEffect } from "react";

export interface PageScrollPosition {
  isAtTop: boolean;

  /**
   * Scroll position on the page. 0 = at top.
   */
  y: number;

  /**
   * Change since last update of scroll position. Increasing is downwards.
   */
  delta: number;
}

export const usePageScrollPosition = (): PageScrollPosition => {
  const [state, setState] = useState({ y: 0, delta: 0 });

  useEffect(() => {
    const setPageY = throttle(
      () => {
        setState((prevState) => ({
          y: window.pageYOffset,
          delta: window.pageYOffset - prevState.y,
        }));
      },
      100,
      { trailing: true }
    );

    document.addEventListener("scroll", setPageY);
    return () => {
      document.removeEventListener("scroll", setPageY);
    };
  }, []);

  return {
    isAtTop: state.y < 1,
    ...state,
  };
};
