import React from "react";
import classNames from "classnames";

export interface DropdownHoverProps {
  className?: string;
  onFocus: () => void;
  onBlur: () => void;

  /**
   * If set to true, the dropdown will only open via mouse hover (as opposed to focus via tabbing)
   */
  mouseOnly?: boolean;
  children?: React.ReactNode;
}

export const DropdownHover = React.forwardRef<
  HTMLDivElement,
  DropdownHoverProps
>(({ className, onFocus, onBlur, mouseOnly, children }, ref) => {
  return (
    <div
      className={classNames("dropdown__hover", className)}
      onMouseOver={onFocus}
      onMouseOut={onBlur}
      onFocus={!mouseOnly ? onFocus : undefined}
      onBlur={!mouseOnly ? onBlur : undefined}
      data-testid="dropdown-hover"
      ref={ref}
    >
      {children}
    </div>
  );
});

DropdownHover.displayName = "DropdownHover";
