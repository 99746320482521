import React from "react";
import { Icomoon, Icon } from "../../atoms/Icon/Icon";

interface Props {
  type?: "empty" | "half" | "full";
}

export const Star: React.FC<Props> = ({ type }) => {
  let icon: Icomoon = Icomoon.starEmpty3;
  switch (type) {
    case "half":
      icon = Icomoon.starHalf;
      break;
    case "full":
      icon = Icomoon.starFull2;
      break;
  }

  return <Icon className="star" icomoon={icon} />;
};

Star.defaultProps = {
  type: "empty",
};

Star.displayName = "Star";
