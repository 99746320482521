import React from "react";
import classNames from "classnames";
import Image from "next/legacy/image";
import { site1Api as api } from "@travellocal/utils";
import { PriceInCurrency, PriceInCurrencyProps, Button } from "../..";
import imageLoader from "../../utils/imageLoader";
import { useTranslation } from "react-i18next";

import "./TripIdeaCard.scss";

interface CommonProps {
  className?: string;
  heroImageUrl?: string;
  heroImageMeta?: string;
  tabIndex?: number;
  url: string;
  cardTarget: string;
  cardHref: string;
  customiseAndRequestHref: string;
  customiseAndRequestTarget: string;
  customiseAndRequestLabel?: string;
}

interface OldProps extends CommonProps {
  tripIdea: api.TripIdea;
}

interface NewProps extends CommonProps {
  title: string;
  numberOfDays: number;
  pricePerPerson: PriceInCurrencyProps["price"];
}

type Props = NewProps | OldProps;

export const TripIdeaCard = ({
  className,
  heroImageUrl = "https://resources.travellocal.com/images/missing-image.jpg",
  heroImageMeta = "",
  tabIndex,
  cardHref,
  cardTarget,
  customiseAndRequestHref,
  customiseAndRequestTarget,
  customiseAndRequestLabel = "Customize and request",
  ...props
}: Props) => {
  const { t } = useTranslation("ui");

  let title: string;
  let numberOfDays: number;
  let pricePerPerson: PriceInCurrencyProps["price"];

  if ("tripIdea" in props) {
    if (!props.tripIdea) {
      return null;
    }

    title = props.tripIdea.title;
    numberOfDays = props.tripIdea.content.numberOfDays;
    pricePerPerson = props.tripIdea.content.pricePerPersonByCurrency;
  } else {
    title = props.title;
    numberOfDays = props.numberOfDays;
    pricePerPerson = props.pricePerPerson;
  }

  return (
    <div
      className={classNames("trip-idea-card is-flex is-flex-direction-column", className)}
      tabIndex={tabIndex}>
      <a href={cardHref} target={cardTarget} className="trip-idea-card__image-wrapper">
        <div className="image-container">
          <Image
            src={heroImageUrl}
            alt={heroImageMeta}
            layout="fill"
            sizes="(max-width: 600px) 80vw, (max-width: 800px) 40vw, (max-width: 1048px) 30vw, (max-width: 1216px) 215px, (max-width: 1408px) 250px, 300px"
            quality="50"
            objectFit="cover"
            loader={imageLoader}
          />
        </div>
      </a>
      <div className="trip-idea-card__content pb-5">
        <a
          href={cardHref}
          target={cardTarget}
          className="px-5 is-flex is-flex-direction-column is-justify-content-space-between">
          <h3 className="title is-4 mb-6">{title}</h3>
          <div className="is-flex is-flex-direction-column is-justify-content-space-between">
            <span>
              {numberOfDays} {t("common:day", { count: numberOfDays })}
            </span>
            {pricePerPerson && (
              <div className="card-price text--label">
                {t("TripIdeaCard_price_from", "from ")}
                <PriceInCurrency price={pricePerPerson} roundUpToNearest={10} />
                {t("TripIdeaCard_price_perPerson", " per person")}
              </div>
            )}
          </div>
        </a>
        <Button
          className="mt-4 mx-5"
          href={customiseAndRequestHref}
          isGhost
          target={customiseAndRequestTarget}>
          {customiseAndRequestLabel}
        </Button>
      </div>
    </div>
  );
};
