import { neverHere } from "@travellocal/utils";
import classNames from "classnames";
import { capitalize } from "lodash";
import React from "react";
import { GoogleAnalyticsHelper } from "../..";
import { Tooltip } from "../..";
import { Icomoon, Icon } from "../../atoms";
import { useTranslation } from "react-i18next";

import "./SocialButtons.scss";

type Platform = "twitter" | "facebook" | "linkedin" | "pinterest" | "whatsapp";

interface Props {
  pageLink: string;
  shortMessage: string;
  longMessage?: string;
  imageUrl?: string;
  enableCopying?: boolean;
  socialButtons?: Platform[];
}

const gaEventCategory = "Share content";
const gaEventAction = "Click";

export const SocialButtons: React.FC<Props> = ({
  pageLink,
  shortMessage,
  longMessage,
  imageUrl,
  enableCopying,
  socialButtons = ["facebook", "twitter"],
}) => {
  const [copied, setCopied] = React.useState(false);

  const { t } = useTranslation("ui");

  const onCopyClick = async () => {
    GoogleAnalyticsHelper.logEvent(gaEventCategory, gaEventAction, "Copy link");

    await navigator.clipboard.writeText(pageLink);
    setCopied(true);
    setTimeout(() => {
      setCopied(!pageLink);
    }, 3500);
  };

  /**
   * Returns a list of anchor tags, with nested social sharing icons
   */
  const renderButtons = (): JSX.Element[] => {
    if (socialButtons.length < 1) {
      return [];
    }

    // Create array of anchor tags from socialButtons list
    const socialLinks: JSX.Element[] = socialButtons.map((platform) => {
      let shareUrl: string;
      let label = capitalize(platform);
      let iconClass = "";
      const summary = longMessage || shortMessage;

      switch (platform) {
        case "twitter":
          shareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
            shortMessage
          )}&hashtags=TravelLocal&url=${encodeURIComponent(pageLink)}`;
          iconClass = "icon-twitter-circle";
          break;
        case "facebook":
          shareUrl = `http://www.facebook.com/sharer.php?u=${encodeURIComponent(
            pageLink
          )}&t=${encodeURIComponent(shortMessage)}`;
          iconClass = "icon-facebook-circle";
          break;
        case "linkedin":
          shareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
            pageLink
          )}&title=${encodeURIComponent(
            shortMessage
          )}&summary=${encodeURIComponent(summary)}&source=TravelLocal`;
          label = "LinkedIn";
          break;
        case "pinterest":
          shareUrl = `http://pinterest.com/pin/create/button/?url=${encodeURIComponent(
            pageLink
          )}&media=${encodeURIComponent(
            imageUrl ?? ""
          )}&description=${encodeURIComponent(shortMessage)}`;
          break;
        case "whatsapp":
          shareUrl = `whatsapp://send?text=${shortMessage} ${pageLink}`;
          label = "WharsApp";
          break;
        default:
          neverHere(platform, "SocialButtons");
      }

      return (
        <Tooltip key={shareUrl + label} text={label} textLength="small">
          <a
            href={shareUrl}
            target="_blank"
            rel="noreferrer"
            onClick={() => GoogleAnalyticsHelper.logEvent(gaEventCategory, gaEventAction, label)}>
            <i className={classNames("social-button", iconClass)} />
          </a>
        </Tooltip>
      );
    });

    return socialLinks;
  };

  return (
    <div className="copy-social-buttons">
      <div className="cta-text text--mid">{t("SocialButtons_share", "Share")}</div>
      <div className="social-buttons">
        {renderButtons()}

        <div className="copy-link">
          {enableCopying && (
            <Tooltip text={t("SocialButtons_copy", "Copy link")} textLength="small">
              <button className="copy-link-button" type="button" onClick={onCopyClick}>
                <Icon icomoon={Icomoon.link} />
              </button>
            </Tooltip>
          )}
          {enableCopying && copied && (
            <div className="copy-confirmation">
              <span>{t("SocialButtons_copied", "Copied!")}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
