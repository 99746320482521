import { isBrowser } from "@travellocal/utils";

export class PageScrollPositionHelper {
  private static scrollY: number | undefined = undefined;

  /**
   * Store the page's current scroll position, for restoration later.
   */
  public static storeScrollPosition = () => {
    if (!isBrowser()) {
      return;
    }

    PageScrollPositionHelper.scrollY = window.pageYOffset;
  };

  /**
   * If a scroll position is stored, restore it.
   */
  public static restoreScrollPosition = () => {
    if (isBrowser() && PageScrollPositionHelper.scrollY != null) {
      window.scrollBy(0, PageScrollPositionHelper.scrollY - window.pageYOffset);
      PageScrollPositionHelper.scrollY = undefined;
    }
  };
}
