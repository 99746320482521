import React from "react";
import classNames from "classnames";

import "./TextArea.scss";

interface Props extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  isErrored: boolean;
  className?: string;
}

export const TextArea: React.FC<Props> = ({
  isErrored,
  className,
  ...props
}) => {
  return (
    <textarea
      className={classNames("textarea-atom", {
        "textarea-errored": isErrored,
      }, className)}
      {...props}
    />
  );
};
