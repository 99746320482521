/* eslint-disable @typescript-eslint/no-var-requires */
// Config to use files (default at public/locales) in server render, and then update in client.
// Blog post: https://locize.com/blog/next-i18next/
// Example: https://github.com/locize/next-i18next-locize/blob/main/next-i18next.config.js

const LocizeBackend = require("i18next-locize-backend/cjs");
const ChainedBackend = require("i18next-chained-backend").default;
const path = require("path");

const isBrowser = typeof window !== "undefined";
const isDevelopment = process.env.NODE_ENV === "development";

if (!process.env.NEXT_PUBLIC_LOCIZE_PROJECT_ID) {
  console.warn("NEXT_PUBLIC_LOCIZE_PROJECT_ID isn't set, so translations won't work.");
}

const locizeApiKey = (isDevelopment && process.env.NEXT_PUBLIC_LOCIZE_API_KEY) || "";

module.exports = {
  debug: !!process.env.NEXT_PUBLIC_I18N_DEBUG,
  i18n: {
    locales: ["en", "en-gb", "de"],
    defaultLocale: "en",
    localeDetection: false,
    reloadOnPrerender: isDevelopment,
  },
  // NB. the files need to exist at this location BEFORE running
  // build in Vercel, or they won't be deployed correctly
  localePath: path.resolve("./public/locales"),
  defaultNS: "web",
  ns: ["common", "ui", "routes", "web"],
  backend: {
    backendOptions: [
      {
        projectId: process.env.NEXT_PUBLIC_LOCIZE_PROJECT_ID,
        apiKey: locizeApiKey,
        referenceLng: "en",
        allowedAddOrUpdateHosts: [
          "localhost",
          "dev.travellocal.com",
          "frontend.travellocal.com",
          // @TODO remove the below trip.me domains when local environment confirmed working with new travellocal.com domains
          // see cleanup PR:
          "dev.trip.me",
          "frontend.dev.trip.me",
        ],
        version: isDevelopment ? "latest" : "production",
      },
    ],
    backends: isBrowser ? [LocizeBackend] : [],
  },
  use: isBrowser ? [ChainedBackend] : [],
  keySeparator: false,
  saveMissing: isDevelopment && isBrowser,
  // updateMissing: isDevelopment && isBrowser,
};
