import classNames from "classnames";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Dropdown,
  DropdownBody,
  DropdownBodyProps,
  useWindowDimensions,
} from "../../..";
import { Loader, Link, Button } from "../../../atoms";
import "./DestinationDropdown.scss";

export interface DropdownCountry {
  id: number | string;
  /** The full href to the page, including the locale prefix */
  href: string;
  label: string;
}

export interface DestinationDropdownProps {
  /**
   * The countries to display in the dropdown.
   */
  countries?: DropdownCountry[];
  dataTrackingIdButton?: string;
  dataTrackingIdLink?: string;
}

const DestinationClickLink = (props: { children?: React.ReactNode, dataTrackingIdButton?: string }) => {
  const { t } = useTranslation("ui");
  return (
    <div className="destination-dropdown__click">
      <Button element="button" className="is-header-link is-family-primary" data-tracking-id={props?.dataTrackingIdButton}>
        {t("DestinationDropdown_linkLabel", "Destinations")}
      </Button>
      {props.children}
    </div>
  );
};

type Props = React.PropsWithChildren<DropdownBodyProps & DestinationDropdownProps>;

const DestinationDropdownBody: React.FC<Props> = ({ countries, dataTrackingIdLink, ...props }) => {
  // Ensure the dropdown can't be taller than the screen.
  const windowDim = useWindowDimensions();
  const ddBodyRef = useRef<HTMLDivElement | null>(null);
  const [maxHeight, setDdMaxHeight] = useState<string>("100vh");
  useEffect(() => {
    if (!props.isVisible) {
      setDdMaxHeight("100vh");
    } else if (ddBodyRef.current && windowDim.height) {
      const { y, height } = ddBodyRef.current.getBoundingClientRect();
      setDdMaxHeight(
        (countries
          ? Math.min(windowDim.height - y, height)
          : windowDim.height) + "px"
      );
    }
  }, [ddBodyRef, windowDim.height, props.isVisible]);

  return (
    <DropdownBody
      {...props}
      visualStyle="arrow"
      className="destination-dropdown__body"
    >
      <div
        className="destination-dropdown__inner"
        style={{ maxHeight }}
        ref={ddBodyRef}
        tabIndex={-1}
      >
        {countries == null && (
          <Loader className="destination-dropdown__loader" />
        )}
        <ul className="destination-dropdown__links p-2">
          {countries?.map((country) => {
            return (
              <li
                key={country.id ?? country.href}
                className={classNames("destination-dropdown__link", {
                  "u-visually-hidden": !props.isVisible,
                })}
              >
                <Link
                  className="link py-2 px-3"
                  href={country.href}
                  localePrefix={false}
                  tabIndex={props.isVisible ? 0 : -1}
                  data-tracking-id={dataTrackingIdLink && `${dataTrackingIdLink}.${country.label.toLowerCase().split(" ").join("-")}`}
                >
                  {country.label}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </DropdownBody>
  );
};

/**
 * Destinations dropdown on the marketing site.
 *
 * @status development
 */
export const DestinationDropdown: React.FC<DestinationDropdownProps> = (
  props
) => {
  const { dataTrackingIdButton, ...rest } = props;
  const renderBody = useCallback(
    (bodyProps: Props) => <DestinationDropdownBody {...bodyProps} {...rest} />,
    [props.countries]
  );

  const renderTrigger = (triggerProps: { children?: React.ReactNode }) => (
    <DestinationClickLink dataTrackingIdButton={dataTrackingIdButton}>
      {triggerProps.children}
    </DestinationClickLink>
  );

  return (
    <Dropdown
      className="destination-dropdown"
      Trigger={renderTrigger}
      openOn="click"
      Body={renderBody}
      hasShade={false}
      bodyAlign="screen"
    />
  );
};
