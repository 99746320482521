import React from "react";
import { useTranslation } from "react-i18next";
import "./ConnectionInfo.scss";

interface Props {
  imageSrc?: string;
  imageAlt?: string;
  name: string;
  country: string;
  scrollHeight?: number;
  onLinkClick?: () => number;
  dataTrackingId?: string;
}

/**
 * Display connection's information in the customer area.
 */
export const ConnectionInfo: React.FC<Props> = ({
  imageSrc,
  imageAlt,
  name,
  country,
  scrollHeight,
  onLinkClick,
  dataTrackingId,
}: Props) => {
  const { t } = useTranslation();

  if (!name || !country) {
    return null;
  }

  return (
    <section className="connection-notice-wrapper is-flex">
      <div className="connection-notice">
        {imageSrc && (
          <div className="connection-notice--profile">
            <img src={imageSrc} alt={imageAlt} />
          </div>
        )}
        <div className="connection-notice--content">
          <div>
            <p className="status">
              {t("ui:ConnectionInfo_PlanningWith", "You're planning with {{connectionName}}", {
                connectionName: name,
              })}
            </p>
            <p className="subheading">
              {t("ui:ConnectionInfo_Subheading", "{{connectionName}} is in {{country}}", {
                connectionName: name,
                country: country,
              })}
            </p>
          </div>
          <button
            type="button"
            onClick={(e: { preventDefault: () => void }) => {
              e.preventDefault();
              const scrollToHeight = (onLinkClick && onLinkClick()) || scrollHeight;
              window?.scrollTo({
                top: scrollToHeight,
                behavior: "smooth",
              });
            }}
            className={"show-more-button link"}
            data-tracking-id={dataTrackingId}>
            <span>
              {t("ui:ConnectionInfo_MessageConnection", "Message {{connectionName}}", {
                connectionName: name,
              })}
            </span>
          </button>
        </div>
      </div>
    </section>
  );
};
