import classNames from "classnames";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";

import "./RangeSlider.scss";

interface Props {
  className?: string;
  // max step / number of maxStep
  maxStep: number;
  //
  initialStep?: number;
  // title is a function which receives the current step and percentage and returns a string for the title
  title: (args: { step: number; percentDone: number }) => string;
  // the description below the title which also moves with the current step
  subtext?: string;
  averageStep?: number;
  averageEnabled: boolean;
  // to be translated in the parent component
  averageText?: string;
  // granularity of each step to snap to
  increments?: number;
  minimumStep?: number;
  inputProps: WithRequired<JSX.IntrinsicElements["input"], "name">;
}

const calculatePercent = (step: number, minimumStep: number, maximumStep: number) =>
  Math.max(0, Math.min(1, (step - minimumStep) / (maximumStep - minimumStep))) * 100;

/**
 * RangeSlider atom in Forms displays a range slider which is interactable with touch and mouse
 */
export const RangeSlider: React.FC<Props> = ({
  className = "",
  maxStep,
  initialStep = 0,
  averageStep = maxStep / 2,
  increments = 1,
  minimumStep = 0,
  title: _title,
  subtext,
  averageEnabled = false,
  averageText = "Average",
  inputProps,
}) => {
  const [focused, setFocused] = useState(false);
  const averagePercent = useMemo(
    () => calculatePercent(averageStep, minimumStep, maxStep),
    [averageStep, maxStep, minimumStep]
  );
  const { watch, register, setValue } = useFormContext();

  const currentStep = watch(inputProps.name) || minimumStep;

  useEffect(() => {
    setValue(inputProps.name, initialStep - minimumStep * (1 - initialStep / maxStep));
  }, [initialStep, minimumStep, maxStep]);
  const containerRef = useRef<HTMLDivElement>(null);
  const pointerIsDown = useRef(false);
  // the step is clamped between the minimum step and the maximum, and with granularity of increments
  const clampedCurrentStep = useMemo(() => {
    return Math.min(
      maxStep,
      Math.max(
        Math.round((currentStep + minimumStep * (1 - currentStep / maxStep)) / increments) *
          increments,
        minimumStep
      )
    );
  }, [currentStep, increments]);
  // percent done from 0 to 100
  const percentDone = useMemo(
    () => calculatePercent(clampedCurrentStep, minimumStep, maxStep) || 0,
    [clampedCurrentStep, maxStep, minimumStep]
  );
  // labels and title text
  const [leftLabel, title, rightLabel] = useMemo(() => {
    return [
      _title({ step: minimumStep, percentDone: 0 }),
      _title({ step: clampedCurrentStep, percentDone }),
      _title({ step: maxStep, percentDone: 100 }),
    ];
  }, [_title, clampedCurrentStep]);

  // uses css clamp to keep the text container within the component bounds
  const textContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (textContainerRef.current) {
      const innerEl = textContainerRef.current;
      const innerRect = innerEl.getBoundingClientRect();

      const halfWidth = innerRect.width / 2;

      innerEl.style.left = `clamp(${halfWidth}px, ${percentDone}%,  100% - ${halfWidth}px)`;
    }
  }, [percentDone]);
  // pointer event handlers on down, move and up
  // handles logic of setting the current step based on x position
  const updateCurrentStep = useCallback((x: number) => {
    const containerEl = containerRef.current;
    if (containerEl) {
      const rect = containerEl.getBoundingClientRect();
      const xDist = (x - rect.x) / rect.width;

      if (pointerIsDown.current) {
        setValue(inputProps.name, xDist * maxStep);
      }
    }
  }, []);
  const handleDown = useCallback((e: MouseEvent | TouchEvent) => {
    let x = 0;
    if ("touches" in e) {
      x = e.touches[0].pageX;
    } else {
      x = e.pageX;
    }
    const hitEl = hitRef.current;
    if (hitEl) {
      pointerIsDown.current = true;
      // increases the hit area to drag along
      hitEl.style.height = "110px";
      updateCurrentStep(x);
    }
  }, []);

  const handleMove = useCallback((e: MouseEvent | TouchEvent) => {
    let x = 0;
    if ("touches" in e) {
      x = e.touches[0].pageX;
    } else {
      x = e.pageX;
    }
    updateCurrentStep(x);
  }, []);
  const handleUp = useCallback(() => {
    pointerIsDown.current = false;
    const hitEl = hitRef.current;
    if (hitEl) {
      hitEl.style.height = "";
    }
  }, []);
  const hitRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (hitRef.current && textContainerRef.current) {
      const hitEl = hitRef.current;
      const textEl = textContainerRef.current;
      [hitEl, textEl].forEach((el) => {
        el.addEventListener("mousedown", handleDown, false);
        el.addEventListener("mousemove", handleMove, false);
        el.addEventListener("touchstart", handleDown, false);
        el.addEventListener("touchmove", handleMove, false);
      });
      window.addEventListener("mouseup", handleUp, false);
      window.addEventListener("touchend", handleUp, false);

      return () => {
        [hitEl, textEl].forEach((el) => {
          el.removeEventListener("mousedown", handleDown, false);
          el.removeEventListener("mousemove", handleMove, false);
          el.removeEventListener("touchstart", handleDown, false);
          el.removeEventListener("touchmove", handleMove, false);
        });
        window.removeEventListener("mouseup", handleUp, false);
        window.removeEventListener("touchend", handleUp, false);
      };
    }
  }, []);
  const keyboardCaptureElementRef = useRef<HTMLInputElement>(null);
  const handleKeyDown = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e: any) => {
      if (focused) {
        if (["ArrowLeft", "a"].includes(e.key)) {
          setValue(inputProps.name, Math.max(0, currentStep - increments * 1.33));
        }
        if (["ArrowRight", "d"].includes(e.key)) {
          setValue(inputProps.name, Math.min(maxStep, currentStep + increments * 1.33));
        }
        if (e.key === "Enter") {
          setFocused(false);
          const inputEl = keyboardCaptureElementRef.current;
          if (inputEl) {
            inputEl.blur();
          }
        }
      }
    },
    [focused, currentStep]
  );
  useEffect(() => {
    if (typeof document != "undefined") {
      document.addEventListener("keydown", handleKeyDown, false);
      return () => {
        document.removeEventListener("keydown", handleKeyDown, false);
      };
    }
  }, []);
  return (
    <div className={classNames("range-slider", className)} ref={containerRef}>
      {averageEnabled && (
        <div className="range-slider__average-mark" style={{ left: averagePercent + "%" }} />
      )}
      <div className="range-slider__strip range-slider__bg" style={{ width: "100%" }} />
      <div
        className="range-slider__strip range-slider__progress"
        style={{ width: percentDone + "%" }}
      />
      <div className="range-slider__drag-handle-container" style={{ left: percentDone + "%" }}>
        <button
          className="range-slider__drag-handle"
          onFocus={() => {
            setFocused(true);
            const inputEl = keyboardCaptureElementRef.current;
            if (inputEl) {
              inputEl.focus();
            }
          }}
        />
      </div>
      <div ref={textContainerRef} className="range-slider__drag-handle-text-container">
        <div className="range-slider__drag-handle-title">{title}</div>
        <div className="range-slider__drag-handle-subtext">{subtext}</div>
        <input
          className="range-slider__drag-handle-input"
          value={clampedCurrentStep}
          onKeyDown={handleKeyDown}
          ref={keyboardCaptureElementRef}
        />
      </div>
      <div className="range-slider__hit" ref={hitRef} />
      {averageEnabled && (
        <div
          className="range-slider__label range-slider__average"
          style={{ left: averagePercent + "%" }}>
          {averageText}
        </div>
      )}
      <div className="range-slider__label range-slider__left-label">{leftLabel}</div>
      <div className="range-slider__label range-slider__right-label">{rightLabel}</div>
      <input type="hidden" value={clampedCurrentStep} {...register(inputProps.name)} />
    </div>
  );
};
