/* eslint-disable */

import classNames from "classnames";
import React, { useMemo } from "react";

import "./ProgressBar.scss";

interface Props {
  className?: string;
  steps: number;
  currentStep: number;
  stepOfText: string;
  finalStepText: string;
  paragraphClassname?: string;
}

/**
 * ProgressBar molecule in Forms displays a progress bar split into discrete steps
 */
export const ProgressBar: React.FC<Props> = ({
  className = "",
  steps,
  currentStep = 1,
  stepOfText,
  finalStepText,
  paragraphClassname,
}) => {
  const clampedCurrentStep = useMemo(() => {
    return Math.min(steps, Math.max(currentStep, 1));
  }, [currentStep]);
  const percentDone = useMemo(() => {
    return Math.max(0, Math.min(1, clampedCurrentStep / steps)) * 100;
  }, [clampedCurrentStep, steps]);
  return (
    <div className={classNames("progress-bar", className)}>
      <div className="progress-bar__strip progress-bar__bg" style={{ width: "100%" }} />
      <div
        className={classNames(
          "progress-bar__strip progress-bar__progress",
          percentDone === 100 ? "progress-bar__progress-full" : ""
        )}
        style={{ width: percentDone + "%" }}
      />
      <p className={paragraphClassname}>{percentDone === 100 ? finalStepText : stepOfText}</p>
    </div>
  );
};
