import React from "react";
import { QuietLink, QuietLinkProps } from "../../molecules/QuietLink/QuietLink";
import "./QuietLinks.scss"

interface Props {
  quietLinks: QuietLinkProps[];
}

export const QuietLinks: React.FC<Props> = ({ quietLinks = [] }) => {
  return (
    <section className="quiet-links py-6 is-fluid">
      <div className="container">
        <div className="columns is-marginless is-centered">
          {quietLinks.map((link, index) => (
            <div className="column is-4" key={index}>
              <QuietLink {...link} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

QuietLinks.displayName = "QuietLinks";
