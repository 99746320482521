import React from "react";
import Image from "next/legacy/image";
import { Button, CallToAction, LogoCorner, WPImage } from "../..";
import imageLoader from "../../utils/imageLoader";
import classNames from "classnames";
import "./Flyer.scss";

interface Props {
  callToAction: CallToAction;
  image: WPImage;
  content: string;
  contentHeading: string;
  whichSideShouldTheImageBeOn: "left" | "right";
}

export const Flyer: React.FC<Props> = ({
  image,
  callToAction,
  content,
  contentHeading,
  whichSideShouldTheImageBeOn,
}) => {
  return (
    <div
      className={classNames("flyer columns is-marginless pb-5", {
        flyer__reverse: whichSideShouldTheImageBeOn === "right",
      })}
    >
      <div className="column is-1-tablet is-2-desktop is-hidden-mobile" />

      <div className="column is-5-tablet is-3-desktop flyer__image is-relative is-flex is-flex-direction-column is-justify-content-center is-align-items-center-fullhd">
        <Image
          src={image?.sourceUrl}
          alt={image?.altText}
          layout="fill"
          objectFit="cover"
          loader={imageLoader}
        />

        <LogoCorner
          type="light"
          corner={whichSideShouldTheImageBeOn === "left" ? "tl" : "tr"}
          width={35}
        />
      </div>

      <div className="is-5-tablet is-5-desktop flyer__text is-flex is-flex-direction-column is-justify-content-center is-align-items-left">
        <h2 className="title is-3 mb-4">{contentHeading}</h2>
        <p className="mb-5">{content}</p>

        {callToAction && (
          <div className="is-pulled-left">
            <Button
              href={callToAction.url}
              isGhost
              target={callToAction.target}
            >
              {callToAction.title}
            </Button>
          </div>
        )}
      </div>

      <div className="column is-1 is-hidden-touch" />
    </div>
  );
};

Flyer.defaultProps = {
  whichSideShouldTheImageBeOn: "left",
};

Flyer.displayName = "Flyer";
