/* eslint-disable */

import React, { ComponentProps } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { useFormContext } from "react-hook-form";
import PhoneInput2 from "react-phone-input-2";
import { InputWrapper } from "../wrapper";
import { useAppState } from "../../../utils";

import "react-phone-input-2/lib/bootstrap.css";
import "./PhoneInput.scss";

// https://github.com/bl00mber/react-phone-input-2/issues/609#issuecomment-1447736550
// @ts-ignore
const PhoneInputComponent = PhoneInput2.default
  ? // @ts-ignore
    PhoneInput2.default
  : PhoneInput2;

type Country = ComponentProps<typeof PhoneInput2>["country"];

interface Props {
  className?: string;
  country?: Country;
  hasError?: boolean;
  helpMessage?: string;
  inputProps: WithRequired<JSX.IntrinsicElements["input"], "name">;
  label?: string;
  dataTrackingId?: string;
}
/**
 * See available options at https://www.npmjs.com/package/react-phone-input-2
 */
export const PhoneInput: React.FC<Props> = ({
  className,
  country,
  hasError,
  helpMessage,
  inputProps,
  label,
  dataTrackingId,
}) => {
  const { appState } = useAppState();
  const { t } = useTranslation();
  const { register, watch, setValue } = useFormContext();
  const value = watch(inputProps.name);
  let defaultCountry;

  if (country) {
    defaultCountry = country;
  } else {
    switch (appState?.locale) {
      case "en":
        defaultCountry = "us";
        break;
      case "en-gb":
        defaultCountry = "gb";
        break;
      case "de":
        defaultCountry = "de";
        break;
      default:
        defaultCountry = "gb";
        break;
    }
  }

  return (
    <div className={classNames(className, "phone-input", hasError ? "errored" : "")}>
      <InputWrapper
        label={{
          text: label || t("common:phone", "Phone"),
        }}
        helpMessage={helpMessage}
        input={{
          input: "custom",
          props: inputProps,
          children: (
            <>
              <input
                type="hidden"
                value={value}
                data-tracking-id={dataTrackingId}
                {...register(inputProps.name)}
              />
              <PhoneInputComponent
                country={defaultCountry}
                enableSearch={true}
                countryCodeEditable={false}
                searchPlaceholder={t("common:search", "Search")}
                enableLongNumbers={true}
                onChange={(value: any) => {
                  setValue(inputProps.name, value);
                }}
                inputProps={inputProps}
              />
            </>
          ),
        }}
      />
    </div>
  );
};
