import React, { PropsWithChildren } from "react";
import classNames from "classnames";
import { Button, CallToAction } from "../..";

import "./CardGrid.scss";

export type CardGridProps = PropsWithChildren<{
  className?: string;
  cta?: CallToAction;
}>;

export const CardGrid: React.FC<CardGridProps> = ({
  children,
  className,
  cta,
}) => {
  return (
    <>
      <div className={classNames("card-grid columns is-multiline my-0", className)}>
        {React.Children.map(children, (child, i) => (
          <div
            key={i}
            className="column px-3 pt-0 pb-6"
          >
            {child}
          </div>
        ))}
      </div>

      {cta && (
        <Button isGhost target={cta.target} href={cta.url}>
          {cta.title}
        </Button>
      )}
    </>
  );
};
