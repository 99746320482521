import React from "react";
import classNames from "classnames";
import Image from "next/legacy/image";
import imageLoader from "../../utils/imageLoader";
import { Icomoon, Icon } from "../Icon/Icon";

import "./CountrySelect.scss";

export interface CountrySelectProps {
  /**
   *  UTS locale code
   */
  locale: string;

  /**
   *  Button label
   */
  label: string;

  /**
   *  Image (flag) url
   */
  src: string;

  /**
   *  On click callback function
   *
   *  *PARAMS*
   *  **locale:** selected UTS locale code
   */
  onClick?: (locale: string) => void;

  /**
   * Size of the button
   */
  size?: "small" | "normal";

  /**
   * True if this is the current selected locale
   */
  isSelected?: boolean;

  /**
   * Extended classes for the root element of the component
   */
  className?: string;

  tabIndex?: number;

  dataTrackingId?: string;
}

/**
 * Button to display the options for locale selection
 *
 * @status stable
 */
const CountrySelect = ({
  locale,
  label,
  src,
  size = "normal",
  isSelected = false,
  onClick,
  className = "",
  tabIndex,
  dataTrackingId,
}: CountrySelectProps) => {
  return (
    <div className={classNames("country-select", className)}>
      <button
        className={classNames(
          "country-select__button",
          { "country-select__button--is-selected": isSelected },
          { "country-select__button--is-small": size === "small" }
        )}
        type="button"
        onClick={() => {
          onClick?.(locale);
        }}
        tabIndex={tabIndex}
        data-tracking-id={`${dataTrackingId}.${locale}`}
      >
        <Image loader={imageLoader} src={src} alt={label} width={32} height={24} />
        {label}
        {isSelected && size === "small" ? (
          <Icon icomoon={Icomoon.checkmark} className="pl-4" />
        ) : null}
      </button>
    </div>
  );
};

export default CountrySelect;
