import React from "react";
import Image from "next/legacy/image";
import { Link } from "../..";
import classNames from "classnames";
import imageLoader from "../../utils/imageLoader";
import { useTranslation } from "react-i18next";

import "./CollectionCard.scss";

interface Props {
  heroImageUrl?: string;
  heroImageMeta?: string;
  title: string;
  subtitle: string;
  url: string;
  isHero?: boolean;
  imagePriority?: boolean;
}

/**
 * A card to display a collection with a link to the collection page.
 */
export const CollectionCard = ({
  heroImageUrl = "https://resources.travellocal.com/images/missing-image.jpg",
  heroImageMeta = "",
  title,
  subtitle,
  url,
  isHero,
  imagePriority,
}: Props) => {
  const { t } = useTranslation("ui");

  return (
    <div className="collection-card-wrapper">
      <Link href={url}>
        <div
          className={classNames("collection-card", {
            "collection-card__hero": isHero,
          })}
        >
          <div className="collection-card__header">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="53.648"
              height="54.019"
            >
              <g data-name="Group 550" fill="#fff">
                <g data-name="Group 547">
                  <path
                    data-name="Path 447"
                    d="M6.689 30.526L.364 32.159.075 31.04l6.326-1.633zm1.131 4.38L1.494 36.54l-.291-1.129 6.325-1.633zm-2.86.3l-.915.236-1.2-4.629.915-.236z"
                  />
                  <path
                    data-name="Path 448"
                    d="M6.338 27.303l-.113 1.188-6.226-3.557.091-.959 6.783-2.325-.111 1.169-5.818 1.864.031-.32zm-1.515-.254l-.871-.535.314-3.316.954-.343z"
                  />
                  <path
                    data-name="Path 449"
                    d="M7.229 20.8l-5.906-2.793.376-.8 6.029-1.31.01.279-4.537-2.146.475-1 5.906 2.793-.38.8-6.005 1.3-.031-.278 4.537 2.146z"
                  />
                  <path
                    data-name="Path 450"
                    d="M10.516 14.496l-4.6-4.635 1.705-1.694a3.969 3.969 0 011.567-1.014 2.668 2.668 0 011.6-.021 3.611 3.611 0 011.522.965 3.66 3.66 0 01.965 1.538 2.653 2.653 0 01-.031 1.609 3.974 3.974 0 01-1.02 1.557zm.161-1.518l.813-.809a2.265 2.265 0 00.793-1.6 2.392 2.392 0 00-.822-1.628 2.012 2.012 0 00-3.219-.036l-.813.808z"
                  />
                  <path
                    data-name="Path 451"
                    d="M15.83 9.686l-2.862-5.873 2.549-1.242a2.425 2.425 0 011.7-.264 1.934 1.934 0 011.208 2.5 2.417 2.417 0 01-1.247 1.179l-1.485.723 1.2 2.458zm-.532-3.793l1.361-.664a1.427 1.427 0 00.725-.639 1.019 1.019 0 00-.633-1.309 1.443 1.443 0 00-.952.183l-1.361.664z"
                  />
                  <path
                    data-name="Path 452"
                    d="M21.778 7.236L20.339.863l1.155-.261 1.439 6.373z"
                  />
                  <path
                    data-name="Path 453"
                    d="M27.62 6.68a3.351 3.351 0 01-1.685-.424 2.72 2.72 0 01-1.086-1.171 3.9 3.9 0 01-.364-1.784 3.844 3.844 0 01.4-1.763A2.724 2.724 0 0125.992.392a3.352 3.352 0 011.692-.393 3.852 3.852 0 011.242.209 2.519 2.519 0 01.963.582l-.393.859a3.26 3.26 0 00-.876-.527 2.642 2.642 0 00-.925-.169 1.815 1.815 0 00-1.469.6 2.655 2.655 0 00-.535 1.762 2.7 2.7 0 00.5 1.78 1.8 1.8 0 001.458.633 2.633 2.633 0 00.928-.152 3.264 3.264 0 00.885-.51l.377.866a2.514 2.514 0 01-.973.564 3.846 3.846 0 01-1.246.184z"
                  />
                  <path
                    data-name="Path 454"
                    d="M31.144 6.907L33.22.712l1.122.376-2.076 6.195zm4 1.341l-2.082-4.211 4.045-2.021 1.357.454-4.472 2.2.285-.592 2.241 4.632z"
                  />
                  <path
                    data-name="Path 455"
                    d="M37.067 9.208l3.788-5.323 3.558 2.533-.537.755-2.617-1.862-1.054 1.48 2.467 1.756-.543.763-2.467-1.756-1.117 1.57 2.617 1.862-.538.755z"
                  />
                  <path
                    data-name="Path 456"
                    d="M41.623 12.616l5.243-3.9 1.434 1.929a3.965 3.965 0 01.78 1.7 2.667 2.667 0 01-.207 1.589 3.616 3.616 0 01-1.172 1.369 3.659 3.659 0 01-1.659.737 2.654 2.654 0 01-1.588-.26 3.979 3.979 0 01-1.4-1.232zm1.48.375l.684.92a2.266 2.266 0 001.474 1.014 2.391 2.391 0 001.728-.582 2.011 2.011 0 00.494-3.18l-.684-.92z"
                  />
                </g>
                <g data-name="Group 548">
                  <path
                    data-name="Path 457"
                    d="M47.009 23.943a3.168 3.168 0 01.114-1.679 2.68 2.68 0 01.954-1.244 4 4 0 011.7-.677 3.943 3.943 0 011.818.057 2.69 2.69 0 011.313.844 3.173 3.173 0 01.681 1.539 3.619 3.619 0 01.011 1.251 2.7 2.7 0 01-.43 1.077l-.687-.2a3.071 3.071 0 00.4-1 2.8 2.8 0 000-1 2.009 2.009 0 00-.946-1.478 2.9 2.9 0 00-2-.229 2.952 2.952 0 00-1.817.9 2 2 0 00-.386 1.717 2.786 2.786 0 00.338.942 3.067 3.067 0 00.714.8l-.578.419a2.692 2.692 0 01-.772-.865 3.609 3.609 0 01-.427-1.174z"
                  />
                  <path
                    data-name="Path 458"
                    d="M46.987 30.191a3.064 3.064 0 01.645-1.52 2.681 2.681 0 011.3-.851 4.539 4.539 0 013.523.545 2.657 2.657 0 01.978 1.2 3.4 3.4 0 01-.5 3.173 2.655 2.655 0 01-1.3.847 4.535 4.535 0 01-3.521-.554 2.685 2.685 0 01-.982-1.2 3.032 3.032 0 01-.143-1.64zm.7.109a1.917 1.917 0 00.434 1.651 3 3 0 001.849.86 2.964 2.964 0 002.014-.257 2.157 2.157 0 00.479-3.1 2.965 2.965 0 00-1.835-.857 3 3 0 00-2.018.256 1.921 1.921 0 00-.924 1.447z"
                  />
                  <path
                    data-name="Path 459"
                    d="M45.932 34.609l5.844 2.922-.378.755-5.2-2.6-1.445 2.889-.64-.32z"
                  />
                  <path
                    data-name="Path 460"
                    d="M43.335 39.254l4.894 4.328-.56.632-4.358-3.854-2.14 2.42-.536-.474z"
                  />
                  <path
                    data-name="Path 461"
                    d="M39.656 43.079l3.576 5.468-3.494 2.285-.382-.583 2.8-1.828-1.185-1.812-2.642 1.728-.387-.591 2.641-1.728-1.24-1.9-2.8 1.828-.382-.583z"
                  />
                  <path
                    data-name="Path 462"
                    d="M32.106 46.707a3.167 3.167 0 011.681-.068 2.677 2.677 0 011.341.815 4 4 0 01.856 1.614 3.942 3.942 0 01.14 1.813 2.692 2.692 0 01-.7 1.4 3.167 3.167 0 01-1.456.844 3.627 3.627 0 01-1.243.146 2.693 2.693 0 01-1.117-.311l.12-.7a3.069 3.069 0 001.034.287 2.809 2.809 0 001-.112 2.009 2.009 0 001.366-1.1 2.9 2.9 0 00.012-2.015 2.954 2.954 0 00-1.089-1.709 2 2 0 00-1.749-.2 2.8 2.8 0 00-.9.438 3.073 3.073 0 00-.719.8l-.479-.53a2.7 2.7 0 01.777-.861 3.613 3.613 0 011.125-.551z"
                  />
                  <path
                    data-name="Path 463"
                    d="M27.036 47.453l-.083 5.817 2.239.032-.01.716-5.34-.077.01-.716 2.239.032.083-5.817z"
                  />
                  <path
                    data-name="Path 464"
                    d="M22.701 47.025l-1.461 6.367-.823-.189 1.462-6.368z"
                  />
                  <path
                    data-name="Path 465"
                    d="M17.578 45.13a3.057 3.057 0 011.239 1.091 2.675 2.675 0 01.4 1.5 4.535 4.535 0 01-1.626 3.172 2.656 2.656 0 01-1.449.55 3.4 3.4 0 01-2.856-1.469 2.659 2.659 0 01-.4-1.5 4.542 4.542 0 011.635-3.168 2.689 2.689 0 011.451-.554 3.039 3.039 0 011.606.378zm-.323.628a1.918 1.918 0 00-1.7-.108 3 3 0 00-1.4 1.484 2.967 2.967 0 00-.391 1.992 2.158 2.158 0 002.793 1.431 2.969 2.969 0 001.392-1.472 3.007 3.007 0 00.392-2 1.922 1.922 0 00-1.086-1.327z"
                  />
                  <path
                    data-name="Path 466"
                    d="M13.69 42.839l-4.794 4.438-.437-.472 1.2-6.661.2.124-4.014 3.715-.548-.592 4.794-4.438.443.478-1.2 6.648-.2-.105 4.013-3.714z"
                  />
                </g>
              </g>
            </svg>

            <Image
              src={heroImageUrl}
              alt={heroImageMeta}
              layout="fill"
              objectFit="cover"
              loader={imageLoader}
              priority={imagePriority}
              sizes="(max-width: 768px) 100vw, 30vw"
            />
          </div>

          <div className="collection-card__body">
            <p className="subtitle is-uppercase is-family-primary is-letter-spacing-1 is-size-7 mt-3 mb-0 is-spaced">
              {subtitle || "Collection"}
            </p>
            <h3 className="title is-size-3 mb-3">{title}</h3>
            {isHero && (
              <div className="has-text-centered">
                <div className="button is-ghost my-2">
                  {t("CollectionCard_cta", "View trip ideas")}
                </div>
              </div>
            )}
          </div>
        </div>
      </Link>
    </div>
  );
};
