import classnames from "classnames";
import React from "react";
import { useEffect } from "react";

interface Props {
  onClose: () => void;
  children: React.ReactChild;
  className?: string;
}

/**
 * Hook that triggers a callback when a click occurs outside of the passed ref
 */
export const useClickOutsideHook = (
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  ref: React.MutableRefObject<any>,
  action?: () => void,
  enabled = true
): void => {
  // Run callback if clicked outside of element
  const handleMouseEvent = (event: MouseEvent): void => {
    if (enabled && action && ref.current && !ref.current.contains(event.target)) {
      action?.();
    }
  };

  // Run callback if escape key is pressed
  const handleKeyEvent = (event: KeyboardEvent): void => {
    if (enabled && action && event.keyCode === 27) {
      action?.();
    }
  };

  // The action handler is passed in as a new function on every render, causing the effect to cleanup on every render.
  // Optimise by wrapping action handler in useCallback
  useEffect(() => {
    document.addEventListener("mousedown", handleMouseEvent);
    document.addEventListener("keydown", handleKeyEvent);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleMouseEvent);
      document.removeEventListener("keydown", handleKeyEvent);
    };
  }, [ref, action]);
};

/**
 * Component runs callback
 */
export default function ClickOutsideHook(props: Props): JSX.Element {
  const wrapperRef = React.useRef(null);
  useClickOutsideHook(wrapperRef, props.onClose);

  return (
    <div ref={wrapperRef} className={classnames(props.className)}>
      {props.children}
    </div>
  );
}
