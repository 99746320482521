/* eslint-disable */

import React from "react";
import { throttle } from "lodash";
import { isBrowser } from "@travellocal/utils";
import { ResponsiveBreakpoints } from "../../utils/uiHelpers";
import { NavMenu, NavMenuItem } from "./NavMenu";

interface DomItemStartingPositions {
  id: string;
  topBoundary: number;
}

interface Props {
  items: NavMenuItem[];
  label?: string;

  onItemClick?: (id: string) => void;
  onSelectChange?: (id: string) => void;
}

interface State {
  activeItem: string;
}

/**
 * Wrapper around `<NavMenu />` that adds scroll to element functionality
 *
 * @status stable
 */
export class NavMenuWithPageScroll extends React.Component<Props, State> {
  private rootRef = React.createRef<HTMLDivElement>();

  constructor(props: Props) {
    super(props);

    this.state = {
      activeItem: props.items && props.items.length > 1 ? props.items[0].id : "",
    };
  }

  /*
   * When called, will iterate over the references for items passed, and add the active flag true to
   * the most relevant one
   */
  private determineActiveLink = (): void => {
    if (!isBrowser() || !this.rootRef.current) {
      return;
    }

    // takes into account different elements offsetting where a content boundary top should be determined from
    const headerBottom = 144; // 74px height + 70px buffer
    const navBottom = this.rootRef.current.getBoundingClientRect().bottom + 10; // 10px buffer
    const windowOffsetModifier =
      window.innerWidth < ResponsiveBreakpoints.tablet ? navBottom : headerBottom;
    const itemsWithtopBoundry = this.generateTopPositions(this.props.items);
    const activeItem = this.setActiveItem(itemsWithtopBoundry, windowOffsetModifier);

    this.setState({
      activeItem,
    });
  };

  // avoid overloading the browser unnecasarily
  private throttledDetermineActiveLink = throttle(this.determineActiveLink, 300);

  private generateTopPositions = (items: NavMenuItem[]): DomItemStartingPositions[] => {
    const domItemStartingPositions = items.map((item: NavMenuItem) => {
      const domItem: Element | null = document.querySelector(`[data-scroll-id="${item.id}"]`);
      if (domItem) {
        return {
          id: item.id,
          topBoundary:
            domItem.getBoundingClientRect().top - document.body.getBoundingClientRect().top,
        };
      }
    }) as DomItemStartingPositions[];

    /*
     * Even if items are passed in an order different to that in which the items appear in the DOM
     * Ensure they get ordered in their scrolling order
     */
    return domItemStartingPositions.sort(
      (a: DomItemStartingPositions, b: DomItemStartingPositions) => {
        return a.topBoundary - b.topBoundary;
      }
    );
  };

  private setActiveItem = (domItems: DomItemStartingPositions[], customOffset: number): any => {
    // increased offset to account for nav header when determining active state application
    const currentScrollPosition = window.pageYOffset + customOffset;
    let idToSetActive = "";

    if (domItems && domItems.length === 0) {
      return "";
    }

    for (const content of domItems) {
      // stops setting id once its reached the closest boundary past the scroll height
      if (content != null && currentScrollPosition >= content.topBoundary) {
        idToSetActive = content.id;
      }
    }

    return idToSetActive;
  };

  public componentDidMount() {
    window.addEventListener("scroll", this.throttledDetermineActiveLink);
    // initialise the starting active link, irrespective of where the user starts on the page
    this.determineActiveLink();
  }

  public componentWillUnmount() {
    window.removeEventListener("scroll", this.throttledDetermineActiveLink);
  }

  public render() {
    const { activeItem } = this.state;
    const { label, onSelectChange, onItemClick, items } = this.props;
    return (
      <div className="page-scroll-nav" ref={this.rootRef}>
        <NavMenu
          label={label}
          selectOnMobile={true}
          onItemClick={onItemClick}
          onSelectChange={onSelectChange}
          activeItem={activeItem}
          items={items}
        />
      </div>
    );
  }
}
