import { site1Api } from "@travellocal/utils";
import { ContentState } from "./ContentState";

type Action = {
  type: "load_content";
  content: site1Api.Content[];
  key: string;
  options?: Partial<{ replace: boolean }>;
};

/** @deprecated Site 1 will be closed down soon */
export const ContentStateReducer = (
  state: ContentState,
  action: Action
): ContentState => {
  if (!state) {
    state = {};
  }

  switch (action.type) {
    case "load_content": {
      if (action?.content) {
        const newContent = action.options?.replace
          ? action.content
          : [...(state[action.key] ?? []), ...action.content];

        return {
          ...state,
          [action.key]: newContent,
        };
      }
    }
  }

  return state;
};
