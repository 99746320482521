import classNames from "classnames";
import React from "react";
import { Star } from "./Star";

import "./StarRating.scss";

export interface IStarRating {
  maximumValue: number;
  currentValue: number;

  /**
   * Should be less than or equal to `maximumValue`.
   */
  numberOfStars?: number;
  className?: string;
  title?: string;
  onClick?: () => void;
  "aria-hidden"?: boolean;
}

/**
 * Converts a numeric value into star icons.
 *
 * Can show half stars with `.5`.
 *
 * @status stable
 */
export const StarRating: React.FC<IStarRating> = ({
  className,
  numberOfStars = 5,
  currentValue,
  maximumValue,
  ...rest
}) => {
  const increment = maximumValue / numberOfStars;
  const rating = currentValue / increment;

  const stars: React.ReactNode[] = [];
  for (let i = 0; i < numberOfStars; i++) {
    if (i >= rating) {
      stars.push(<Star key={i} type="empty" />);
    } else if (rating - i < 1 && rating - i > 0) {
      stars.push(<Star key={i} type="half" />);
    } else {
      stars.push(<Star key={i} type="full" />);
    }
  }

  return (
    <div {...rest} className={classNames("star-rating", className)}>
      {stars}
    </div>
  );
};

StarRating.defaultProps = {
  numberOfStars: 5,
};

StarRating.displayName = "StarRating";
