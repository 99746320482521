/* eslint-disable */

import React, { useId } from "react";
import ReactSelect, { Props } from "react-select";
import classNames from "classnames";

import "./FeaturedSelect.scss";
import { useFormContext } from "react-hook-form";

interface FeaturedSelectCustomProps {
  className?: string;
  isSearchable?: boolean;
  name: string;
  onChange?: (value: any) => void;
  defaultValue?: Props["defaultValue"];
  placeholderVariant?: "silver" | "elephant";
  isClearable?: boolean;
  placeholder?: string;
}

export type FeaturedSelectProps<T> = FeaturedSelectCustomProps & Props<T, false>;

export function FeaturedSelect<T>({
  className,
  isSearchable = false,
  name,
  defaultValue,
  onChange,
  placeholderVariant = "silver",
  placeholder,
  isClearable = false,
  ...props
}: FeaturedSelectProps<T>) {
  const context = useFormContext();
  const value = !!context ? context.watch(name, defaultValue) : defaultValue;

  // https://github.com/JedWatson/react-select/issues/2629#issuecomment-1298828364
  const instanceId = useId();

  return (
    <>
      {!!context && <input type="hidden" value={value} {...context?.register(name)} />}
      <ReactSelect
        className={classNames("featured-select-container", className, {
          "featured-select-container--has-value": value,
          "featured-select-container--elephant-placeholder": placeholderVariant === "elephant",
        })}
        classNamePrefix="featured-select"
        isSearchable={isSearchable}
        isClearable={isClearable}
        placeholder={placeholder}
        instanceId={instanceId}
        value={value}
        {...props}
        onChange={(newValue, meta) => {
          onChange?.(newValue, meta);
          context?.setValue(name, newValue);
        }}
      />
    </>
  );
}
