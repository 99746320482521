import React from "react";
import { useTranslation } from "react-i18next";
import {
  HeroSection,
  Breadcrumbs,
  HtmlRenderBlock,
  LocationCard,
  GoogleAnalyticsHelper,
  SocialButtons,
  Loader,
  LocationCardProps,
  Link,
} from "../..";
import { parseISO } from "date-fns";
import "./Article.scss";
import classNames from "classnames";
import { SubscribeWrapper } from "../../organisms";
import { Locale } from "@travellocal/utils";

interface Props {
  /**
   * Fully qualified URL including domain, e.g. https://www.travellocal.com/inspiration/blog/222/good-times
   */
  fullUrl: string;
}

interface LoadingProps extends Props {
  isLoading: true;
}

interface LoadedProps extends Props {
  isLoading: false;
  title: string;
  heroImage: {
    src: string;
    alt?: string;
  };
  countries?: Omit<LocationCardProps, "size">[];
  tags?: { name: string; slug: string }[];
  author: string;
  publishedDate: string;
  content: string;
  meta?: {
    titleTag?: string;
    descriptionTag?: string;
  };
  isOld: boolean;
  subscribeBodyText?: string;
  subscribeText?: string;
  subscribeSignupSource?: string;
  locale?: Locale;
}

export const Article = (props: LoadingProps | LoadedProps) => {
  const { t } = useTranslation("ui");

  return (
    <div
      className={classNames("tl-article", {
        "tl-article__is-old": !props.isLoading && props.isOld,
      })}>
      <HeroSection
        imageSrc={
          (!props.isLoading && props.heroImage.src) ||
          "https://resources.travellocal.com/images/missing-image.jpg"
        }
        imageMeta={(!props.isLoading && props.heroImage.alt) || ""}
        className="tl-article__hero"
      />

      <div className="container">
        <div className="columns is-marginless sidebarDirection">
          <div className="column is-12 is-8-desktop tl-article__main">
            {props.isLoading && <Loader className="my-6" />}

            {!props.isLoading && (
              <>
                <div className="tl-article__header mb-4">
                  <Breadcrumbs
                    className="tl-article__breadcrumbs"
                    items={[
                      { label: "Home", href: t("routes:index", "/") },
                      {
                        label: t("ui:InspirationDropdown_articlesLink", "Articles"),
                        href: t("routes:articles", "/articles"),
                      },
                      { label: props.title },
                    ]}
                  />

                  <h1 className="title is-size-2 is-size-1-tablet my-3">{props.title}</h1>
                  <span className="tl-article__author mb-2">
                    {t("Article_byAuthor", { author: props.author })}
                  </span>
                  {props.publishedDate && (
                    <>
                      {" | "}
                      <span className="tl-article__date mb-2">
                        <time dateTime={props.publishedDate}>
                          {t("common:intlDateTime", {
                            val: parseISO(props.publishedDate),
                            formatParams: {
                              // NB. doesn't include ordinal notation
                              // https://tinycode2.medium.com/js-and-ecmascript-date-formatting-with-ordinal-indicators-a271a23a866c
                              val: { dateStyle: "long" },
                            },
                          })}
                        </time>
                      </span>
                    </>
                  )}
                </div>

                <hr />

                <HtmlRenderBlock className="alloy-html" htmlString={props.content} />
              </>
            )}
          </div>

          <div className={"tl-article__sidebar column is-12 is-4-desktop"}>
            <div className="tl-article__featured mb-6">
              {!props.isLoading && props.countries?.length && (
                <>
                  <h3 className="is-size-3 title mb-2">
                    {t("Article_sidebarFeaturedDestinations", "Featured destinations")}
                  </h3>
                  <div className="tl-article__countries">
                    {props.countries.map((props) => (
                      <LocationCard
                        {...props}
                        className="mb-3"
                        key={props.title}
                        size="small"
                        onClick={() =>
                          GoogleAnalyticsHelper.logEvent(
                            "Select Country",
                            "Click",
                            "Blog post card"
                          )
                        }
                      />
                    ))}
                  </div>
                </>
              )}
            </div>

            {!props.isLoading && !!props.tags?.length && (
              <div className="tl-article__tagged mb-6">
                <h3 className="is-size-3 title mb-2">{t("Article_sidebarTags", "Tagged with")}</h3>

                <div className="tl-article__countries tags">
                  {props.tags.map((tag) => (
                    <Link
                      key={tag.slug}
                      href={t("routes:articles", "/articles") + `?tags=${tag.slug}`}
                      className="tag is-primary">
                      {tag.name}
                    </Link>
                  ))}
                </div>
              </div>
            )}

            {!props.isLoading && (
              <div className="tl-article__social mb-6">
                <h3 className="is-size-3 title mb-2">
                  {t("Article_sidebarShare", "Share this article")}
                </h3>
                <SocialButtons
                  pageLink={props.fullUrl}
                  shortMessage={props.meta?.titleTag ?? props.title}
                  longMessage={props.meta?.descriptionTag ?? props.title}
                  enableCopying={true}
                />
                <SubscribeWrapper
                  subscribeClassName="mt-7 mb-7"
                  subscribeContentClassName="subscribeContent"
                  subscribeColumnClassName="subscribeColumn"
                  subscribeFormClassName="p-5 pb-6"
                  title={props.subscribeText}
                  bodyText={props.subscribeBodyText}
                  locale={props.locale}
                  signupSource={props.subscribeSignupSource}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
