import React from "react";
import Image from "next/legacy/image";
import classNames from "classnames";
import { CallToAction, WPImage, HtmlRenderBlock, Button } from "../..";
import imageLoader from "../../utils/imageLoader";
import "./Billboard.scss";

interface Props {
  backgroundImage: WPImage;
  callToAction?: CallToAction;
  content: string;
  heading?: string;
  imageSide?: "left" | "right";
  style?: "light" | "sage";
}

/**
 * A large block highlighting some messaging, usually with a clear singular call to action.
 *
 * @status stable
 */

export const Billboard: React.FC<Props> = ({
  backgroundImage,
  callToAction,
  content,
  heading,
  style = "",
  imageSide = "left",
}) => {
  const cssVars = {
    ["--billboard-style"]: `var(${
      style === "light" ? "--colour-white" : "--colour-sage"
    })`,
  } as React.CSSProperties;

  return (
    <div
      className={classNames("billboard columns is-marginless", {
        "is-flex-direction-row-reverse": imageSide === "right",
      })}
      style={cssVars}
    >
      <div className="column is-half billboard__image is-relative is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
        {backgroundImage?.sourceUrl && (
          <Image
            src={backgroundImage.sourceUrl}
            alt={backgroundImage.altText}
            layout="fill"
            objectFit="cover"
            loader={imageLoader}
          />
        )}
        {heading && (
          <h2
            className="title has-text-centered has-text-light is-size-3-touch m-4 is-size-2-desktop billboard__heading"
          >
            {heading}
          </h2>
        )}
      </div>
      <div
        className="column is-half billboard__text is-flex is-flex-direction-column is-justify-content-center is-align-items-left"
      >
        <HtmlRenderBlock className="is-size-5 mb-5" htmlString={content} />

        {callToAction && (
          <div className="is-pulled-left">
            <Button
              href={callToAction.url}
              isGhost
              target={callToAction.target}
            >
              {callToAction.title}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

Billboard.defaultProps = {};

Billboard.displayName = "Billboard";
