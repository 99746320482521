import React from "react";
import classNames from "classnames";
import "./SectionHeading.scss";

interface Props {
  sectionHeading: string;
  subHeading?: string;
  variant?: "default" | "inline";
  children?: React.ReactNode;
  hideRuler?: boolean;
  sectionClassname?: string;
  subheadingClassname?: string;
  useH1?: boolean;
}

export const SectionHeading: React.FC<Props> = ({
  sectionHeading,
  subHeading,
  children,
  variant = "default",
  hideRuler = false,
  sectionClassname,
  subheadingClassname,
  useH1,
}: Props) => {
  return (
    <div
      className={classNames("section-heading is-flex", {
        "is-flex-direction-column is-align-items-center": variant === "default",
        "is-flex-direction-row": variant === "inline",
      })}>
      <div
        className={classNames("is-flex", {
          "is-justify-content-start is-flex-direction-row-reverse is-align-items-baseline":
            variant === "inline",
          "is-flex-direction-column is-align-items-center": variant === "default",
        })}>
        {subHeading && (
          <span
            className={classNames(
              "subtitle is-uppercase is-size-6 has-text-weight-semibold is-family-primary is-letter-spacing-1 is-block",
              subheadingClassname,
              { "mb-5": variant === "default" },
              { "is-spaced ml-6 mb-1": variant === "inline" }
            )}>
            {subHeading}
          </span>
        )}
        {useH1 ? (
          <h1
            className={classNames("title has-text-weight-light is-2", sectionClassname, {
              heading: variant === "default",
            })}>
            {sectionHeading}
          </h1>
        ) : (
          <h2
            className={classNames("title has-text-weight-light is-2", sectionClassname, {
              heading: variant === "default",
            })}>
            {sectionHeading}
          </h2>
        )}
      </div>
      {children}
      <hr
        className={classNames({
          "mt-6 mb-3": variant === "default",
          "is-hidden": hideRuler || variant === "inline",
        })}
      />
    </div>
  );
};

SectionHeading.displayName = "SectionHeading";
