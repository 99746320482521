import React from "react";
import classNames from "classnames";
import { HtmlRenderBlock } from "../..";
import "./CopyWithPullout.scss";

interface Props {
  mainContent: string;
  pulloutContent: string;
  whichSideShouldTheQuoteBeOn: "left" | "right";
}

export const CopyWithPullout: React.FC<Props> = ({
  mainContent,
  pulloutContent,
  whichSideShouldTheQuoteBeOn,
}) => {
  return (
    <div
      className={classNames("copy-with-pullout columns is-marginless", {
        "is-flex-direction-row-reverse":
          whichSideShouldTheQuoteBeOn === "right",
      })}
    >
      <div
        className={classNames({
          "column is-1": whichSideShouldTheQuoteBeOn === "right",
        })}
      ></div>
      <div
        className={classNames("column is-4", {
          "is-offset-1": whichSideShouldTheQuoteBeOn === "left",
        })}
      >
        <h2
          className={classNames("title is-size-3", {
            "mr-4": whichSideShouldTheQuoteBeOn === "left",
            "ml-6": whichSideShouldTheQuoteBeOn === "right",
          })}
        >
          {pulloutContent}
        </h2>
      </div>
      <div className="column is-5">
        <HtmlRenderBlock htmlString={mainContent} />
      </div>
    </div>
  );
};

CopyWithPullout.defaultProps = {};

CopyWithPullout.displayName = "CopyWithPullout";
