import classNames from "classnames";
import React from "react";
import "./AccountWidget.scss";

interface Props {
  title: string;
  icon?: React.ReactNode;
  theme?: "with-shadow" | "with-bg" | "with-border" | "ghost";
  className?: string;
  children?: React.ReactNode;
}

/**
 * Wrapper to display post-request components under the intended widget guidelines.
 */

export const AccountWidget: React.FC<Props> = ({
  title,
  icon,
  theme = "with-shadow",
  className,
  children,
}: Props) => {
  return (
    <section className={classNames("area-widget-wrapper", className)}>
      <div className={`area-widget ${theme}`}>
        <div className="area-widget__header">
          <h3 className="area-widget__title">{title}</h3>
          <div className="area-widget__icon">{icon}</div>
        </div>
        {children}
      </div>
    </section>
  );
};

AccountWidget.displayName = "AccountWidget";
