import { site1Api } from "@travellocal/utils";
import _ from "lodash";
import React, { createContext, useContext, useEffect, useReducer } from "react";
import { ContentState, ContentStateContext } from "./ContentState";
import { ContentStateReducer } from "./ContentStateReducer";

/** @deprecated Site 1 will be closed down soon */
export interface ContentStateProviderProps {
  initialState: ContentState;
  children: React.ReactNode;
}

const ReactContext = createContext<ContentStateContext | null>(null);

/** @deprecated Site 1 will be closed down soon */
export const useContentState = () => {
  const state = useContext(ReactContext);
  if (state == null) {
    throw new Error(
      "useContentState must be used within a ContentStateProvider"
    );
  }
  return state;
};

/** @deprecated Site 1 will be closed down soon */
export const ContentStateProvider: React.FC<ContentStateProviderProps> = (
  props
) => {
  const [state, dispatch] = useReducer(ContentStateReducer, props.initialState);

  const context: ContentStateContext = {
    contentState: state,
    loadContent: (key: string, content: site1Api.Content[], options) =>
      dispatch({
        type: "load_content",
        content,
        key,
        options,
      }),
  };

  // Make sure all data from initialState gets loaded.
  // When NextJS loads a static page for the first time, it will first render without props meaning this
  // ContentStateProvider gets initialised to {}. There will then be a second render once getStaticProps
  // has finished, so we need to make sure the initialState is loaded in.
  useEffect(() => {
    for (const [key, val] of _.toPairs(props.initialState)) {
      context.loadContent(key, val, { replace: true });
    }
  }, [props.initialState]);

  return (
    <ReactContext.Provider value={context}>
      {props.children}
    </ReactContext.Provider>
  );
};
