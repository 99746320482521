import React from "react";
import { useTranslation } from "react-i18next";
import "./SuccessMessage.scss";

export const SuccessMessage = () => {
  const { t } = useTranslation();
  const privacyPolicyUrl = t("routes:privacy", "/en/privacy");

  return (
    <div className="is-flex is-flex-direction-column subscribe-privacy-link">
      <h4 className="title is-size-4 mb-3 has-text-white">
        {t("Subscribe_success_title", "Great! Check your inbox to verify your email")}
      </h4>
      <p>
        {t(
          "ui:Subscribe_success_p1",
          "We’re excited to have you with us! All you need to do now is confirm your subscription - it might take a minute to come through to your inbox."
        )}
      </p>
      <p
        dangerouslySetInnerHTML={{
          __html: t(
            "ui:Subscribe_success_p2",
            `You can unsubscribe at any time - read our <a href="{{privacyPolicyUrl}}">privacy policy</a> for more details.`,
            {
              privacyPolicyUrl,
            }
          ),
        }}></p>
    </div>
  );
};
