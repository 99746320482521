import React, { useEffect, createContext, useContext, useState } from "react";
import { debounce } from "lodash";
import { ResponsiveBreakpoints } from ".";

type Dimensions =
  | {
      width: number;
      height: number;
      isMobile: boolean;
    }
  | {
      width: null;
      height: null;
      isMobile: true;
    };

const WindowDimensionsContext = createContext({} as Dimensions);

interface Props {
  children: React.ReactChild;
}

/**
 * Helper to provide up-to-date window dimensions via React's context API.
 * Call useWindowDimensions inside render().
 */
export const WindowDimensionsProvider = ({ children }: Props) => {
  const [dimensions, setDimensions] = useState<Dimensions>({
    width: null,
    height: null,
    isMobile: true,
  });

  const syncDimensions = () =>
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
      isMobile: window.innerWidth < ResponsiveBreakpoints.tablet,
    });

  useEffect(() => {
    // Trigger a refresh after the server-rendered HTML has rendered once on the browser
    syncDimensions();

    const resizeDelayMs = 100; // Relatively small so as to be less noticeable
    const handleResize = debounce(syncDimensions, resizeDelayMs, { trailing: true });

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <WindowDimensionsContext.Provider value={dimensions}>
      {children}
    </WindowDimensionsContext.Provider>
  );
};

/**
 * Get up-to-date window dimensions. width and height will be undefined in a server render.
 */
export const useWindowDimensions = () => useContext(WindowDimensionsContext);

export const useIsMobile = () => {
  const { width } = useWindowDimensions();
  return width != null ? width < ResponsiveBreakpoints.tablet - 1 : true;
};
