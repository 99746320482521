/* eslint-disable react/no-children-prop */
import React from "react";
import { BulletList, IBulletList } from "../../molecules";
import { Button, Icomoon, WPImage } from "../..";
import classNames from "classnames";

import "./PropositionPullout.scss";

interface IPropositionPullout {
  heading: string;
  paragraph: string;
  largeImage: Pick<WPImage, "sourceUrl" | "altText">;
  topImages?: Pick<WPImage, "sourceUrl" | "altText">[];
  bottomImages?: Pick<WPImage, "sourceUrl" | "altText">[];
  flipLayout?: boolean;
  buttonURL?: string;
  buttonCTA?: string;
}

interface Props extends IPropositionPullout, IBulletList {}

export const PropositionPullout: React.FC<Props> = (props) => {
  return (
    <div className={classNames("proposition-pullout columns", props.flipLayout && "flip-layout")}>
      <div className="proposition-pullout__article column is-6 is-12-mobile">
        {props.topImages && (
          <div className="proposition-pullout__top-images">
            {props.topImages.map((image, i) => (
              <img key={i} src={image.sourceUrl} alt={image.altText} />
            ))}
          </div>
        )}
        <h2 className="title is-size-2 mb-3 is-size-1-widescreen">{props.heading}</h2>
        <p className="is-size-5">{props.paragraph}</p>
        <BulletList size="large" {...(props as IBulletList)} />
        {props.bottomImages && (
          <div className="proposition-pullout__bottom-images">
            {props.bottomImages.map((image, i) => (
              <img key={i} src={image.sourceUrl} alt={image.altText} />
            ))}
          </div>
        )}
        {props.buttonURL && props.buttonCTA && (
          <Button
            className="proposition-pullout__button"
            isGhost={true}
            children={props.buttonCTA}
            href={props.buttonURL}
            icomoonIcon={Icomoon.arrowRight3}
            iconSide="right"
          />
        )}
      </div>
      <div className="proposition-pullout__large-image column is-6 is-12-mobile">
        <img src={props.largeImage.sourceUrl} alt={props.largeImage.altText} />
      </div>
    </div>
  );
};
