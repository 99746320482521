import { getQueryStringValue, isBrowser, LocalStorageHelper } from "@travellocal/utils";
import React from "react";

/**
 * Typing for GA events, based on observation
 */
interface DataLayerEvent {
  [key: string]: string | number | undefined;
  event: string;
  "gtm.start"?: number;
  "gtm.uniqueEventId"?: number;
}

declare global {
  interface Window {
    /**
     * Used by Google Analytics and Tag Manager
     */
    dataLayer: DataLayerEvent[];
  }
}

const getOrCreateDataLayer = () => {
  if (!window.dataLayer) {
    window.dataLayer = [];
  }

  return window.dataLayer;
};

/**
 * Send an arbitrary bit of data to the dataLayer object used by GTM
 */
export const sendToDataLayer = (dataLayerEvent: DataLayerEvent) => {
  const dataLayer = getOrCreateDataLayer();
  dataLayer.push(dataLayerEvent);
};

/**
 * Test whether Analytics has been loaded onto the page (may have been blocked by an ad blocker).
 * Useful for providing a fallback if needed when A/B testing with Google Optimize
 */
const isGoogleAnalyticsActive = (): boolean => {
  // WARNING: Works On My Machine™
  // If the GA snippet is not loaded onto the page, it seems that events sent to the dataLayer are ignored
  // If ad blocking is enabled (via e.g. uBlock), there's only one event in window.dataLayer.
  // If ads are allowed, there are a lot (see appRoot.tsx for where they're coming from).
  const dataLayer = getOrCreateDataLayer();
  return dataLayer && dataLayer.length > 1;
};

/**
 * Sets up the page to work with Optimize.
 * The .no-ga class is added to the <body> element if no experiments are running, or if Optimize hasn't loaded.
 * This function should be called after the render of the React bundle.
 */
export const setupGoogleOptimize = () => {
  // HOW TO TEST WITH GOOGLE OPTIMIZE
  //
  // These instructions are for tests which make significant changes to the page that would be noticeable.
  // Tests which involve minor changes don't need to follow these rules.
  //
  // 1. The className that is added to the <body> tag via Optimize must contain the string "optimize".
  //    This is so this function can tell whether any experiments are running.
  // 2. When writing the CSS for each variant, use the pattern `body:not(.optimize-expt-1) .element { display: none; }`.
  //    This CSS is less likely to conflict with any of the CSS you've written for the experiment.
  // 3. Fallback CSS is controlled with the .no-ga class. For the CSS you want to show if Optimize fails to load (or for the original variant),
  //    write something like: `body:not(.optimize-expt-2):not(.no-ga) .element { display: none }`

  const setupFallbacks = () => {
    if (document.body.className.indexOf("no-ga") < 0) {
      document.body.className = document.body.className + " no-ga";
      console.debug("Optimize fallback applied");
    }
  };

  const delayOverride = getQueryStringValue("optimize-delay");
  const fallbackDelay = delayOverride ? parseInt(delayOverride) : 500;
  console.debug(`Optimize delay: ${fallbackDelay}`);
  setTimeout(() => {
    const gaActive = isGoogleAnalyticsActive();
    const gaFailed = document.body.className.indexOf("optimize") < 0;

    // Optimize should have loaded within this timeframe
    if (!gaActive || gaFailed) {
      setupFallbacks();
    }
  }, fallbackDelay);
};

/**
 * Hook to reapply Optimize changes when React rerenders
 */
export const useGoogleOptimise = () => {
  const supportsOptimise = isBrowser() && "MutationObserver" in window;

  React.useEffect(() => {
    if (!supportsOptimise) {
      return;
    }

    const host = document.getElementById("host");
    if (!host) {
      return;
    }

    const observer = new MutationObserver(() => {
      if (isGoogleAnalyticsActive()) {
        sendToDataLayer({ event: "optimize.activate" });
      }
    });

    // Observe changes on all elements
    observer.observe(host, {
      attributes: true,
      childList: true,
      characterData: true,
      subtree: true,
    });

    return () => observer.disconnect();
  });
};

/**
 * Google Tag Manager scripts, shared between main web app and Next.js
 */

export const gtmHeadScript = () =>
  `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${process.env.TRAVELLOCAL_GOOGLE_TAG_MANAGER_HEAD_ID}');`;

export const gtmBodyScript = () =>
  `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${process.env.TRAVELLOCAL_GOOGLE_TAG_MANAGER_ID}');`;

export const GtmNoScript = () => (
  <noscript>
    <iframe
      src={`https://www.googletagmanager.com/ns.html?id=${process.env.TRAVELLOCAL_GOOGLE_TAG_MANAGER_ID}`}
      height="0"
      width="0"
      style={{ display: "none", visibility: "hidden" }}></iframe>
  </noscript>
);

/**
 * Helper Class
 */

export class GoogleAnalyticsHelper {
  private static trackerName: string;
  private static trackerReady(): boolean {
    if (typeof ga === "function" && ga.getAll) {
      if (ga.getAll()[0]) {
        GoogleAnalyticsHelper.trackerName = ga.getAll()[0].get("name") as string;
        if (GoogleAnalyticsHelper.trackerName) {
          const userId = LocalStorageHelper.getUserId();
          if (userId) {
            ga(`${GoogleAnalyticsHelper.trackerName}.set`, "userId", userId);
          }
          return true;
        }
      }
    }
    return false;
  }

  public static logPageView(path: string) {
    if (GoogleAnalyticsHelper.trackerReady()) {
      ga(`${GoogleAnalyticsHelper.trackerName}.set`, "page", path);
      ga(`${GoogleAnalyticsHelper.trackerName}.send`, "pageview");
    }
  }

  public static logEvent(
    category: string,
    action: string,
    label?: string,
    metricName?: string,
    data?: unknown
  ) {
    if (GoogleAnalyticsHelper.trackerReady()) {
      const defaultEvent = {
        hitType: "event",
        eventCategory: category,
        eventAction: action,
        eventLabel: label,
      };

      // For Custom dimensions & metrics docs, see:
      // https://support.google.com/analytics/answer/2709828
      if (metricName && data) {
        // Set metric and dimension data first
        if (metricName === "Enquiry") {
          ga(`${GoogleAnalyticsHelper.trackerName}.set`, "metric1", 1);
          ga(`${GoogleAnalyticsHelper.trackerName}.set`, "dimension1", data);
        }

        if (metricName === "Engaged enquiry") {
          ga(`${GoogleAnalyticsHelper.trackerName}.set`, "metric1", 1);
          ga(`${GoogleAnalyticsHelper.trackerName}.set`, "dimension1", data);
          ga(`${GoogleAnalyticsHelper.trackerName}.set`, "metric2", 2);
          ga(`${GoogleAnalyticsHelper.trackerName}.set`, "dimension5", data);
        }

        // Then send the event
        ga(`${GoogleAnalyticsHelper.trackerName}.send`, defaultEvent);
      } else {
        ga(`${GoogleAnalyticsHelper.trackerName}.send`, {
          ...defaultEvent,
          dimension1: JSON.stringify(data),
        });
      }
    }
  }

  // See: https://github.com/GoogleChrome/web-vitals#send-the-results-to-google-analytics
  public static logWebVital({ name, value, id }: { name: string; value: number; id: string }) {
    sendToDataLayer({
      event: "Data Layer",
      category: "Web Vitals",
      action: name,
      label: id,
      value: Math.round(name === "CLS" ? value * 1000 : value),
      transport: "beacon",
    });
  }
}
