import React from "react";
import { useAppState } from "../..";
import { HeaderComponent, HeaderComponentProps } from "./HeaderComponent";

export type HeaderProps = Omit<HeaderComponentProps, "user">;

export const Header: React.FC<HeaderProps> = (props) => {
  const { appState } = useAppState();

  return <HeaderComponent {...props} user={appState?.user ?? null} />;
};
