// Helper methods for building nice UI

import tinycolor from "tinycolor2";
import { PageScrollPositionHelper } from "./pageScrollPositionHelper";

// TODO I moved this file to @travellocal/ui but didn't want to move over the SCSS imports yet.
// When @travellocal/ui has a bundling step, restore the import:
// import * as ThemeVars from "../../theme/exports.scss";
export const ResponsiveBreakpoints = {
  tablet: 768,
  desktop: 1024,
  widescreen: 1216,
  fullhd: 1408,
};

/**
 * Translate a client rect to new coordinates
 */
export const translate = (
  either: DOMRect | Pick<DOMRect, "width" | "height">,
  x: number,
  y: number
): DOMRect => {
  const rect = either as DOMRect;
  return {
    left: (rect.left || 0) + x,
    right: (rect.right || rect.width) + x,
    top: (rect.top || 0) + y,
    bottom: (rect.bottom || rect.height) + y,
    width: rect.width,
    height: rect.height,
  } as DOMRect;
};

/**
 * Test if a point is inside a DOMRect
 */
export const isPointInside = (x: number, y: number, area: DOMRect) =>
  x >= area.left && x <= area.right && y >= area.top && y <= area.bottom;

/**
 * Test whether the mouse event occurred inside the bounds of a page element
 * @param e MouseEvent from React e.g. onClick
 * @param rect DOMRect sourced from document.getElementById() or alternative
 */
export const isClickInside = (e: MouseEvent | { pageX: number; pageY: number }, rect: DOMRect) => {
  const { pageX, pageY } = e;

  return isPointInside(pageX, pageY, translate(rect, window.pageXOffset, window.pageYOffset));
};

/**
 * Helper to get a DOMRect for window. element.getBoundingDOMRect returns coordinates relative
 * to the window, so this is useful for comparison.
 */
export const getWindowDOMRect = (): DOMRect => {
  const { innerHeight, innerWidth } = window;

  return {
    height: innerHeight,
    width: innerWidth,
    left: 0,
    top: 0,
    right: innerWidth,
    bottom: innerHeight,
  } as DOMRect;
};

/**
 * Test if there is any overlap between the two provided areas
 */
export const intersects = (r1: DOMRect, r2: DOMRect): boolean => {
  return (
    isPointInside(r1.left, r1.top, r2) ||
    isPointInside(r1.right, r1.top, r2) ||
    isPointInside(r1.left, r1.bottom, r2) ||
    isPointInside(r1.right, r1.bottom, r2)
  );
};

/**
 * Returns the closest scrolling element up the DOM tree, or undefined if there are no scrolling parents.
 * @param element the element to start from
 */
export const getScrollingParent = (element: HTMLElement): HTMLElement | undefined => {
  if (element == null || element === document.body) {
    return undefined;
  }

  const testElement = element.parentElement;
  if (testElement == null || testElement === document.body) {
    return undefined;
  }

  const { overflow, overflowX, overflowY } = window.getComputedStyle(testElement);
  const isScrollable = /auto|scroll/.test(overflow + overflowX + overflowY);

  return isScrollable ? testElement : getScrollingParent(testElement);
};

/**
 * Produce a valid #hex for CSS, or undefined.
 */
export const parseColourString = (colourString?: string | null) => {
  if (!colourString) {
    return undefined;
  }

  const colour = tinycolor(colourString);
  return colour.isValid() ? colour.toHexString() : undefined;
};

/**
 * Prevent scrolling on the page. Use in conjunction with unlockPageScroll.
 * N.B. this applied position: fixed to the html element, which causes a scroll to the
 * top of the page. This is therefore best used with a fullscreen overlay, where the page
 * is not visible, otherwise there'll be an obvious jump.
 * This technique is incompatible with hiding the address bar on iOS.
 */
export const lockPageScroll = () => {
  PageScrollPositionHelper.storeScrollPosition();
  document.documentElement.classList.add("scroll-lock"); // See /theme/bundle/scroll-lock.scss
};

/**
 * Restore scrolling behaviour to the page, and restore the original scroll position.
 */
export const unlockPageScroll = () => {
  if (document.documentElement.classList.contains("scroll-lock")) {
    document.documentElement.classList.remove("scroll-lock");
    PageScrollPositionHelper.restoreScrollPosition();
  }
};

/**
 * Function that attempts to ensure that the provided rect will be placed within the provided bounds
 * @param rect the rect to keep within bounds
 * @param bounds the bounds for the rect
 * @returns the new origin for rect
 */
export const keepRectWithinBounds = (
  rect: DOMRect,
  bounds: DOMRect
): Pick<DOMRect, "top" | "left"> => {
  let [xShift, yShift] = [0, 0];

  if (rect.left < bounds.left) {
    xShift = Math.abs(bounds.left - rect.left);
  } else if (rect.right > bounds.right) {
    xShift = -Math.abs(rect.right - bounds.right);
  }

  if (rect.top < bounds.top) {
    yShift = Math.abs(bounds.top - rect.top);
  } else if (rect.bottom > bounds.bottom) {
    yShift = -Math.abs(rect.bottom - bounds.bottom);
  }

  return {
    left: rect.left + xShift,
    top: rect.top + yShift,
  };
};
