import classNames from "classnames";
import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useLayoutEffect,
} from "react";
import { debounce } from "lodash";
import { DropdownHover } from "./DropdownHover";
import { DropdownBodyProps } from "./DropdownBody";
import "./Dropdown.scss";
import { DropdownClick } from "./DropdownClick";
import { usePageScrollPosition } from "../../utils";

export interface DropdownProps {
  /**
   * Component to use for the Body element.
   */
  Body: React.FC<DropdownBodyProps>;

  /**
   * Has shade?
   */
  hasShade?: boolean;

  className?: string;

  Trigger: React.FC<{ children: React.ReactNode }>;

  /**
   * Whether the dropdown should open on mouse hover / keyboard focus, or mouse click only.
   */
  openOn: "hover" | "click";

  /**
   * Whether the dropdown body should be aligned relative to the screen edges or the trigger element.
   */
  bodyAlign?: "screen" | "trigger";

  /**
   * Which direction the dropdown should open.
   */
  direction?: "down-right" | "down-left";
}

/**
 * Open a dropdown menu by hovering over another component. You can provide your own components for both the trigger and body elements.
 *
 * > Your `Trigger` component should render `children`. You should use the `DropdownBody` component as a wrapper for the body.
 *
 * e.g.
 *
 * ```tsx
 * <Dropdown
 *   openOn="click"
 *   Trigger={({ children }) => (
 *     <div>
 *       <LinkButton to="http://travellocal.com" buttonClass="secondary-button" className="heading is-marginless">
 *         Hover
 *       </LinkButton>
 *       {children}
 *     </div>
 *   )}
 *   Body={(props: DropdownBodyProps) => (
 *     <DropdownBody {...props}>
 *       Hello!
 *     </DropdownBody>
 *   )}
 * />
 * ```
 *
 * @status stable
 */
export const Dropdown: React.FC<DropdownProps> = ({
  className,
  Body,
  hasShade = true,
  Trigger,
  openOn,
  bodyAlign = "trigger",
  direction = "down-right",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const pageScroll = usePageScrollPosition();
  const triggerRef = useRef<HTMLDivElement>(null);

  // Get the midpoint and bottom edge of the trigger button, so we can position the arrow for the dropdown.
  const [triggerxMid, setTriggerxMid] = useState<string>();
  const [bodyTopOffset, setBodyTopOffset] = useState<string>();
  const syncDropdownPosition = useCallback(() => {
    if (triggerRef.current) {
      const triggerRect = triggerRef.current.getBoundingClientRect();

      // The dropdown body's `top` and the arrow's offset need to be set differently
      // when aligned to screen or to the trigger
      if (bodyAlign === "screen") {
        setTriggerxMid(triggerRect.x + triggerRect.width / 2 + "px");
        setBodyTopOffset(triggerRect.bottom + "px");
      } else {
        setTriggerxMid(triggerRect.width / 2 + "px");
        setBodyTopOffset(undefined);
      }
    }
  }, [triggerRef.current]);

  useLayoutEffect(() => {
    syncDropdownPosition();

    // Trigger another resync shortly shortly after a sync to handle the case where a dropdown is
    // inside an animating element (like the Header). The delay should be a bit more than --speed CSS variable.
    setTimeout(syncDropdownPosition, 200);
  }, [triggerRef.current, bodyAlign, isOpen, isOpen && pageScroll.y]);

  const toggleDropdown = useCallback(
    debounce((val: boolean) => setIsOpen(val), 0, { trailing: true }),
    []
  );

  const openDropdown = () => {
    toggleDropdown.cancel();
    setIsOpen(true);
  };

  const closeDropdown = () => {
    toggleDropdown.cancel();
    toggleDropdown(false);
  };

  // Close dropdowns when URL changes
  // NB. Storybook causes this to fire one time on opening if there's already another dropdown open,
  // meaning there's weird behaviour when there are multiple dropdowns in the component (e.g. Header).
  useEffect(() => {
    closeDropdown();
  }, [typeof window !== "undefined" ? window.location.toString() : null]);

  if (Trigger == null || Body == null) {
    return null;
  }

  const content =
    openOn === "hover" ? (
      <DropdownHover
        className={classNames({
          "dropdown__hover--active": isOpen,
          "is-relative": bodyAlign != "screen",
        })}
        onFocus={openDropdown}
        onBlur={closeDropdown}
        ref={triggerRef}
      >
        <Trigger>
          <Body
            isVisible={isOpen}
            onFocus={openDropdown}
            onBlur={closeDropdown}
          />
        </Trigger>
      </DropdownHover>
    ) : (
      <DropdownClick
        className={classNames({
          "dropdown__hover--active": isOpen,
          "is-relative": bodyAlign != "screen",
        })}
        onClick={openDropdown}
        onClose={closeDropdown}
        isOpen={isOpen}
        ref={triggerRef}
      >
        <Trigger>
          <Body
            isVisible={isOpen}
            onFocus={openDropdown}
            onBlur={closeDropdown}
          />
        </Trigger>
      </DropdownClick>
    );

  return (
    <div
      className={classNames(
        "dropdown",
        className,
        `dropdown--dir-${direction}`,
        `dropdown--align-${bodyAlign}`
      )}
      style={
        {
          ["--trigger-x-mid"]: triggerxMid,
          ["--dd-body-top-offset"]: bodyTopOffset,
          ["--dd-body-align"]: bodyAlign === "screen" ? 0 : "auto",
        } as React.CSSProperties
      }
    >
      {content}
      {isOpen && hasShade && <div className="dropdown__shade" />}
    </div>
  );
};
