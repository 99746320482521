import React from "react";
import { Link } from "../../..";
import { useTranslation } from "react-i18next";

interface ReimagineTravelMenuButtonProps {
  dataTrackingId?: string;
}

export const ReimaginingTravelButton: React.FC<ReimagineTravelMenuButtonProps> = ({ dataTrackingId }) => {
  const { t } = useTranslation();

  // translation default strings in german since this should only be presented in DE locale
  return (
    <Link href={t("routes:Articles_travelDefined", "/magazin?tags=reisen-neu-definiert")} className="button button-comp is-header-link is-family-primary" data-tracking-id={dataTrackingId}>
      {t("ui:ReimagineTravelDropdown_linkLabel", "Nachhaltig Reisen")}
    </Link>
  );
};
