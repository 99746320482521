import React, { useState } from "react";
import classNames from "classnames";
import { SubmenuItem } from "./mobileMenuHelpers";
import { Button, Icomoon, Icon, Link } from "../../../atoms";

interface Props {
  item: SubmenuItem;
  activeMenuHref: string | undefined;
  onClose?: () => void;
  dataTrackingId?: string;
}

export const MobileSubmenu: React.FC<Props> = ({
  item,
  activeMenuHref,
  onClose,
  dataTrackingId,
}) => {
  const isActiveItemInMenu = item.submenu.some(
    (item) => item.href === activeMenuHref
  );
  const [isOpen, setIsOpen] = useState(isActiveItemInMenu);

  return (
    <li className="mobile-menu__item">
      <Button
        element="button"
        className={classNames(
          "is-link is-family-secondary is-size-3 py-1 pl-3 px-0",
          { "has-text-primary": isOpen }
        )}
        onClick={() => setIsOpen((open) => !open)}
        aria-expanded={isOpen}
        data-tracking-id={dataTrackingId}
      >
        {item.label}
        <Icon
          className="is-size-4 ml-3"
          icomoon={isOpen ? Icomoon.arrowUp3 : Icomoon.arrowDown3}
        />
      </Button>

      <ul
        className={classNames("mobile-menu__list mt-2", {
          "is-sr-only": !isOpen,
        })}
      >
        {item.submenu?.filter(subItem => subItem.visible !== false).map((subItem) => {
          return (
            <li
              key={subItem.href}
              className={classNames("mobile-menu__subitem", {
                "mobile-menu__item--active": activeMenuHref === subItem.href,
                "mobile-menu__item--visible": isOpen,
              })}
            >
              <Link
                className="is-block is-size-4 py-1 pl-3 my-0"
                href={subItem.href}
                tabIndex={isOpen ? 0 : -1}
                role="menuitem"
                onClick={onClose}
                data-tracking-id={subItem.dataTrackingId}
              >
                &ndash; {subItem.label}
              </Link>
            </li>
          );
        })}
      </ul>
    </li>
  );
};
