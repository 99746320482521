/* eslint-disable */

import classNames from "classnames";
import React, { PropsWithChildren, useMemo } from "react";
import { Icomoon, Icon } from "../Icon/Icon";
import { Link, LinkProps } from "../..";

import "./Button.scss";

type ButtonWithButtonElementProps = {
  /**
   * Determines whether a Link or a button element should be rendered.
   */
  element: "button";
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

type ButtonWithLinkElementProps = {
  /**
   * Determines whether a Link or a button element should be rendered.
   * Optional for backwards compatibiilty.
   */
  element?: "link" | "button";
} & LinkProps;

type ButtonCommonProps = {
  colour?: "teal" | "navy" | "white" | "elephant";

  /**
   * The secondary outlined version of the button
   */
  isGhost?: boolean;

  /**
   * If you'd like to use an icon choose one here. These relate to those set in the Icon component.
   */
  icon?: React.ReactNode;

  /**
   * If you'd like to use an icon choose one here. These relate to those set in the Icon component.
   */
  icomoonIcon?: Icomoon;

  /**
   * The side the icon should be placed on, defaults to right.
   */
  iconSide?: "left" | "right";
};

export type ButtonProps = PropsWithChildren<
  ButtonCommonProps & (ButtonWithButtonElementProps | ButtonWithLinkElementProps)
>;

/**
 * A button element. Pass element="link" or "button" to
 *
 * @status stable
 */

export const Button: React.FC<ButtonProps> = ({
  element = "link",
  children,
  colour = "teal",
  isGhost = false,
  icon,
  icomoonIcon,
  iconSide = "right",
  className,
  ...elementProps
}) => {
  const Element = useMemo(
    () =>
      element === "button"
        ? (props: any) => <button type="button" {...elementProps} {...props} />
        : (props: any) => <Link {...elementProps} {...props} />,
    [element, elementProps]
  );

  return (
    <Element
      className={classNames(
        "button",
        "button-comp",
        { "is-teal": colour === "teal" },
        { "is-navy": colour === "navy" },
        { "is-white": colour === "white" },
        { "is-elephant": colour === "elephant" },
        { "is-ghost": isGhost },
        { "button--with-icon": !!(icon || icomoonIcon) && children != null },
        { "button--with-icon-only": !!(icon || icomoonIcon) && children == null },
        className
      )}>
      <span
        className={classNames({
          "is-flex-direction-row-reverse": iconSide === "left",
        })}>
        {children}
        {icomoonIcon ? (
          <Icon icomoon={icomoonIcon} />
        ) : !!icon ? (
          <span className={"is-flex icon"}>{icon}</span>
        ) : null}
      </span>
    </Element>
  );
};

Button.displayName = "Button";
