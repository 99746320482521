/* eslint-disable */

import classNames from "classnames";
import React from "react";
import { Icon, Icomoon, Link } from "../..";
import "./NavMenu.scss";

export interface NavMenuItem {
  /**
   * Label to be shown within the link.
   */
  label: string;

  /**
   * A DOM ID reference relating to this navigation item (NOT A postgres table ID).
   */
  id: string;

  /**
   * Slug to be used as the element ID, for URL hash anchor linking;
   */
  slug: string;

  /**
   * Used for sub pages
   */
  relatedPage?: string;
}

interface Props {
  /**
   * Label to show above the nav
   */
  label?: string;
  items: NavMenuItem[];
  selectOnMobile?: boolean;

  /**
   * Pass the ID string of the active item to set active style
   */
  activeItem: string;
  baseRoute?: string;

  onItemClick?(id: string): any;
  onSelectChange?(id: string): any;
}

/**
 * A nav menu that can optionally become a select on mobile
 *
 * @status stable
 */
export class NavMenu extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  private updateHashMobile = (event: React.ChangeEvent<any>) => {
    const slug = this.props.items.find((item) => item.id === event.target.value)?.slug;
    history.replaceState(null, "", slug ? `#${slug}` : null);
    this.props.onSelectChange?.(event.target.value);
  };

  private updateHash = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, itemId: string) => {
    e.preventDefault(); // Native route change affects zenscroll.to method
    const slug = this.props.items.find((item) => item.id === itemId)?.slug;
    history.replaceState(null, "", slug ? `#${slug}` : null);
    this.props.onItemClick?.(itemId);
  };

  private renderLink = (item: NavMenuItem): JSX.Element | null => {
    const { baseRoute, onItemClick, activeItem } = this.props;
    const itemClassName = classNames("nav-menu__item-button", {
      "nav-menu__item-button--active": item.id === activeItem,
    });

    if (!onItemClick && baseRoute && baseRoute.length > 0) {
      return (
        <Link className={itemClassName} href={`${baseRoute}#${item.slug}`}>
          {item.label}
        </Link>
      );
    }
    if (!onItemClick && !baseRoute) {
      return (
        <a className={itemClassName} href={`#${item.slug}`}>
          {item.label}
        </a>
      );
    }
    if (onItemClick) {
      return (
        <a
          className={itemClassName}
          onClick={(e) => this.updateHash(e, item.id)}
          href={`#${item.slug}`}>
          {item.label}
        </a>
      );
    }
    return null;
  };
  public render() {
    const { label, items, selectOnMobile, activeItem } = this.props;

    return (
      <div className="nav-menu">
        {label && (
          <div className="nav-menu__header">
            <span className="subtitle is-uppercase is-family-primary is-letter-spacing-1 is-size-6">
              {label}
            </span>
          </div>
        )}

        <nav className={selectOnMobile ? "hide-on-mobile" : ""}>
          <ul>
            {items.map((item) => (
              <li
                className={classNames("nav-menu__li is-size-4 is-family-secondary", {
                  "nav-menu__li--active": item.id === activeItem,
                })}
                key={item.id}>
                {this.renderLink(item)}
              </li>
            ))}
          </ul>
        </nav>

        {selectOnMobile && (
          <div className="nav-menu__select-wrapper">
            <select onChange={this.updateHashMobile} value={activeItem}>
              {items.map((item) => (
                <option value={item.id} key={item.id}>
                  {item.label}
                </option>
              ))}
            </select>
            <Icon icomoon={Icomoon.arrowDown3} />
          </div>
        )}
      </div>
    );
  }
}
