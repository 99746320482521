/* eslint-disable */

import classNames from "classnames";
import React, { forwardRef, LegacyRef } from "react";

export const NativeText = forwardRef(
  (props: JSX.IntrinsicElements["input"], ref: LegacyRef<HTMLInputElement>) => {
    return <input ref={ref} {...props} className={classNames("input", props.className)} />;
  }
);
