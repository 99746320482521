import { site1Api } from "@travellocal/utils";

/** @deprecated Site 1 will be closed down soon */
export const getRelatedFilterSet = (
  content: site1Api.Content
): site1Api.ListContentParams | null => {
  if (!content) {
    return null;
  }

  const themes = content.themes ? content.themes.map((x) => x.id) : [];
  const locations = content.locations ? content.locations.map((x) => x.id) : [];
  return { themes, locations };
};
