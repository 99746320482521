import React from "react";

/**
 * Context to allow content and location cards to disable browser-default drag behaviour (see: Firefox),
 * but only when they're inside this context.
 */
export const DraggingContext = React.createContext({});

interface LocalState {
  clickStartPosition: [number, number] | undefined;
}

export class PreventLinkDragging extends React.Component<React.PropsWithChildren, LocalState> {
  private onMouseDown = (e: React.MouseEvent) => {
    this.setState({ clickStartPosition: [e.pageX, e.pageY] });
  };

  /**
   * Intercept the click event if the mouse has moved too much.
   * https://stackoverflow.com/a/20290312/268555
   */
  private onClickCapture = (e: React.MouseEvent) => {
    const { clickStartPosition } = this.state;
    if (!clickStartPosition) {
      return;
    }

    const thresholdPx = 2;
    const [startX, startY] = clickStartPosition;
    const [currentX, currentY] = [e.pageX, e.pageY];

    // Prevent click if we're ending a click-and-drag
    if (Math.abs(currentX - startX) > thresholdPx || Math.abs(currentY - startY) > thresholdPx) {
      e.stopPropagation(); // Firefox
      e.preventDefault(); // Chrome
    }

    this.setState({ clickStartPosition: undefined });
  };

  public render() {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    const disableDragStartProps: React.HTMLProps<any> = {
      onMouseDown: this.onMouseDown,
      onClickCapture: this.onClickCapture,
      onMouseLeave: () => this.setState({ clickStartPosition: undefined }),
      onDragStart: (e) => e.preventDefault(), // Disable link dragging in Firefox https://stackoverflow.com/a/24775961/268555
    };

    return (
      <DraggingContext.Provider value={disableDragStartProps}>
        {this.props.children}
      </DraggingContext.Provider>
    );
  }
}
