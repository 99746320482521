/* eslint-disable */

import classNames from "classnames";
import React, { forwardRef, LegacyRef } from "react";

export const NativeTextarea = forwardRef(
  (props: JSX.IntrinsicElements["textarea"], ref: LegacyRef<HTMLTextAreaElement>) => {
    return (
      <textarea ref={ref} rows={5} {...props} className={classNames("textarea", props.className)} />
    );
  }
);
