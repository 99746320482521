/* eslint-disable */

import React, { useCallback } from "react";
import classNames from "classnames";
import { Icomoon, Icon } from "../../Icon/Icon";
import { useFormContext } from "react-hook-form";
import "./NumberStepper.scss";

interface NumberStepperProps {
  className?: string;
  titleText: string;
  subtitleText: string;
  inputProps: WithRequired<JSX.IntrinsicElements["input"], "name">;
}

interface StepButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  icomoon: Icomoon;
}

const StepButton: React.FC<StepButtonProps> = ({ className, icomoon, ...buttonProps }) => {
  return (
    <button className={classNames(className, "step-button")} {...buttonProps} type="button">
      <Icon icomoon={icomoon} />
    </button>
  );
};

export const NumberStepper: React.FC<NumberStepperProps> = ({
  className,
  titleText,
  subtitleText,
  inputProps,
}) => {
  const { register, setFocus, watch, setValue } = useFormContext(); // get form context
  const count = +watch(inputProps.name, 0);

  const handleFocus = useCallback(
    (e: any) => {
      setFocus(inputProps.name, { shouldSelect: true });
      if (inputProps.onFocus) {
        inputProps.onFocus(e);
      }
    },
    [inputProps]
  );

  // this bit of logic handles setting the value in the input in state on blur
  const handleBlur = useCallback(
    (e: any) => {
      if (count < 0) {
        setValue(inputProps.name, 0);
      }
      if (inputProps.onBlur) {
        // make sure fire the onBlur prop with the event
        inputProps.onBlur(e);
      }
    },
    [inputProps, count]
  );

  return (
    <div className={classNames(className, "number-stepper")}>
      <div className="number-stepper-text">
        <span className={classNames("title-text", { active: !!count })}>{titleText}</span>
        <span className="subtitle-text">{subtitleText}</span>
      </div>
      <div className="number-stepper-buttons">
        <StepButton
          icomoon={Icomoon.minus2}
          onClick={() => count > 0 && setValue(inputProps.name, count - 1)}
          className={classNames({ disabled: count === 0 })}
        />
        <input
          {...inputProps}
          className={classNames("number-stepper-count", { active: !!count })}
          onFocus={handleFocus}
          step="1"
          type="number"
          defaultValue={0}
          min="0"
          {...register(inputProps.name, {
            onBlur: handleBlur,
          })}
        />
        <StepButton icomoon={Icomoon.plus2} onClick={() => setValue(inputProps.name, count + 1)} />
      </div>
    </div>
  );
};
