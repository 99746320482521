import React from "react";
import { createContext, useCallback, useState } from "react";
import { lockPageScroll, unlockPageScroll } from "../..";

type DialogId = number;

interface IDialogContext {
  hasDialogs: () => boolean;

  /**
   * Returns true if the dialog id is the top-most in the stack.
   */
  isOnTop: (ref: DialogId | null) => boolean;

  /**
   * Number of active dialogs.
   */
  count: number;

  /**
   * Register your instance of the dialog. When any dialogs are registered, the context will lock page scroll and hide the rest of the app from screen readers.
   */
  register: (shouldLockPageScroll: boolean) => DialogId;

  /**
   * Unregister a dialog instance. If there are no dialogs registered, the context will restore page scroll and unhide the rest of the app from screen readers.
   */
  unregister: (ref: DialogId | null) => void;
}

export const DialogContext = createContext<IDialogContext | null>(null);

interface ProviderProps {
  appRootId?: string;
  children: React.ReactNode;
}

export const DialogContextProvider: React.FC<ProviderProps> = ({
  appRootId = "host",
  children,
}) => {
  const [stack] = useState<DialogId[]>([]);
  const [counter, setCounter] = useState(0);

  const register = useCallback(
    (shouldLockPageScroll = true) => {
      const newId = counter + 1;
      setCounter(newId);
      stack.push(newId);

      if (shouldLockPageScroll) {
        document.getElementById(appRootId)?.setAttribute("aria-hidden", "true");
        lockPageScroll();
      }

      return newId;
    },
    [appRootId]
  );

  const unregister = useCallback(
    (id: number | null) => {
      const index = stack.findIndex((x) => x === id);
      if (index >= 0) {
        stack.splice(index, 1);
      }

      // If there aren't any more dialogs, restore visibility + unlock the rest of the app.
      if (stack.length === 0) {
        document.getElementById(appRootId)?.removeAttribute("aria-hidden");
        unlockPageScroll();
      }
    },
    [appRootId]
  );

  const hasDialogs = useCallback(() => stack.length > 0, [stack]);

  const isOnTop = useCallback(
    (ref: DialogId | null) => {
      const index = stack.findIndex((x) => x === ref);
      return index === stack.length - 1;
    },
    [stack]
  );

  return (
    <DialogContext.Provider
      value={{
        register,
        unregister,
        hasDialogs,
        isOnTop,
        count: stack.length,
      }}
    >
      {children}
    </DialogContext.Provider>
  );
};
