import React from "react";
import classNames from "classnames";
import { Icomoon, Icon } from "../Icon/Icon";

import "./CircleArrow.scss";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  dir: "left" | "right";
  theme?: "light" | "dark";
  className?: string;
}

export const CircleArrow: React.FC<Props> = ({
  dir,
  theme = "dark",
  className,
  ...props
}) => {
  const icomoon = dir === "left" ? Icomoon.arrowLeft3 : Icomoon.arrowRight3;
  return (
    <button
      type="button"
      className={classNames(
        className,
        "circle-arrow",
        `circle-arrow--${dir}`,
        `circle-arrow--${theme}`
      )}
      {...props}
    >
      <Icon icomoon={icomoon} />
    </button>
  );
};
