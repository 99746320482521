import React from "react";
import classNames from "classnames";
import ClickOutsideHook from "../../utils/clickOutsideHook";

export interface DropdownClickProps {
  className?: string;
  onClick: () => void;
  onClose: () => void;
  isOpen: boolean;
  children?: React.ReactNode;
}

export const DropdownClick = React.forwardRef<
  HTMLDivElement,
  DropdownClickProps
>(({ className, onClick, onClose, children, isOpen }, ref) => {
  return (
    <ClickOutsideHook onClose={onClose}>
      <div
        ref={ref}
        className={classNames("dropdown__hover", className)}
        onClick={!isOpen ? onClick : onClose}
        data-testid="dropdown-click"
      >
        {children}
      </div>
    </ClickOutsideHook>
  );
});

DropdownClick.displayName = "DropdownClick";
