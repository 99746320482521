/* eslint-disable */

import React from "react";
import { createContext, useContext } from "react";
import { useAppState } from "../../utils";

import "./Link.scss";

/**
 * Determine localisation prefix behaviour:
 * - True | inherit from appstate
 * - False | do not add prefix
 * - string | string to prefix
 */
type LocalePrefix = true | false | string;

export interface LinkProps extends React.HTMLProps<HTMLAnchorElement> {
  /**
   * Determine localisation prefix behaviour for this Link.
   *
   * - True | use locale from AppState
   * - False | no prefix
   * - string | an exact string to prefix
   *
   * If this is undefined LinkContext.localePrefix is used (defaults to true).
   */
  localePrefix?: LocalePrefix;
}

export interface LinkContextProps {
  Component: React.FC<LinkProps>;

  /**
   * Determine localisation prefix behaviour for this application.
   *
   * - True | use locale from AppState
   * - False | no prefix
   * - string | an exact string to prefix
   *
   * Can be overridden for each instace of Link by passing the localePrefix property.
   */
  localePrefix: LocalePrefix;
}

export const LinkContext = createContext<LinkContextProps | null>(null);

/**
 * A link component that can be configured to work with different routers. Components in @travellocal/ui can
 * use this component and it'll be replaced with the required Link component.
 *
 * See documentation in Storybook for more information.
 */
export const Link: React.FC<LinkProps> = ({ children, href, localePrefix, ...props }) => {
  const linkContext = useContext(LinkContext);
  if (!linkContext) {
    throw new Error("@travellocal/ui/Link must be used within a LinkContext.");
  }

  const { appState } = useAppState();
  const { Component, localePrefix: contextLocalePrefix } = linkContext;

  let fullHref: string;
  let LinkComponent: React.FC<LinkProps> = Component;

  const removeTrailingslash = (str: string) => str.replace(/\/$/, "");

  if (href && href.startsWith("http")) {
    LinkComponent = (props) => <a target="_blank" {...props} />;
    fullHref = href;
  } else if (href && href.startsWith("/")) {
    // Determine whether this link should be prefixed with a locale string.
    // This behaviour can be configured on each Link or on
    LinkComponent = Component;

    const config = localePrefix ?? contextLocalePrefix;
    let prefix = "";
    if (href?.match(/\/(\w{2}(-\w{2})?)\/.+/i)) {
      prefix = "";
    } else if (typeof config === "string") {
      prefix = config;
    } else if (config === true) {
      prefix =
        typeof contextLocalePrefix === "string" ? contextLocalePrefix : appState?.locale ?? "";
    }

    fullHref = removeTrailingslash(prefix ? `/${prefix}${href}` : href ?? "");
  } else {
    LinkComponent = Component;
    fullHref = removeTrailingslash(href ?? "");
  }

  return (
    <LinkComponent className="link-component" href={fullHref} {...props}>
      {children}
    </LinkComponent>
  );
};

Link.displayName = "Link";
